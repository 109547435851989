import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Input,
  Spinner,
  Alert,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useGetProductsByIdsQuery } from "slices/resourceInventory/inventorySlice";

interface Product {
  id: number;
  name: string;
  total_stock: string;
}

interface FilteredDataItem {
  product: number;
  product_name: string;
  quoted_amount: number;
  planned_quantity_sum: number;
  procured_amount: number;
  procured_status: string;
  procured_balance_quantity: number;
}

interface IntiateProcuredModalProps {
  isOpen: boolean;
  toggle: () => void;
  nextAction: string | null;
  selectedProductIds?: number[];
  filteredData?: FilteredDataItem[];
}

interface FormValues {
  [key: string]: number; // or any other type you expect
}

const IntiateProcuredModal: React.FC<IntiateProcuredModalProps> = ({
  isOpen,
  toggle,
  nextAction,
  selectedProductIds = [],
  filteredData = [],
}) => {
  const {
    data = { results: [] },
    isLoading,
    isError,
    refetch,
  } = useGetProductsByIdsQuery(selectedProductIds.join(","));

  const productsData: Product[] = data.results || [];

  const [procuredAmounts, setProcuredAmounts] = useState<{
    [key: number]: number;
  }>({});
  const [isInputDisabled, setIsInputDisabled] = useState<{
    [key: number]: boolean;
  }>({});

  // Calculation of procured and max procured values
  useEffect(() => {
    const calculatedProcuredAmounts: { [key: number]: number } = {};
    const calculatedInputDisability: { [key: number]: boolean } = {};

    filteredData.forEach((item) => {
      const product = productsData.find((p) => p.id === item.product);
      const totalStock = product ? parseFloat(product.total_stock) : 0;
      const plannedAmount = item.planned_quantity_sum;
      const balanceQuantity = item.procured_balance_quantity;

      // Logic for handling different conditions
      if (totalStock > plannedAmount) {
        calculatedProcuredAmounts[item.product] = 0; // Total stock exceeds planned, set procured amount to 0
        calculatedInputDisability[item.product] = true; // Disable input
      } else if (balanceQuantity === 0) {
        calculatedProcuredAmounts[item.product] =
          plannedAmount - item.procured_amount;
        calculatedInputDisability[item.product] = false; // Enable input
      } else if (balanceQuantity > 0) {
        calculatedProcuredAmounts[item.product] =
          plannedAmount - item.procured_amount + totalStock;
        calculatedInputDisability[item.product] = false; // Enable input
      } else {
        calculatedProcuredAmounts[item.product] = plannedAmount; // Default to planned amount
        calculatedInputDisability[item.product] = false; // Enable input
      }
    });

    setProcuredAmounts(calculatedProcuredAmounts);
    setIsInputDisabled(calculatedInputDisability);
  }, [filteredData, productsData]);

  // Formik and Yup validation
  const formik = useFormik<FormValues>({
    initialValues: filteredData.reduce((acc, item) => {
      acc[item.product] = procuredAmounts[item.product] || 0;
      return acc;
    }, {} as Record<number, number>),
    enableReinitialize: true,
    validationSchema: Yup.object().shape(
      filteredData.reduce((schema, item) => {
        schema[item.product] = Yup.number()
          .required("Required")
          .min(0, "Cannot be less than 0")
          .max(
            procuredAmounts[item.product] || 0,
            `Cannot exceed ${procuredAmounts[item.product]}`
          );
        return schema;
      }, {} as Record<number, Yup.NumberSchema>)
    ),
    onSubmit: (values) => {
      // Handle form submission logic
      console.log("Submitted values:", values);
    },
  });

  return (
    <Modal isOpen={isOpen} size="lg" toggle={toggle}>
      <ModalHeader toggle={toggle}>Bulk Insert Procured Section</ModalHeader>
      <hr />
      <ModalBody>
        {isLoading ? (
          <Spinner color="primary" />
        ) : isError ? (
          <Alert color="danger">Error fetching products.</Alert>
        ) : (
          <Table responsive>
            <thead>
              <tr>
                <th>Product Name</th>
                <th>Quoted Amount</th>
                <th>Planned Quantity</th>
                <th>Total Stock</th>
                <th>Inventory Stock</th>
                <th>Procured Amount</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item) => {
                const product = productsData.find((p) => p.id === item.product);
                const totalStock = product
                  ? parseFloat(product.total_stock)
                  : 0;
                const inventoryStock =
                  totalStock > item.planned_quantity_sum
                    ? item.planned_quantity_sum // Show planned amount
                    : 0; // Show 0 if total stock is not greater

                return (
                  <tr key={item?.product}>
                    <td>{item?.product_name}</td>
                    <td>{item?.quoted_amount}</td>
                    <td>{item?.planned_quantity_sum}</td>
                    <td>{totalStock}</td>
                    <td>{inventoryStock}</td>
                    <td>
                      <Input
                        type="number"
                        value={formik.values[item?.product?.toString()] || 0} // Convert item.product to string
                        onChange={
                          (e) =>
                            formik?.setFieldValue(
                              item?.product?.toString(),
                              parseFloat(e.target.value) || 0
                            ) // Convert item.product to string
                        }
                        onBlur={formik.handleBlur}
                        disabled={isInputDisabled[item?.product]}
                        invalid={
                          !!formik.errors[item?.product?.toString()] &&
                          formik.touched[item?.product?.toString()]
                        } // Convert item.product to string
                      />
                      {formik.errors[item?.product?.toString()] &&
                        formik.touched[item?.product?.toString()] && (
                          <div className="text-danger">
                            {formik.errors[item?.product?.toString()]}
                          </div>
                        )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
        {nextAction && (
          <Button
            color="primary"
            onClick={() => formik.handleSubmit()} // Correct handling of the submit event
          >
            {nextAction}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default IntiateProcuredModal;
