import React, { useState, useContext } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Table,
  Spinner,
} from "reactstrap";

import { useCreateSafetyAppApproveWorkflowMutation } from "slices/workflow/WorkFlowSlice";
import { ServiceContext } from "context/ServiceContextProvider";

interface ProjectProps {
  projectData: any;
  refetchProject: any;
}

const OverviewTab: React.FC<ProjectProps> = ({
  projectData,
  refetchProject,
}) => {
  const context = useContext(ServiceContext);

  // Handle the case where context might be undefined
  if (!context) {
    throw new Error(
      "ProfileDropdown must be used within a ServiceContextProvider"
    );
  }

  const { selectedRoleName } = context;

  const [rejectModal, setRejectModal] = useState(false);
  const [nestedRejectModal, setNestedRejectModal] = useState(false); // Nested rejection modal
  const [approvalModal, setApprovalModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [selectedApprovalWorkflow, setSelectedApprovalWorkflow] =
    useState<any>(null);
  const [reason, setReason] = useState("");
  const [workflowToReject, setWorkflowToReject] = useState<any>(null); // For handling the workflow to reject

  const [createApproveWorkflow, { isLoading: createApprovelLoading }] =
  useCreateSafetyAppApproveWorkflowMutation();

  const toggleRejectModal = () => setRejectModal(!rejectModal);
  const toggleNestedRejectModal = () =>
    setNestedRejectModal(!nestedRejectModal);
  const toggleApprovalModal = () => setApprovalModal(!approvalModal);

  const handleReject = (product: any) => {
    setSelectedProduct(product);
    setSelectedApprovalWorkflow(product.approval_workflow);
    toggleRejectModal();
  };

  const handleAccept = (product: any) => {
    setSelectedApprovalWorkflow(product.approval_workflow);
    toggleApprovalModal();
  };

  const openNestedRejectModal = (workflow: any) => {
    setWorkflowToReject(workflow);
    toggleNestedRejectModal();
  };

  const isRoleAllowed = (workflow: any) => {
    return workflow.role.includes(selectedRoleName);
  };

  const handleSubmitReject = async () => {
    if (!workflowToReject) return;

    const rejectData = {
      approval_id: workflowToReject.approval_id,
      resource_product: workflowToReject?.resource_product_id,
      approval_list_id: workflowToReject?.approval_list_id,
      toinitate_id: workflowToReject?.toinitate_id,
      approval_status: "Rejected",
      reason: reason, // Include rejection reason
    };

    try {
      await createApproveWorkflow(rejectData).unwrap();
      refetchProject();
      console.log("Rejection submitted successfully");
    } catch (error) {
      console.error("Error rejecting workflow", error);
    }

    toggleNestedRejectModal();
    toggleRejectModal();
    setReason(""); // Clear reason after submission
  };

  const handleApprove = async (workflow: any) => {
    const approveData = {
      approval_id: workflow.approval_id,
      resource_product: workflow?.resource_product_id,
      approval_list_id: workflow?.approval_list_id,
      toinitate_id: workflow?.toinitate_id,
      approval_status: "Approved",
    };

    try {
      await createApproveWorkflow(approveData).unwrap();
      refetchProject();
      toggleApprovalModal();
      console.log("Approval submitted successfully");
    } catch (error) {
      console.error("Error approving workflow", error);
      toggleApprovalModal();
    }
  };


  return (
    <React.Fragment>
      <Row>
        {projectData && projectData.length > 0 ? (
          projectData.map((project: any, projectIndex: any) => (
            <Col xl={12} key={projectIndex}>
              <div>
                {project.sections?.length > 0 ? (
                  project.sections.map((section: any, sectionIndex: any) => (
                    <Card key={sectionIndex}>
                      <CardBody>
                        <div className="mt-4">
                          <h6 className="mb-3 fw-semibold text-uppercase">
                            Section Name: {section.section_name}
                          </h6>
                          {section.products?.length > 0 ? (
                            <div className="table-responsive">
                              <Table className="table-borderless align-middle mb-0">
                                <thead className="table-light">
                                  <tr>
                                    <th scope="col">Approval#</th>
                                    <th scope="col">Product Name</th>
                                    <th scope="col">Planned</th>
                                    <th scope="col">Quoted</th>
                                    <th scope="col">Procured</th>
                                    <th scope="col">Inventory</th>
                                    <th scope="col">Pending</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {section.products.map(
                                    (product: any, productIndex: any) => {
                                      // Calculate the count of pending approvals for the current product
                                      const pendingApprovalCount =
                                        product.approval_workflow.reduce(
                                          (count: number, workflow: any) =>
                                            workflow.approval_status ===
                                            "Pending Approval"
                                              ? count + 1
                                              : count,
                                          0
                                        );

                                      return (
                                        <tr key={productIndex}>
                                          <td>{product.approval_id}</td>
                                          <td>{product.product_name}</td>
                                          <td>{product.planned_quantity}</td>
                                          <td>{product.quoted_quantity}</td>
                                          <td>{product.procured_quantity}</td>
                                          <td>{product.inventory_quantity}</td>
                                          <td>{pendingApprovalCount}</td>{" "}
                                          {/* Display pending approval count */}
                                          <td>
                                            <span
                                              className={`badge fw-medium ${
                                                product.overall_status ===
                                                  "Pending Approval" ||
                                                product.overall_status ===
                                                  "Rejected"
                                                  ? "bg-warning"
                                                  : "bg-success"
                                              }`}
                                            >
                                              {product.overall_status}
                                            </span>
                                          </td>
                                          <td>
                                            <Button
                                              color="success"
                                              disabled={
                                                product.overall_status !==
                                                "Pending Approval"
                                              }
                                              onClick={() =>
                                                handleAccept(product)
                                              }
                                              className="btn rounded-pill me-2"
                                            >
                                              <i className="ri-check-line align-middle" />
                                              Approve
                                            </Button>
                                            <Button
                                              color="danger"
                                              disabled={
                                                product.overall_status !==
                                                "Pending Approval"
                                              }
                                              onClick={() =>
                                                handleReject(product)
                                              }
                                              className="btn rounded-pill me-2"
                                            >
                                              <i className="ri-close-line align-middle" />
                                              Reject
                                            </Button>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          ) : (
                            <p>No products available</p>
                          )}
                        </div>
                      </CardBody>
                    </Card>
                  ))
                ) : (
                  <p>No sections available</p>
                )}
              </div>
            </Col>
          ))
        ) : (
          <p>No projects available</p>
        )}
      </Row>

      {/* Reject Modal */}
      <Modal isOpen={rejectModal} size="lg" toggle={toggleRejectModal}>
        <ModalHeader toggle={toggleRejectModal}>Reject Product</ModalHeader>
        <ModalBody>
          {selectedApprovalWorkflow ? (
            <div className="table-responsive">
              <Table className="table-borderless align-middle mb-0">
                <thead className="table-light">
                  <tr>
                    <th scope="col">Approval Level</th>
                    <th scope="col">Roles</th>
                    <th scope="col">Approval Status</th>
                    <th scope="col">Approved By</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedApprovalWorkflow.map((workflow: any, index: any) => (
                    <tr key={index}>
                      <td>Level {workflow.approval_level}</td>
                      <td>
                        {/* {workflow.role.join(", ")} */}
                        {workflow.role.map(
                          (action: string, actionIndex: number) => (
                            <span
                              key={actionIndex}
                              className="badge text-uppercase bg-success-subtle text-success me-1"
                            >
                              {action}
                            </span>
                          )
                        )}
                      </td>
                      <td>{workflow.approval_status}</td>
                      <td>{workflow.approved_by || " "}</td>
                      <td>
                        {workflow.approval_status === "Pending Approval" ? (
                          <Button
                            color="danger"
                            onClick={() => openNestedRejectModal(workflow)}
                            className="btn rounded-pill me-2"
                            disabled={!isRoleAllowed(workflow)}
                          >
                            Reject
                            {/* {createApprovelLoading &&
                            selectedApprovalWorkflow ===
                              workflow.approval_workflow ? (
                              <Spinner size="sm" />
                            ) : (
                              <>Reject</>
                            )} */}
                          </Button>
                        ) : (
                          <span
                            className={`badge fw-medium ${
                              workflow.approval_status === "Pending Approval" ||
                              workflow.approval_status === "Rejected"
                                ? "bg-warning"
                                : "bg-success"
                            }`}
                          >
                            {workflow.approval_status}
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <p>No approval workflow available</p>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            disabled={createApprovelLoading}
            onClick={toggleRejectModal}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>

      {/* Nested Reject Modal */}
      <Modal isOpen={nestedRejectModal} toggle={toggleNestedRejectModal}>
        <ModalHeader toggle={toggleNestedRejectModal}>
          Confirm Rejection
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="reason">Reason for Rejection</Label>
            <Input
              type="textarea"
              name="reason"
              id="reason"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              required
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleSubmitReject}>
            {createApprovelLoading && selectedApprovalWorkflow ? (
              <Spinner size="sm" />
            ) : (
              <> Confirm Rejection</>
            )}
          </Button>{" "}
          <Button color="secondary" onClick={toggleNestedRejectModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      {/* Approval Modal */}
      <Modal isOpen={approvalModal} size="lg" toggle={toggleApprovalModal}>
        <ModalHeader toggle={toggleApprovalModal}>
          Approval Workflow
        </ModalHeader>
        <ModalBody>
          {selectedApprovalWorkflow ? (
            <div className="table-responsive">
              <Table className="table-borderless align-middle mb-0">
                <thead className="table-light">
                  <tr>
                    <th scope="col">Approval Level</th>
                    <th scope="col">Roles</th>
                    <th scope="col">Approval Status</th>
                    <th scope="col">Approved By</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedApprovalWorkflow.map((workflow: any, index: any) => (
                    <tr key={index}>
                      <td>Level {workflow.approval_level}</td>
                      <td>
                        {/* {workflow.role.join(", ")} */}
                        {workflow.role.map(
                          (action: string, actionIndex: number) => (
                            <span
                              key={actionIndex}
                              className="badge text-uppercase bg-success-subtle text-success me-1"
                            >
                              {action}
                            </span>
                          )
                        )}
                      </td>
                      <td>{workflow.approval_status}</td>
                      <td>{workflow.approved_by || " "}</td>
                      <td>
                        {workflow.approval_status === "Pending Approval" ? (
                          <Button
                            color="success"
                            onClick={() => handleApprove(workflow)}
                            className="btn rounded-pill sm me-2"
                            disabled={
                              !isRoleAllowed(workflow) || createApprovelLoading
                            }
                          >
                            Approve
                          </Button>
                        ) : (
                          <span
                            className={`badge fw-medium ${
                              workflow.approval_status === "Pending Approval" ||
                              workflow.approval_status === "Rejected"
                                ? "bg-warning"
                                : "bg-success"
                            }`}
                          >
                            {workflow.approval_status}
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <p>No approval workflow available</p>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            disabled={createApprovelLoading}
            onClick={toggleApprovalModal}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default OverviewTab;
