import React, { useState } from "react";
import {
  useUpdateExcavationWorkAreaAllocationMutation,
  useDeleteExcavationWorkAreaAllocationMutation,
  useGetExcavationPermitByNumberQuery 
} from "slices/safetyApp/safetyappSlice";
import { useParams } from "react-router-dom";
import Step3WorkAreaAllocation from "../../../CommonWizardSteps/Wizards/Step3WorkAreaAllocation";

interface Step3WorkAreaAllocationIndexProps {
  previousStep: () => void;
  nextStep: () => void;
}

const Step3WorkAreaAllocationIndex: React.FC<Step3WorkAreaAllocationIndexProps> = ({
  previousStep,
  nextStep,
}) => {
  const { selectedProject: urlProjectId, hwPermitNo: hwPermitNoFromURL } = useParams<{ selectedProject: string, hwPermitNo: string }>();
  const { data: permitData, isFetching: isPermitFetching, refetch: refetchPermit } = useGetExcavationPermitByNumberQuery(hwPermitNoFromURL);

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEmployeeModalOpen, setIsEmployeeModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [deleteType, setDeleteType] = useState<"section" | "employee" | null>(null);
  const [selectedEmployee, setSelectedEmployee] = useState<number | null>(null);
  const [workLocation, setWorkLocation] = useState<string>("");

  const [updateWorkAreaAllocation, { isLoading: isUpdating }] =
    useUpdateExcavationWorkAreaAllocationMutation();
  const [deleteWorkAreaAllocation, { isLoading: isDeleting }] =
    useDeleteExcavationWorkAreaAllocationMutation();

  const handleAddWorkLocation = async () => {
    const payload = {
      excavation_permit: permitData?.id,
      work_location: workLocation,
    };

    try {
      await updateWorkAreaAllocation({
        ew_permit_no: hwPermitNoFromURL,
        updatedPermitData: payload,
      });
      await refetchPermit();
      setIsAddModalOpen(false);
      setWorkLocation("");
    } catch (error) {
      console.error("Error adding work location:", error);
    }
  };

  const handleAddEmployeeToWorkArea = async () => {
    const workLocation = permitData?.work_area_allocation?.find(
      (area: { id: number | null }) => area.id === selectedId
    )?.work_location;

    const payload = {
      excavation_permit: permitData?.id,
      work_location: workLocation,
      employee: selectedEmployee,
    };

    try {
      await updateWorkAreaAllocation({
        ew_permit_no: hwPermitNoFromURL,
        updatedPermitData: payload,
      });
      await refetchPermit();
      setIsEmployeeModalOpen(false);
      setSelectedEmployee(null);
    } catch (error) {
      console.error("Error adding employee:", error);
    }
  };

  const handleDeleteWorkLocationOrEmployee = async () => {

    if (selectedId && deleteType) {
      try {
        await deleteWorkAreaAllocation({
          sectionId: selectedId,
          employeeId: deleteType === "employee" ? selectedEmployee : null,
          type: deleteType,
        });
        await refetchPermit();
        setIsDeleteModalOpen(false);
        setDeleteType(null);
        setSelectedId(null);
        setSelectedEmployee(null);
      } catch (error) {
        console.error("Error deleting:", error);
      }
    }
  };

  return (
    <Step3WorkAreaAllocation
      previousStep={previousStep}
      nextStep={nextStep}
      permitData={permitData}
      handleAddWorkLocation={handleAddWorkLocation}
      handleAddEmployeeToWorkArea={handleAddEmployeeToWorkArea}
      handleDeleteWorkLocationOrEmployee={handleDeleteWorkLocationOrEmployee}
      isUpdateLoading={isUpdating}
      isDeleteLoading={isDeleting}
      setIsAddModalOpen={setIsAddModalOpen}
      setIsEmployeeModalOpen={setIsEmployeeModalOpen}
      setIsDeleteModalOpen={setIsDeleteModalOpen}
      setSelectedId={setSelectedId}
      setDeleteType={setDeleteType}
      setSelectedEmployee={setSelectedEmployee}
      setWorkLocation={setWorkLocation}
      isAddModalOpen={isAddModalOpen}
      isEmployeeModalOpen={isEmployeeModalOpen}
      isDeleteModalOpen={isDeleteModalOpen}
      workLocation={workLocation}
      selectedEmployee={selectedEmployee}
      isPermitFetching={isPermitFetching}
    />
  );
};

export default Step3WorkAreaAllocationIndex;
