import React, { useState } from "react";
import {
  Col,
  Row,
  Button,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  Spinner,
  Badge,
  Table,
} from "reactstrap";
import { useParams, useNavigate } from "react-router-dom";
import {
  useGetHotworkPermitByNumberQuery,
  useUpdateHotworkPermitMutation,
} from "slices/safetyApp/safetyappSlice";
import { useGetEmployeeDropDownQuery } from "slices/employee/employeeSlice";
import { useGetCompaniesQuery } from "slices/InspectionReport/InspectionReportSlice";
import { BadgeStatus } from "helpers/badgeStatus";
import { STATUS_COLORS } from "helpers/common_helper";

interface Step6PreviewSubmitProps {
  // projectsData: any;
  location: { latitude: number | null; longitude: number | null };
  previousStep: () => void;
  permitData: any;
  PermitDetail: any;
  handleSubmit: any;
  isPermitLoading: boolean;
  isSubmitting: boolean;
}

const imageExtensions = ["jpeg", "jpg", "png"];

const fileIconMap: { [key: string]: string } = {
  pdf: "ri-file-pdf-line",
  doc: "ri-file-word-line",
  docx: "ri-file-word-line",
  zip: "ri-folder-zip-line",
  rar: "ri-folder-zip-line",
  default: "ri-file-line",
};

const Step6PreviewSubmit: React.FC<Step6PreviewSubmitProps> = ({
  // projectsData,
  location,
  previousStep,

  permitData,
  PermitDetail,
  handleSubmit,
  isPermitLoading,
  isSubmitting
}) => {
  const [submitted, setSubmitted] = useState(false);

  // const [location, setLocation] = useState<{ latitude: number | null; longitude: number | null }>({
  //   latitude: null,
  //   longitude: null,
  // });

  const { hwPermitNo: hwPermitNoFromURL } = useParams<{ hwPermitNo: string }>();
  const navigate = useNavigate();


  // Queries to fetch homework permit, employees, projects, and companies data
  // const { data: hwPermitData, isLoading: isPermitLoading } =
  //   useGetHotworkPermitByNumberQuery(hwPermitNoFromURL);

  // const { data: employeeData } = useGetEmployeeDropDownQuery();
  // const { data: projectData } = useGetProjectsQuery();
  // const { data: companyData } = useGetCompaniesQuery();

  // const [updateHotworkPermit, { isLoading: isSubmitting }] =
  //   useUpdateHotworkPermitMutation();

  // Find Supervisor Names from IDs
  // const getSupervisorNames = () => {
  //   return hwPermitData?.name_of_hotwork_supervisor.map((id: number) => {
  //     const supervisor = employeeData?.find((emp: any) => emp.id === id);
  //     return supervisor ? supervisor.employee_fullname : `Unknown ID (${id})`;
  //   });
  // };

  // Get Company Name
  // const getCompanyName = () => {
  //   const company = companyData?.find(
  //     (comp: any) => comp.id === hwPermitData?.company
  //   );
  //   return company
  //     ? company.company_name
  //     : `Unknown Company ID (${hwPermitData?.company})`;
  // };

  // Get Project Name
  // const getProjectName = () => {
  //   return projectsData ? projectsData?.project_name : `Unknown Project ID (${hwPermitData?.project})`;
  // };

  // Extract file extension and return corresponding icon or image
  const getFileTypeIconOrImage = (documentUrl: string) => {
    const fileName = documentUrl.split("/").pop()?.split("?")[0] || "";
    const fileExtension = fileName.split(".").pop()?.toLowerCase() || "default";

    if (imageExtensions.includes(fileExtension)) {
      // Show the actual image if it's an image file
      return (
        <CardImg
          top
          width="150px"
          height="150px"
          src={documentUrl}
          alt={fileName}
          style={{ position: "relative" }}
        />
      );
    } else {
      // Return appropriate icon for non-image files
      const iconClass = fileIconMap[fileExtension] || fileIconMap["default"];
      return (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "150px" }}
        >
          <i className={`${iconClass} ri-4x`} />
        </div>
      );
    }
  };

  const truncateFileName = (fileName: string, maxLength = 20) => {
    if (fileName.length > maxLength) {
      const truncatedName = fileName.substring(0, maxLength) + "...";
      return truncatedName;
    }
    return fileName;
  };

  const extractDocumentName = (url: string) => {
    const parts = url?.split("/");
    return parts[parts?.length - 1];
  };

  // const getLocation = () => {
  //   if ("geolocation" in navigator) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         setLocation({
  //           latitude: position.coords.latitude,
  //           longitude: position.coords.longitude,
  //         });
  //       },
  //       (error) => {
  //         console.error("Error fetching location:", error);
  //       }
  //     );
  //   } else {
  //     console.warn("Geolocation is not available in this browser.");
  //   }
  // };

  // useEffect(() => {
  //   getLocation();
  // }, []);

  // const handleSubmit = async () => {
  //   if (location.latitude === null || location.longitude === null) {
  //     alert("Unable to fetch your location. Please try again or Reload Page.");
  //     return;
  //   }

  //   let payload = {
  //     report_status: 3,
  //     ...location
  //   };

  //   try {
  //     await updateHotworkPermit({
  //       hw_permit_no: hwPermitNoFromURL,
  //       updatedPermitData: payload,
  //     }).unwrap();
  //     setSubmitted(true);
  //   } catch (error) {
  //     console.error("Failed to submit", error);
  //   }
  // };


  if (submitted) {
    return (
      <div>
        <Card className="text-center">
          <CardBody>
            <h3>Your Report has been Successfully Submitted!</h3>
            <p>Home Work Permit No: {hwPermitNoFromURL}</p>
            <Button
              color="primary"
              onClick={() =>
                navigate(`/hotwork-permit-details/${hwPermitNoFromURL}/`)
              }
            >
              View Report
            </Button>
          </CardBody>
        </Card>
      </div>
    );
  }

  return (
    <div>
      <Row>
        <Col
          md={12}
          className="align-items-center justify-content-center"
        >
          {isPermitLoading ? (
            <Spinner color="primary" />
          ) : (
            <div>
              <PermitDetail/>

              <h5 className="mt-4">Worker And Site Area Details</h5>
              <hr />
              <Row className="mt-4">
                <Col md={12}>
                  <Table className="table-borderless align-middle mb-0">
                    <thead className="table-light">
                      <tr>
                        <th scope="col">Work Location</th>
                        <th scope="col">Employee No</th>
                        <th scope="col">Employee</th>
                      </tr>
                    </thead>
                    <tbody>
                      {permitData?.work_area_allocation?.length > 0 ? (
                        permitData.work_area_allocation.map(
                          (workArea: any, index: number) => (
                            <tr key={workArea.id}>
                              <td>{workArea.work_location}</td>
                              <td>
                                {workArea.employee?.map((location: any, index: number) => {
                                  console.log("employee", location)
                                  return (
                                    <tr className="py-2 d-flex">
                                      {location.employee_number}
                                    </tr>
                                  )
                                }
                                )
                                }
                              </td>

                              <td>
                                {workArea.employee?.map((location: any, index: number) => {
                                  return (
                                    <tr className="py-2 d-flex">
                                      <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0">

                                          <div className="flex-shrink-0 avatar-xs me-2">
                                            <div className="avatar-title bg-success-subtle text-success rounded-circle fs-13">
                                              {location.employee_fullname?.charAt(0)}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="flex-grow-1 ms-2 name">
                                          {location.employee_fullname}
                                        </div>
                                      </div>
                                    </tr>
                                  )
                                }
                                )
                                }
                              </td>

                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td colSpan={4} className="text-center">
                            No work locations found yet
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Col>
              </Row>


              {/* Display Questionnaires in Table */}
              <h5 className="mt-3">Check List</h5>
              <hr />
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Question</th>
                    <th>Answer</th>
                  </tr>
                </thead>
                <tbody>
                  {permitData?.questionnaires.map((q: any) => (
                    <tr key={q.id}>
                      <td>{q.question}</td>
                      <td>
                        <Badge
                          color={q.answer === "Yes" ? "success" : "danger"}
                        >
                          {q.answer}
                        </Badge>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* Display Documents */}
              <h5 className="mt-5">Safety Documents</h5>
              <hr />
              <Row>
                {permitData?.documents?.map(
                  (document: any, index: number) => (
                    <Col key={index} md={3} className="mb-4">
                      <Card>
                        {getFileTypeIconOrImage(document.attachment_url)}
                        <CardBody>
                          {/* Extract document name from URL */}
                          <CardTitle tag="h5">
                            {truncateFileName(
                              extractDocumentName(document.attachment)
                            )}
                          </CardTitle>
                        </CardBody>
                      </Card>
                    </Col>
                  )
                )}
              </Row>

              {/* Display Status */}
              <p>
                <strong>Current Status: {" "}</strong>
                {permitData?.report_status_name ?
                  <BadgeStatus value={permitData?.report_status_name} color={STATUS_COLORS[permitData?.report_status_name]} />
                  : null}
              </p>
            </div>
          )}
        </Col>
      </Row>

      {/* Navigation Buttons */}
      <div className="d-flex justify-content-between mt-4">
        <Button
          color="secondary"
          disabled={isSubmitting}
          onClick={previousStep}
        >
          Previous
        </Button>
        <Button color="success" onClick={handleSubmit} disabled={isSubmitting}>
          {isSubmitting ? <Spinner size="sm" /> : "Submit"}
        </Button>
      </div>
    </div>
  );
};

export default Step6PreviewSubmit;
