import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Col,
  Row,
  Input,
  Form,
  Label,
  FormGroup,
  Spinner,
} from "reactstrap";
import LoadingOverlay from "react-loading-overlay-ts";
import { useParams, useNavigate } from "react-router-dom";
import {
  useCreateToolboxMeetingMutation,
  useUpdateToolboxMeetingMutation,
  useGetToolboxMeetingByIdQuery,
} from "slices/safetyApp/safetyappSlice"; // Adjust imports based on your slice structure
import { useGetProjectsQuery } from "slices/employee/employeeSlice";

interface Step1ToolBoxDetailsProps {
  nextStep: () => void;
}

interface FormValues {
  contractor: string;
  project: number | "";
  start_date_of_work: string;
  start_time_of_work: string;
  end_date_of_work: string;
  end_time_of_work: string;
  work_description: string;
}

const Step1ToolBoxDetails: React.FC<Step1ToolBoxDetailsProps> = ({
  nextStep,
}) => {
  const { toolBoxId } = useParams<{ toolBoxId: string }>();
  const navigate = useNavigate();

  const { data: projectData } = useGetProjectsQuery();
  const { data: toolboxData, refetch, isLoading: isToolBoxLoading } =
    useGetToolboxMeetingByIdQuery(toolBoxId);
    console.log("toolBoxId",toolBoxId)

    console.log("toolboxData",toolboxData)

  const [createToolboxMeeting, { isLoading: createLoading }] =
    useCreateToolboxMeetingMutation();
  const [updateToolboxMeeting, { isLoading: updateLoading }] =
    useUpdateToolboxMeetingMutation();

  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      contractor: "",
      project: "",
      start_date_of_work: getCurrentDate(),
      start_time_of_work: "",
      end_date_of_work: "",
      end_time_of_work: "",
      work_description: "",
    },
    validationSchema: Yup.object({
      contractor: Yup.string().required("Contractor is required"),
      project: Yup.number().required("Project is required").nullable(),
      start_date_of_work: Yup.date().required("Start date is required"),
      start_time_of_work: Yup.string().required("Start time is required"),
      end_date_of_work: Yup.date().required("End date is required"),
      end_time_of_work: Yup.string().required("End time is required"),
      work_description: Yup.string().required("Work description is required"),
    }),
    onSubmit: async (values) => {
      try {
        const payload = { ...values, project: Number(values.project) };

        let response;
        if (toolBoxId) {
          response = await updateToolboxMeeting({
            toolbox_id: toolBoxId,
            updatedData: payload,
          }).unwrap();
        } else {
          response = await createToolboxMeeting(payload).unwrap();
          navigate(`/toolbox-meeting-wizard/${response.toolbox_id}`);
        }

        formik.resetForm();
        nextStep();
      } catch (error) {
        console.error("Failed to submit the form", error);
      }
    },
  });

  useEffect(() => {
    if (toolBoxId) {
      refetch(); // Fetch the toolbox data if the toolBoxId exists
    }
  }, [toolBoxId, refetch]);

  useEffect(() => {
    if (toolboxData && !isToolBoxLoading) {
      // Only set the form values once the data is fetched and loading is complete
      const {
        contractor,
        project,
        start_date_of_work,
        start_time_of_work,
        end_date_of_work,
        end_time_of_work,
        work_description,
      } = toolboxData;

      formik.setValues({
        contractor: contractor || "",
        project: project || "",
        start_date_of_work: start_date_of_work || getCurrentDate(),
        start_time_of_work: start_time_of_work || "",
        end_date_of_work: end_date_of_work || "",
        end_time_of_work: end_time_of_work || "",
        work_description: work_description || "",
      });
    }
  }, [toolboxData, isToolBoxLoading]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <LoadingOverlay active={createLoading || updateLoading} spinner text="Submitting...">
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="contractor">
                Contractor <span className="text-danger">*</span>
              </Label>
              <Input
                id="contractor"
                name="contractor"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.contractor}
                invalid={!!formik.errors.contractor}
              />
              {formik.errors.contractor && (
                <div className="text-danger">{formik.errors.contractor}</div>
              )}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="project">
                Project <span className="text-danger">*</span>
              </Label>
              <Input
                id="project"
                name="project"
                type="select"
                onChange={formik.handleChange}
                value={formik.values.project}
                invalid={!!formik.errors.project}
              >
                <option value="">Select Project</option>
                {projectData?.map((project) => (
                  <option key={project.id} value={project.id}>
                    {project.project_name}
                  </option>
                ))}
              </Input>
              {formik.errors.project && (
                <div className="text-danger">{formik.errors.project}</div>
              )}
            </FormGroup>
          </Col>

          {/* Start and End Date/Time Fields */}
          <Col md={6}>
            <FormGroup>
              <Label for="start_date_of_work">
                Start Date <span className="text-danger">*</span>
              </Label>
              <Input
                id="start_date_of_work"
                name="start_date_of_work"
                type="date"
                onChange={formik.handleChange}
                value={formik.values.start_date_of_work}
                invalid={!!formik.errors.start_date_of_work}
              />
              {formik.errors.start_date_of_work && (
                <div className="text-danger">{formik.errors.start_date_of_work}</div>
              )}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="start_time_of_work">
                Start Time <span className="text-danger">*</span>
              </Label>
              <Input
                id="start_time_of_work"
                name="start_time_of_work"
                type="time"
                onChange={formik.handleChange}
                value={formik.values.start_time_of_work}
                invalid={!!formik.errors.start_time_of_work}
              />
              {formik.errors.start_time_of_work && (
                <div className="text-danger">{formik.errors.start_time_of_work}</div>
              )}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="end_date_of_work">
                End Date <span className="text-danger">*</span>
              </Label>
              <Input
                id="end_date_of_work"
                name="end_date_of_work"
                type="date"
                onChange={formik.handleChange}
                value={formik.values.end_date_of_work}
                invalid={formik.touched.end_date_of_work && !!formik.errors.end_date_of_work}
              />
              {formik.touched.end_date_of_work && formik.errors.end_date_of_work ? (
                <div className="text-danger">{formik.errors.end_date_of_work}</div>
              ) : null}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="end_time_of_work">
                End Time <span className="text-danger">*</span>
              </Label>
              <Input
                id="end_time_of_work"
                name="end_time_of_work"
                type="time"
                onChange={formik.handleChange}
                value={formik.values.end_time_of_work}
                invalid={formik.touched.end_time_of_work && !!formik.errors.end_time_of_work}
              />
              {formik.touched.end_time_of_work && formik.errors.end_time_of_work ? (
                <div className="text-danger">{formik.errors.end_time_of_work}</div>
              ) : null}
            </FormGroup>
          </Col>

          <Col md={12}>
            <FormGroup>
              <Label for="work_description">
                Work Description <span className="text-danger">*</span>
              </Label>
              <Input
                id="work_description"
                name="work_description"
                type="textarea"
                onChange={formik.handleChange}
                value={formik.values.work_description}
                invalid={!!formik.errors.work_description}
              />
              {formik.errors.work_description && (
                <div className="text-danger">{formik.errors.work_description}</div>
              )}
            </FormGroup>
          </Col>

          <Col md={12}>
            <Button type="submit" color="primary">
              {createLoading || updateLoading ? <Spinner size="sm" /> : "Next"}
            </Button>
          </Col>
        </Row>
      </LoadingOverlay>
    </Form>
  );
};

export default Step1ToolBoxDetails;
