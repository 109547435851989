import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardImg,
  CardTitle,
  Col,
  Row,
  Button,
  CardHeader,
} from "reactstrap";

interface TaskDocumentProps {
  getConfinedSpacePermitById: any;
}

const imageExtensions = ["jpeg", "jpg", "png"];

const fileIconMap: { [key: string]: string } = {
  pdf: "ri-file-pdf-line", // Icon for PDFs
  doc: "ri-file-word-line", // Icon for Word docs
  docx: "ri-file-word-line",
  zip: "ri-folder-zip-line", // Icon for ZIP files
  rar: "ri-folder-zip-line",
  default: "ri-file-line", // Default icon for unknown file types
};

const DocumentsTab: React.FC<TaskDocumentProps> = ({ getConfinedSpacePermitById }) => {
  // Extract document name from URL
  const extractDocumentName = (url: string) => {
    const parts = url.split("/");
    return parts[parts.length - 1];
  };

  // Get the file type icon or actual image if it's an image file
  const getFileTypeIconOrImage = (documentUrl: string) => {
    const fileName = documentUrl.split("/").pop()?.split("?")[0] || "";
    const fileExtension = fileName.split(".").pop()?.toLowerCase() || "default";

    if (imageExtensions.includes(fileExtension)) {
      // Show the actual image if it's an image file
      return (
        <CardImg
          top
          width="150px"
          height="150px"
          src={documentUrl}
          alt={fileName}
          style={{ position: "relative" }}
        />
      );
    } else {
      // Return appropriate icon for non-image files
      const iconClass = fileIconMap[fileExtension] || fileIconMap["default"];
      return (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "150px" }}
        >
          <i className={`${iconClass} ri-4x`} />
        </div>
      );
    }
  };

  // Truncate long file names for display purposes
  const truncateFileName = (fileName: string, maxLength = 20) => {
    if (fileName.length > maxLength) {
      const truncatedName = fileName.substring(0, maxLength) + "...";
      return truncatedName;
    }
    return fileName;
  };

  const handleDownload = (documentUrl: string, fileName: string) => {
    // Create an anchor element
    const link = document.createElement("a");
  
    // Ensure the link points to the correct file URL
    link.href = documentUrl;
  
    // Set the download attribute to force download
    link.setAttribute("download", fileName);
  
    // Append the link to the body, click it, and remove it after triggering the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
        <CardTitle>Documents</CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            {getConfinedSpacePermitById?.documents?.map(
              (document: any, index: number) => {
                const documentName = extractDocumentName(document?.attachment);
                return (
                  <Col key={index} md={3} className="mb-4">
                    <Card>
                      {getFileTypeIconOrImage(document?.attachment_url)}
                      <CardBody>
                        <CardTitle tag="h5" className="d-flex justify-content-between align-items-center">
                          {/* Document name */}
                          <span>{truncateFileName(documentName)}</span>
                          {/* Download button */}
                          {/* <Button
                            color="link"
                            className="p-0"
                            onClick={() => handleDownload(document?.attachment_url, documentName)}
                          >
                            <i className="ri-download-2-line ri-lg" />
                          </Button> */}
                        </CardTitle>
                      </CardBody>
                    </Card>
                  </Col>
                );
              }
            )}
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default DocumentsTab;
