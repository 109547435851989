import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetHotworkPermitByNumberQuery,
  useUploadHotworkPermitDocumentMutation,
  useDeleteHotworkPermitDocumentMutation,
} from "slices/safetyApp/safetyappSlice";
import Step5FileUpload from "../../../CommonWizardSteps/Wizards/Step5FileUpload";

interface Step5FileUploadProps {
  previousStep: () => void;
  nextStep: () => void;
}

const Step4FileUpload: React.FC<Step5FileUploadProps> = ({
  previousStep,
  nextStep,

}) => {
  const { hwPermitNo: hwPermitNoFromURL } = useParams<{ hwPermitNo: string }>();
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [deleteDocumentId, setDeleteDocumentId] = useState<string | null>(null);
  const [documentToDeleteName, setDocumentToDeleteName] = useState<string>("");

  const {
    data: permitData,
    isLoading: isPermitLoading,
    isFetching: isPermitFetching,
    refetch: refetchPermit,
  } = useGetHotworkPermitByNumberQuery(hwPermitNoFromURL);

  const [uploadHotworkPermitDocument, { isLoading: isUploading }] =
    useUploadHotworkPermitDocumentMutation();
  const [deleteHotworkPermitDocument, { isLoading: isDeleting }] =
  useDeleteHotworkPermitDocumentMutation();

  const handleAcceptedFiles = (files: File[]) => {
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const handleFileUpload = async () => {
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("attachment", file);
    });

    try {
      await uploadHotworkPermitDocument({
        hw_permit_no: hwPermitNoFromURL,
        documentData: formData,
      });
      setIsUploadModalOpen(false);
      refetchPermit();
      setSelectedFiles([]);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleDeleteDocument = async () => {
    if (deleteDocumentId) {
      try {
        await deleteHotworkPermitDocument({ id: deleteDocumentId });
        await refetchPermit();
        setIsDeleteModalOpen(false);
      } catch (error) {
        console.error("Error deleting document:", error);
      }
    }
  };

  return (
    <Step5FileUpload 
    nextStep={nextStep}
    previousStep={previousStep}
    permitData={permitData}
    handleFileUpload={handleFileUpload}
    handleDeleteDocument={handleDeleteDocument}
    handleAcceptedFiles={handleAcceptedFiles}
    setDeleteDocumentId={setDeleteDocumentId}
    selectedFiles={selectedFiles}
    setIsUploadModalOpen={setIsUploadModalOpen}
    setDocumentToDeleteName={setDocumentToDeleteName}
    setIsDeleteModalOpen={setIsDeleteModalOpen}
    documentToDeleteName={documentToDeleteName}
    isPermitLoading={isPermitLoading}
    isUploadModalOpen={isUploadModalOpen}
    isDeleteModalOpen={isDeleteModalOpen}
    isUploading={isUploading}
    isDeleting={isDeleting}
    isPermitFetching={isPermitFetching}
    />
  );
};

export default Step4FileUpload;
