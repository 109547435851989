import React, { useEffect, useState, useContext } from "react";
import { ServiceContext } from "context/ServiceContextProvider";
import { useNavigate } from "react-router-dom";
import { IsValidPermissions } from "../../src/Routes/AuthProtected";

const Navdata = () => {
  const history = useNavigate();
  const context = useContext(ServiceContext);
  if (!context) {
    throw new Error(
      "ServiceContext must be used within a ServiceContextProvider"
    );
  }

  const { featureFlags, getEmployeeUUID, getPermissionList } = context;

  //state data
  const [isDashboard, setIsDashboard] = useState<boolean>(false);
  const [isAuth, setIsAuth] = useState<boolean>(false);
  const [isProject, setIsProject] = useState<boolean>(false);
  const [isTask, setIsTask] = useState<boolean>(false);
  const [isMembers, setIsMembers] = useState<boolean>(false);
  const [isReport, setIsReport] = useState<boolean>(false);
  const [isWorkFlow, setIsWorkFlow] = useState<boolean>(false);
  const [isRoles, setIsRoles] = useState<boolean>(false);
  const [isInventory, setIsInventory] = useState<boolean>(false);
  const [isSafetyApp, setIsSafetyApp] = useState<boolean>(false);
  const [isSafetyAppWorkFlow, setIsSafetyAppWorkFlow] =
    useState<boolean>(false);
  const [isSafetyAppPermits, setIsSafetyAppPermits] = useState<boolean>(false);

  const [isInventoryWorkFlow, setInventoryWorkFlow] = useState<boolean>(false);
  const [isInventoryItems, setIsInventoryItems] = useState<boolean>(false);
  const[isToolBoxMeeting, setIsToolBoxMeeting]=useState<boolean>(false);

  const [isSafetyAppWorkApproval, setIsSafetyAppWorkApproval] =
  useState<boolean>(false);
const [isSafetyAppApprovals, setIsSafetyAppApprovals] = useState<boolean>(false);

  

  // Authentication
  const [isSignIn, setIsSignIn] = useState<boolean>(false);
  const [isSignUp, setIsSignUp] = useState<boolean>(false);
  const [isPasswordReset, setIsPasswordReset] = useState<boolean>(false);
  const [isPasswordCreate, setIsPasswordCreate] = useState<boolean>(false);
  const [isLockScreen, setIsLockScreen] = useState<boolean>(false);
  const [isLogout, setIsLogout] = useState<boolean>(false);
  const [isSuccessMessage, setIsSuccessMessage] = useState<boolean>(false);
  const [isVerification, setIsVerification] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e: any) {
    if (e && e.target && e.target.getAttribute("sub-items")) {
      const ul: any = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("sub-items");
        const getID = document.getElementById(id) as HTMLElement;
        if (getID) getID.classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Auth") {
      setIsAuth(false);
    }
    if (iscurrentState !== "Project") {
      setIsProject(false);
    }
    if (iscurrentState !== "Task") {
      setIsTask(false);
    }
    if (iscurrentState !== "Members") {
      setIsMembers(false);
    }
    if (iscurrentState !== "Report") {
      setIsReport(false);
    }
    if (iscurrentState !== "WorkFlow") {
      setIsWorkFlow(false);
    }
    if (iscurrentState !== "Roles") {
      setIsRoles(false);
    }
    if (iscurrentState !== "Inventory") {
      setIsInventory(false);
    }
    if (iscurrentState !== "SafetyApp") {
      setIsSafetyApp(false);
    }
    if (iscurrentState !== "SafetyAppApprovals") {
      setIsSafetyAppApprovals(false);
    }
    if(iscurrentState !== "ToolBoxMeeting"){
      setIsToolBoxMeeting(false);
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isAuth,
    isProject,
    isTask,
    isMembers,
    isReport,
    isWorkFlow,
    isRoles,
    isInventory,
    isSafetyApp,
    isSafetyAppApprovals,
  ]);

  const menuItems: any = [
    {
      label: "Home",
      isHeader: true,
    },
    IsValidPermissions(["VIEW_ADMIN_DASHBOARD"]) && {
      id: "dashboard",
      label: "Dashboards",
      icon: "mdi mdi-speedometer",
      link: "/dashboard",
      click: function (e: any) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },
    {
      label: "apps",
      isHeader: true,
    },
    featureFlags.Members &&
      IsValidPermissions(["VIEW_MEMBERS_ALL"]) && {
        id: "members",
        label: "Members",
        icon: "ri-team-fill",
        link: "/#",
        click: function (e: any) {
          e.preventDefault();
          setIsMembers(!isMembers);
          setIscurrentState("Members");
          updateIconSidebar(e);
        },
        stateVariables: isMembers,
        subItems: [
          // {
          //   id: "members_profile",
          //   label: "Members Profile",
          //   link: "/pages-profile",
          //   parentId: "members",
          // },
          // {
          //   id: "members_list",
          //   label: "Members List",
          //   link: "/apps-ecommerce-orders",
          //   parentId: "members",
          // },
          {
            id: "members_customers",
            label: "Members",
            link: "/apps-ecommerce-customers",
            parentId: "members",
          },
        ],
      },
    featureFlags.Members &&
      IsValidPermissions(["VIEW_MEMBERS_ONLY"]) && {
        id: "members",
        label: "Members",
        icon: "ri-team-fill",
        link: "/#",
        click: function (e: any) {
          e.preventDefault();
          setIsMembers(!isMembers);
          setIscurrentState("Members");
          updateIconSidebar(e);
        },
        stateVariables: isMembers,
        subItems: [
          {
            id: "members_customers",
            label: "Members",
            link: `/profile/${getEmployeeUUID}`,
            parentId: "members",
          },
        ],
      },
    featureFlags["Inspection Report"] &&
      IsValidPermissions(["VIEW_INSPECTION_ALL"]) && {
        id: "Report",
        label: "Report",
        icon: "mdi mdi-file",
        link: "/report-list",
        click: function (e: any) {
          e.preventDefault();
          setIscurrentState("Report");
        },
      },
    // featureFlags.Workflow &&
    //   (IsValidPermissions(["WORKFLOW_VIEW_ALL"]) ||
    //     IsValidPermissions(["WORKFLOW_APPROVALS_VIEW_ALL"])) && {
    //     id: "WorkFlow",
    //     label: "WorkFlow",
    //     icon: "bx bx-line-chart-down",
    //     link: "/#",
    //     click: function (e: any) {
    //       e.preventDefault();
    //       setIscurrentState("WorkFlow");
    //       setIsWorkFlow(!isWorkFlow);
    //       updateIconSidebar(e);
    //     },
    //     stateVariables: isWorkFlow,
    //     subItems: [
    //       IsValidPermissions(["WORKFLOW_VIEW_ALL"]) && {
    //         id: "workflow_list",
    //         label: "WorkFlowList",
    //         link: "/workflow-list",
    //         parentId: "WorkFlow",
    //       },
    //       IsValidPermissions(["WORKFLOW_APPROVALS_VIEW_ALL"]) && {
    //         id: "work_flow_approvels",
    //         label: "WorkFlow Approvals",
    //         link: "/workflowaccess-list",
    //         parentId: "WorkFlow",
    //       },
    //     ].filter(Boolean),
    //   },
    featureFlags["Access Control"] &&
      (IsValidPermissions(["ROLE_ACCESS_VIEW_ALL"]) ||
        IsValidPermissions(["ROLE_AND_PAGE_ACCESS_VIEW_ALL"])) && {
        id: "Access Control",
        label: "Access Control",
        icon: "bx bxs-wrench",
        link: "/#",
        click: function (e: any) {
          e.preventDefault();
          setIsRoles(!isRoles);
          setIscurrentState("Roles");
          updateIconSidebar(e);
        },
        stateVariables: isRoles,
        subItems: [
          IsValidPermissions(["ROLE_ACCESS_VIEW_ALL"]) && {
            id: "role_access",
            label: "Role Access",
            link: "/roles-list",
            parentId: "Access Control",
          },
          IsValidPermissions(["ROLE_AND_PAGE_ACCESS_VIEW_ALL"]) && {
            id: "page_access",
            label: "Roles & Page Access",
            link: "/page-access-list",
            parentId: "Access Control",
          },
        ].filter(Boolean),
      },

    featureFlags["Resource Inventory"] &&
      (IsValidPermissions(["VIEW_CATEGORIES_ALL"]) ||
        IsValidPermissions(["VIEW_MEASUREMENTS_ALL"]) ||
        IsValidPermissions(["VIEW_PRODUCTS_ALL"]) ||
        IsValidPermissions(["VIEW_SUPPLIERS_ALL"]) ||
        IsValidPermissions(["WORKFLOW_VIEW_ALL"]) ||
        IsValidPermissions(["WORKFLOW_APPROVALS_VIEW_ALL"]) ||
        IsValidPermissions(["STOCKS_VIEW_ALL"])) && {
        id: "Inventory",
        label: "Resource Inventory",
        icon: "bx bxs-store",
        link: "/#",
        click: function (e: any) {
          e.preventDefault();
          setIsInventory(!isInventory);
          setIscurrentState("Inventory");
          updateIconSidebar(e);
        },
        stateVariables: isInventory,
        subItems: [
          {
            id: "inventoryworkflow",
            label: "WorkFlow",
            link: "/#",
            isChildItem: true,
            click: function (e: any) {
              e.preventDefault();
              setInventoryWorkFlow(!isInventoryWorkFlow);
            },
            stateVariables: isInventoryWorkFlow,
            childItems: [
              IsValidPermissions(["WORKFLOW_VIEW_ALL"]) && {
                id: 1,
                label: "Work Flow",
                link: "/inventory-workflow-list",
                parentId: "Inventory",
              },
              IsValidPermissions(["WORKFLOW_APPROVALS_VIEW_ALL"]) && {
                id: 7,
                label: "WorkFlow Approvals",
                link: "/inventory-workflowaccess-list",
                parentId: "WorkFlow",
              },
            ],
          },
          {
            id: "inventory",
            label: "Inventory",
            link: "/#",
            isChildItem: true,
            click: function (e: any) {
              e.preventDefault();
              setIsInventoryItems(!isInventoryItems);
            },
            stateVariables: isInventoryItems,
            childItems: [
              IsValidPermissions(["VIEW_MEASUREMENTS_ALL"]) && {
                id: 2,
                label: "Measurements",
                link: "/measurment-list",
                parentId: "Inventory",
              },
              IsValidPermissions(["VIEW_SUPPLIERS_ALL"]) && {
                id: 3,
                label: "Suppliers",
                link: "/supplier-list",
                parentId: "Inventory",
              },
              IsValidPermissions(["VIEW_CATEGORIES_ALL"]) && {
                id: 4,
                label: "Categories",
                link: "/category-list",
                parentId: "Inventory",
              },
              IsValidPermissions(["VIEW_PRODUCTS_ALL"]) && {
                id: 5,
                label: "Products",
                link: "/product-list",
                parentId: "Inventory",
              },
              IsValidPermissions(["STOCKS_VIEW_ALL"]) && {
                id: 6,
                label: "Stocks",
                link: "/stock-list",
                parentId: "Inventory",
              },
            ],
          },
        ],
        // subItems: [

        // ].filter(Boolean),
      },
      {
        id: "SafetyAppApprovals",
        label: "SafetyApp Approvals",
        icon: "bx bxs-file",
        link: "/#",
        click: function (e: any) {
          e.preventDefault();
          setIsSafetyAppApprovals(!isSafetyAppApprovals);
          setIscurrentState("SafetyAppApprovals");
          updateIconSidebar(e);
        },
        stateVariables: isSafetyAppApprovals,
        subItems: [
          {
            id: "permitworkflow",
            label: "WorkFlow",
            link: "/#",
            isChildItem: true,
            click: function (e: any) {
              e.preventDefault();
              setIsSafetyAppWorkFlow(!isSafetyAppWorkFlow);
            },
            stateVariables: isSafetyAppWorkFlow,
            childItems: [
              {
                id: 2,
                label: "Work Flow",
                link: "/safety-app-workflow-list",
                parentId: "SafetyAppApprovals",
              },
            ],
          },
          {
            id: "workflowApprovals",
            label: "Approval",
            link: "/#",
            isChildItem: true,
            click: function (e: any) {
              e.preventDefault();
              setIsSafetyAppWorkApproval(!isSafetyAppWorkApproval);
            },
            stateVariables: isSafetyAppWorkApproval,
            childItems: [
              {
                id: 2,
                label: "Approvals List",
                link: "/safety-app-workflow-approvel-list",
                parentId: "SafetyAppApprovals",
              },
            ],
          },
          
        ],
      },
    {
      id: "SafetyApp",
      label: "Safety App",
      icon: "bx bxs-store",
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setIsSafetyApp(!isSafetyApp);
        setIscurrentState("SafetyApp");
        updateIconSidebar(e);
      },
      stateVariables: isSafetyApp,
      subItems: [
        {
          id: "permits",
          label: "Permits",
          link: "/#",
          isChildItem: true,
          click: function (e: any) {
            e.preventDefault();
            setIsSafetyAppPermits(!isSafetyAppPermits);
          },
          stateVariables: isSafetyAppPermits,
          childItems: [
            {
              id: 1,
              label: "Home Work Permit",
              link: "/homeworkpermit-list",
              parentId: "SafetyApp",
            },
            {
              id: 2,
              label: "Hot Work Permit",
              link: "/hotworkpermit-list",
              parentId: "SafetyApp",
            },
            {
              id: 3,
              label: "General Work Permit",
              link: "/generalworkpermit-list",
              parentId: "SafetyApp",
            },
            {
              id: 4,
              label: "Work At Height Permit",
              link: "/workatheightpermit-list",
              parentId: "SafetyApp",
            },
            {
              id: 5,
              label: "Excavation Permit",
              link: "/excavationpermit-list",
              parentId: "SafetyApp",
            },
            {
              id: 6,
              label: "Lifting Permit",
              link: "/lifting-permit-list",
              parentId: "SafetyApp",
            },
            {
              id: 7,
              label: "Confined Space Permit",
              link: "/confinedspace-permit-list",
              parentId: "SafetyApp",
            },
           
          ],

        },
        {
          id: "ToolBoxMeeting",
          label: "ToolBox Meeting",
          link: "/#",
          isChildItem: true,
          click: function (e: any) {
            e.preventDefault();
            setIsToolBoxMeeting(!isToolBoxMeeting);
          },
          stateVariables: isToolBoxMeeting,
          childItems: [
            {
              id: 10,
              label: "ToolBox Meeting",
              link: "/toolboxmeeting-list",
              parentId: "ToolBoxMeeting",
            },
          ],
        },
      ],
    },
    featureFlags.Project &&
      (IsValidPermissions(["VIEW_PROJECT_ALL"]) ||
        IsValidPermissions(["VIEW_PROJECT_ONLY"])) && {
        id: "project",
        label: "Project",
        icon: "bx bxs-folder-open",
        link: "/#",
        click: function (e: any) {
          e.preventDefault();
          setIsProject(!isProject);
          setIscurrentState("Project");
          updateIconSidebar(e);
        },
        stateVariables: isProject,
        subItems: [
          (IsValidPermissions(["VIEW_PROJECT_ALL"]) ||
            IsValidPermissions(["VIEW_PROJECT_ONLY"])) && {
            id: "project_list",
            label: "List",
            link: "/apps-projects-list",
            parentId: "project",
          },
          // {
          //   id: "project_overview",
          //   label: "Overview",
          //   link: "/apps-projects-overview",
          //   parentId: "project",
          // },
          // {
          //   id: "project_create",
          //   label: "Create Project",
          //   link: "/apps-projects-create",
          //   parentId: "project",
          // },
        ].filter(Boolean),
      },

    featureFlags.Task &&
      (IsValidPermissions(["VIEW_TASK_ALL"]) ||
        IsValidPermissions(["VIEW_TASK_ONLY"])) && {
        id: "task",
        label: "Task",
        icon: "bx bxs-server",
        link: "/#",
        click: function (e: any) {
          e.preventDefault();
          setIsTask(!isTask);
          setIscurrentState("Task");
          updateIconSidebar(e);
        },
        stateVariables: isTask,
        subItems: [
          // {
          //     id: "kanban_board",
          //     label: "Kanban Board",
          //     link: "/apps-tasks-kanban",
          //     parentId: "task",
          // },
          IsValidPermissions(["ADD_TASK"]) && {
            id: "task_list_create",
            label: "Create New Task",
            link: "/apps-tasks-list-view-create?create_task=true",
            parentId: "task",
          },
          IsValidPermissions(["VIEW_TASK_ALL"]) && {
            id: "task_list",
            label: "Task List",
            link: "/apps-tasks-list-view",
            parentId: "task",
          },
          // {
          //     id: "task_detail",
          //     label: "Task Details",
          //     link: "/apps-tasks-details",
          //     parentId: "task",
          // },
          // {
          //   id: "resource_dashboard",
          //   label: "Resource Dashboard",
          //   link: "/advance-ui-ratings",
          //   parentId: "task",
          // },
        ].filter(Boolean),
      },
  ].filter(Boolean);
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
