import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Spinner,
} from "reactstrap";
import {
  useCreateSectionProductMutation,
  useGetProductsQuery,
} from "slices/resourceInventory/inventorySlice";
import { Formik, Form as FormikForm, ErrorMessage } from "formik";
import * as Yup from "yup";

interface ProductAddModalProps {
  isOpen: boolean;
  toggle: () => void;
  sectionId: string | number;
  refetchInventory: () => void;
  productIds: number[];
}

const ProductAddModal: React.FC<ProductAddModalProps> = ({
  isOpen,
  toggle,
  sectionId,
  refetchInventory,
  productIds,
}) => {
  const { data: products, isLoading: productsLoading } =
    useGetProductsQuery(undefined);
  const [createSectionProduct, { isLoading: isSectionProductLoading }] =
    useCreateSectionProductMutation();

  const validationSchema = Yup.object({
    productId: Yup.string().required("Product is required"),
  });

  const initialValues = {
    productId: "",
  };

  const handleSubmit = async (
    values: { productId: string },
    { resetForm }: { resetForm: () => void }
  ) => {
    try {
      await createSectionProduct({
        product: values.productId,
        section: sectionId,
      });
      toggle();
      refetchInventory();
      resetForm();
    } catch (error) {
      console.error("Error adding product:", error);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Add Product</ModalHeader>
      <ModalBody>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ handleChange, handleBlur, values, handleSubmit }) => (
            <FormikForm onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="productId">Select Product</Label>
                <Input
                  type="select"
                  id="productId"
                  name="productId"
                  value={values.productId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={productsLoading || isSectionProductLoading}
                >
                  <option value="" label="Select a product" />
                  {products?.results?.map((product: any) => (
                    <option
                      key={product?.id}
                      value={product?.id}
                      disabled={productIds?.includes(product?.id)} // Disable the option if the product ID is already selected
                    >
                      {product?.name}
                    </option>
                  ))}
                </Input>
                <ErrorMessage
                  name="productId"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              <ModalFooter>
                <Button
                  color="primary"
                  type="submit"
                  disabled={isSectionProductLoading}
                >
                  {isSectionProductLoading ? (
                    <>
                      <Spinner size="sm" />
                      &nbsp;Saving...
                    </>
                  ) : (
                    "Save Changes"
                  )}
                </Button>
                <Button
                  color="secondary"
                  disabled={isSectionProductLoading}
                  onClick={toggle}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </FormikForm>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default ProductAddModal;
