import React from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  CardFooter,
  CardHeader,
  Button,
} from "reactstrap";
import { EmergencyContactInfo } from "../EmployeeProfile";
import { IsValidPermissions } from "../../../Routes/AuthProtected";

interface EmergencyContactInfoProps {
  emergencyContactData: EmergencyContactInfo | null;
  setCardView: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
}

export const EmergencyContactInfoView: React.FC<EmergencyContactInfoProps> = ({
  emergencyContactData,
  setCardView,
}) => {
  return (
    <React.Fragment>
      <Card>
        <CardHeader className="d-flex align-items-center">
          <i className="ri-parent-fill fs-4"></i>
          <h4 className="card-title flex-grow-1 mb-0">
            Emergency Contact Information
          </h4>
          <div className="flex-shrink-0">
            {IsValidPermissions(['EDIT_MEMBERS']) &&
              <Button
                onClick={() => {
                  setCardView({ emergencyContactEdit: true });
                }}
                className="btn btn-soft-info btn-md shadow-none"
              >
                Edit
              </Button>
            }
          </div>
        </CardHeader>
        <CardBody>
          <div className="table-card">
            <table className="table mb-0">
              <tbody>
                <tr>
                  <td className="fw-medium" style={{ width: "300px" }}>
                    Full Name
                  </td>
                  <td>{emergencyContactData?.full_name}</td>
                </tr>
                <tr>
                  <td className="fw-medium">Email</td>
                  <td>{emergencyContactData?.email}</td>
                </tr>
                <tr>
                  <td className="fw-medium">Relationship</td>
                  <td>{emergencyContactData?.relationship}</td>
                </tr>
                <tr>
                  <td className="fw-medium">Address 1</td>
                  <td>{emergencyContactData?.address1}</td>
                </tr>
                <tr>
                  <td className="fw-medium">Address 2</td>
                  <td>{emergencyContactData?.address2}</td>
                </tr>
                <tr>
                  <td className="fw-medium">City</td>
                  <td>{emergencyContactData?.city}</td>
                </tr>
                <tr>
                  <td className="fw-medium">State</td>
                  <td>{emergencyContactData?.state}</td>
                </tr>
                <tr>
                  <td className="fw-medium">Country</td>
                  <td>{emergencyContactData?.country_name}</td>
                </tr>
                <tr>
                  <td className="fw-medium">Zip Code</td>
                  <td>{emergencyContactData?.zip_code}</td>
                </tr>
                <tr>
                  <td className="fw-medium">Home Phone</td>
                  <td>{emergencyContactData?.home_phone}</td>
                </tr>
                <tr>
                  <td className="fw-medium">Work Phone</td>
                  <td>{emergencyContactData?.work_phone}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
