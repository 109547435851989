import React, { useState } from 'react';
import Flatpickr from "react-flatpickr";
import {
    useGetAllTaskStatusQuery
  } from "slices/tasks/taskSlice";

export const TaskListGlobalFilter = ({setPageState, globalValue}: any) => {

    const { data: taskStatus } = useGetAllTaskStatusQuery(undefined);
    const [dateRange, setDateRange] = useState([null, null]);

    const statusOnChange = (value: string) => {
        setPageState((prev: any)=>({
            ...prev,
            status_name: value,
            page: 1
        }))
    }

    const handleDateChange = (selectedDates: any) => {
        if (selectedDates.length === 2) {
            const [startDate, endDate] = selectedDates;
            setDateRange([startDate, endDate]);

            let startDateString = startDate.toISOString().split('T')[0];
            let dueDateString = endDate.toISOString().split('T')[0]

            setPageState((prev: any)=>({
                ...prev,
                start_date: startDateString,
                due_date: dueDateString
            }))
        }
    };

    const handleBlur = () => {
        // If the date range is not set, clear the date range
        if (dateRange[0] && dateRange[1]) {
            setDateRange([null, null]);
            setPageState((prev: any)=>({
                ...prev,
                start_date: '',
                due_date: ''
            }))
        }
    };


    return (
        <React.Fragment>
            <div className="col-xxl-3 col-sm-4">
                <Flatpickr
                    placeholder="Select start & due date range"
                    className="form-control bg-light border-light"
                    options={{
                        mode: "range",
                        dateFormat: "d-m-Y",
                    }}
                    value={dateRange}
                    onChange={handleDateChange}
                    onClose={handleBlur}
                />
            </div>

            <div className="col-xxl-3 col-sm-4">
                <div className="input-light">
                    <select onChange={(e) => statusOnChange(e.target.value)} className="form-control" data-choices data-choices-search-false name="status" id="idStatus">
                    <option value="" >All</option>
                    {taskStatus?.map((options: any) => (
                        <option key={options.id} value={options.status_name}>
                        {options.status_name}
                        </option>
                    ))}
                    </select>
                </div>
            </div>
        </React.Fragment>
    );
};
