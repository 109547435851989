import React from "react";
import {
  Col,
  Row,
  Button,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardFooter,
  CardHeader,
  Spinner,
  Badge,
  Table,
} from "reactstrap";
import { useParams, useNavigate } from "react-router-dom";
import { useGetHomeworkPermitByNumberQuery } from "slices/safetyApp/safetyappSlice";
import { useGetEmployeeDropDownQuery } from "slices/employee/employeeSlice";
import { useGetProjectsQuery } from "slices/employee/employeeSlice";
import { useGetCompaniesQuery } from "slices/InspectionReport/InspectionReportSlice";

const imageExtensions = ["jpeg", "jpg", "png"];

const fileIconMap: { [key: string]: string } = {
  pdf: "ri-file-pdf-line", // Use an icon for PDFs
  doc: "ri-file-word-line", // Use an icon for Word docs
  docx: "ri-file-word-line",
  zip: "ri-folder-zip-line", // Use an icon for ZIP files
  rar: "ri-folder-zip-line",
  default: "ri-file-line", // Default icon for unknown file types
};

const HomeWorkPermitDetailView = () => {
  const { hwPermitNo: hwPermitNoFromURL } = useParams<{ hwPermitNo: string }>();
  const navigate = useNavigate();

  // Queries to fetch homework permit, employees, projects, and companies data
  const { data: hwPermitData, isLoading: isPermitLoading } =
    useGetHomeworkPermitByNumberQuery(hwPermitNoFromURL);

  const { data: employeeData } = useGetEmployeeDropDownQuery();
  const { data: projectData } = useGetProjectsQuery();
  const { data: companyData } = useGetCompaniesQuery();

  // Find Supervisor Names from IDs
  const getSupervisorNames = () => {
    return hwPermitData?.work_supervisor.map((id: number) => {
      const supervisor = employeeData?.find((emp: any) => emp.id === id);
      return supervisor ? supervisor.employee_fullname : `Unknown ID (${id})`;
    });
  };

  // Get Company Name
  const getCompanyName = () => {
    const company = companyData?.find(
      (comp: any) => comp.id === hwPermitData?.company
    );
    return company
      ? company.company_name
      : `Unknown Company ID (${hwPermitData?.company})`;
  };

  // Get Project Name
  const getProjectName = () => {
    const project = projectData?.find(
      (proj: any) => proj.id === hwPermitData?.project
    );
    return project
      ? project.project_name
      : `Unknown Project ID (${hwPermitData?.project})`;
  };

  // Extract document name from URL
  const extractDocumentName = (url: string) => {
    const parts = url.split("/");
    return parts[parts.length - 1];
  };

  // Extract file extension and return corresponding icon or image
  const getFileTypeIconOrImage = (documentUrl: string) => {
    const fileName = documentUrl.split("/").pop()?.split("?")[0] || "";
    const fileExtension = fileName.split(".").pop()?.toLowerCase() || "default";

    if (imageExtensions.includes(fileExtension)) {
      // Show the actual image if it's an image file
      return (
        <CardImg
          top
          width="150px"
          height="150px"
          src={documentUrl}
          alt={fileName}
          style={{ position: "relative" }}
        />
      );
    } else {
      // Return appropriate icon for non-image files
      const iconClass = fileIconMap[fileExtension] || fileIconMap["default"];
      return (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "150px" }}
        >
          <i className={`${iconClass} ri-4x`} />
        </div>
      );
    }
  };

  const truncateFileName = (fileName: string, maxLength = 20) => {
    if (fileName.length > maxLength) {
      const truncatedName = fileName.substring(0, maxLength) + "...";
      return truncatedName;
    }
    return fileName;
  };

  const handleClose = () => {
    navigate("/safety-app-workflow-approvel-list");
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Card>
          <CardHeader>
            <CardTitle>Home Work Permit</CardTitle>
          </CardHeader>
          <CardBody>
            <Row>
              <Col
                md={12}
                className="d-flex align-items-center justify-content-center"
              >
                {isPermitLoading ? (
                  <Spinner color="primary" />
                ) : (
                  <div>
                    <table className="table table-borderless table-nowrap ">
                      <tbody>
                        <tr>
                          <td className="fw-medium" style={{ width: "300px" }}>
                            Home Work Permit No :
                          </td>
                          <td>{hwPermitData?.hw_permit_no}</td>
                        </tr>
                        <tr>
                          <td className="fw-medium" style={{ width: "300px" }}>
                            Company :
                          </td>
                          <td>
                            <Badge color="info">{getCompanyName()}</Badge>
                          </td>
                        </tr>
                        {hwPermitData?.company_others && (
                          <tr>
                            <td
                              className="fw-medium"
                              style={{ width: "300px" }}
                            >
                              Other Company :
                            </td>
                            <td>{hwPermitData?.company_others}</td>
                          </tr>
                        )}
                        <tr>
                          <td className="fw-medium" style={{ width: "300px" }}>
                            Project :
                          </td>
                          <td>
                            <Badge color="primary">{getProjectName()}</Badge>
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-medium" style={{ width: "300px" }}>
                            Work Performed By :
                          </td>
                          <td>{hwPermitData?.work_performed_by}</td>
                        </tr>
                        <tr>
                          <td className="fw-medium" style={{ width: "300px" }}>
                            Work Supervisor :
                          </td>
                          <td>
                            {getSupervisorNames()?.map(
                              (name: string, index: number) => (
                                <Badge
                                  key={index}
                                  color="warning"
                                  className="me-2"
                                >
                                  {name}
                                </Badge>
                              )
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-medium" style={{ width: "300px" }}>
                            Supervisor Contact :
                          </td>
                          <td>
                            {hwPermitData?.supervisor_contact_number_others}
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-medium" style={{ width: "300px" }}>
                            Equipment Used :
                          </td>
                          <td>{hwPermitData?.equipment_used}</td>
                        </tr>
                        <tr>
                          <td className="fw-medium" style={{ width: "300px" }}>
                            Submit Date :
                          </td>
                          <td>{hwPermitData?.submit_date}</td>
                        </tr>
                        <tr>
                          <td className="fw-medium" style={{ width: "300px" }}>
                            Start Date of Work :
                          </td>
                          <td>{hwPermitData?.start_date_of_work}</td>
                        </tr>
                        <tr>
                          <td className="fw-medium" style={{ width: "300px" }}>
                            Start Time of Work :
                          </td>
                          <td>{hwPermitData?.start_time_of_work}</td>
                        </tr>
                        <tr>
                          <td className="fw-medium" style={{ width: "300px" }}>
                            End Date of Work :
                          </td>
                          <td>{hwPermitData?.end_date_of_work}</td>
                        </tr>
                        <tr>
                          <td className="fw-medium" style={{ width: "300px" }}>
                            End Time of Work :
                          </td>
                          <td>{hwPermitData?.end_time_of_work}</td>
                        </tr>
                        <tr>
                          <td className="fw-medium" style={{ width: "300px" }}>
                            Location & Work Description :
                          </td>
                          <td>{hwPermitData?.location_and_work_description}</td>
                        </tr>
                      </tbody>
                    </table>

                    <h5 className="mt-2">Worker And Site Area Details</h5>
                    <hr />
                    <Row className="mt-4">
                      <Col md={12}>
                        <Table className="table-borderless align-middle mb-0">
                          <thead className="table-light">
                            <tr>
                              <th scope="col">Employee</th>
                              <th scope="col">Work Location</th>
                            </tr>
                          </thead>
                          <tbody>
                            {hwPermitData?.work_area_allocation?.length > 0 ? (
                              hwPermitData.work_area_allocation.map(
                                (workArea: any, index: number) => (
                                  <tr key={workArea.id}>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 avatar-xs me-2">
                                          <div className="avatar-title bg-success-subtle text-success rounded-circle fs-13">
                                            {workArea?.employee_full_name?.charAt(
                                              0
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="flex-grow-1 ms-2 name">
                                        {workArea.employee_full_name}
                                      </div>
                                    </td>
                                    <td>{workArea.work_location}</td>
                                  </tr>
                                )
                              )
                            ) : (
                              <tr>
                                <td colSpan={4} className="text-center">
                                  No work locations found yet
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>

                    {/* Display Questionnaires in Table */}
                    <h5 className="mt-2">Check List</h5>
                    <hr />
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Question</th>
                          <th>Answer</th>
                        </tr>
                      </thead>
                      <tbody>
                        {hwPermitData?.questionnaires.map((q: any) => (
                          <tr key={q.id}>
                            <td>{q.question}</td>
                            <td>
                              <Badge
                                color={
                                  q.answer === "Yes" ? "success" : "danger"
                                }
                              >
                                {q.answer}
                              </Badge>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    {/* Display Documents */}
                    <h5 className="mt-2">Safety Documents</h5>
                    <hr />
                    <Row>
                      {hwPermitData?.documents?.map(
                        (document: any, index: number) => (
                          <Col key={index} md={3} className="mb-4">
                            <Card>
                              {getFileTypeIconOrImage(document?.attachment_url)}
                              <CardBody>
                                {/* Extract document name from URL */}
                                <CardTitle tag="h5">
                                  {truncateFileName(
                                    extractDocumentName(document?.attachment)
                                  )}
                                </CardTitle>
                              </CardBody>
                            </Card>
                          </Col>
                        )
                      )}
                    </Row>

                    {/* Display Status */}
                    <p>
                      <strong>Status:</strong>{" "}
                      {hwPermitData?.report_status_name}
                    </p>
                  </div>
                )}
              </Col>
            </Row>

            {/* Navigation Buttons */}
            <div className="d-flex justify-content-between mt-4">
              <Button onClick={handleClose} color="secondary">
                Close
              </Button>
            </div>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default HomeWorkPermitDetailView;
