import React, { useContext, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Col,
  Row,
  Input,
  Form,
  Label,
  FormGroup,
  Spinner,
} from "reactstrap";
import Select from "react-select"; // Import Select from react-select
import {
  useCreateHotworkPermitMutation,
  useUpdateHotworkPermitMutation,
  useGetHotworkPermitByNumberQuery
} from "slices/safetyApp/safetyappSlice";
import { useGetEmployeeDropDownByProjectQuery } from "slices/employee/employeeSlice";
import { useGetCompaniesQuery } from "slices/InspectionReport/InspectionReportSlice";

import LoadingOverlay from "react-loading-overlay-ts";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { useParams, useNavigate } from "react-router-dom";
import { ServiceContext } from "context/ServiceContextProvider";

interface Step1HotWorkPermitProps {
  selectedProject: string;
  projectsData: any;
  nextStep: () => void;
  // setHwPermitNo: (no: string) => void; 
  // hwPermitNo: string | null; 
}

interface SupervisorOption {
  value: number;
  label: string;
}


interface FormValues {
  work_performed_by: string;
  project: string;
  name_of_hotwork_supervisor: number[];
  names_of_other_workers_involved_in_hotwork: string;
  contact_number: string;
  type_of_hotwork_equipment_used: string;
  name_of_hotwork_equipment_operator: string;
  work_location: string;
  work_description: string;
  start_date_of_work: string;
  start_time_of_work: string;
  end_date_of_work: string;
  end_time_of_work: string;
}


const Step1HotWorkPermit: React.FC<Step1HotWorkPermitProps> = ({
  selectedProject,
  projectsData,
  nextStep,
}) => {
  const { getEmployeeUUID } = useContext(ServiceContext);
  const { selectedProject: urlProjectId, hwPermitNo: hwPermitNoFromURL } = useParams<{ selectedProject: string, hwPermitNo: string }>();
  const navigate = useNavigate();
  const { data: supervisorsData, isLoading: supervisorsDataLoading } = useGetEmployeeDropDownByProjectQuery(urlProjectId, {
    skip: !urlProjectId,
  });
  const [createHotworkPermit, { isLoading: hotpermitisLoading }] =
    useCreateHotworkPermitMutation();
  const [updateHotworkPermit, { isLoading: patchisLoading }] =
    useUpdateHotworkPermitMutation();

  const {
    data: hwPermitData,
    isLoading: isPermitLoading,
    refetch: refetchHotWorkPermit,
  } = useGetHotworkPermitByNumberQuery(hwPermitNoFromURL);

  const supervisorOptions: SupervisorOption[] =
    supervisorsData?.map((supervisor) => ({
      value: supervisor.id,
      label: supervisor.employee_fullname,
    })) || [];

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Add 1 to month since it's zero-indexed
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      work_performed_by: hwPermitData?.work_performed_by || "",
      name_of_hotwork_equipment_operator:
        hwPermitData?.name_of_hotwork_equipment_operator || "",
      project: selectedProject,
      name_of_hotwork_supervisor: hwPermitData?.name_of_hotwork_supervisor || [],
      names_of_other_workers_involved_in_hotwork:
        hwPermitData?.names_of_other_workers_involved_in_hotwork || "",
      contact_number: hwPermitData?.contact_number || "",
      type_of_hotwork_equipment_used:
        hwPermitData?.type_of_hotwork_equipment_used || "",
      work_location: hwPermitData?.work_location || "",
      work_description: hwPermitData?.work_description || "",
      start_date_of_work: hwPermitData?.start_date_of_work || getCurrentDate(),
      start_time_of_work: hwPermitData?.start_time_of_work || "",
      end_date_of_work: hwPermitData?.end_date_of_work || "",
      end_time_of_work: hwPermitData?.end_time_of_work || "",
    },
    validationSchema: Yup.object({
      name_of_hotwork_supervisor: Yup.array().min(
        1,
        "At least one supervisor is required"
      ),
      work_performed_by: Yup.string().optional(),
      work_location: Yup.string().optional(),
      work_description: Yup.string().optional(),
      contact_number: Yup.string().optional(),
      names_of_other_workers_involved_in_hotwork: Yup.string().optional(),
      name_of_hotwork_equipment_operator: Yup.string()
        .required("Name of hotwork equipment operator is required"),
      start_date_of_work: Yup.date().required("Start date is required"),
      start_time_of_work: Yup.string().required("Start time is required"),
      end_date_of_work: Yup.date().required("End date is required"),
      end_time_of_work: Yup.string().required("End time is required"),
    }),
    onSubmit: async (values) => {
      try {
        const payload = { ...values };
        let response;

        if (hwPermitNoFromURL) {
          response = await updateHotworkPermit({
            hw_permit_no: hwPermitNoFromURL,
            updatedPermitData: payload,
          }).unwrap();
        } else {
          response = await createHotworkPermit(payload).unwrap();
          localStorage.setItem("hwPermitNo", response.hw_permit_no);
          navigate(
            `/hotwork-permit-wizard/${selectedProject}/${response.hw_permit_no}`
          );
        }
        formik.resetForm();
        nextStep();
      } catch (error) {
        console.error("Failed to submit the form", error);
      }
    },
  });

  useEffect(() => {
    if (supervisorsData && getEmployeeUUID) {
      const defaultSupervisor = supervisorsData.find(
        (supervisor) => supervisor.employee_uuid === getEmployeeUUID
      );
      if (defaultSupervisor) {
        formik.setFieldValue(
          "name_of_hotwork_supervisor",
          [defaultSupervisor.id]
        );
      }
    }
  }, [supervisorsData, getEmployeeUUID]);

  useEffect(() => {
    if (hwPermitNoFromURL) {
      refetchHotWorkPermit();
    }
  }, [hwPermitNoFromURL, refetchHotWorkPermit]);

  useEffect(() => {
    // Ensure data is fetched and not currently loading
    if (!isPermitLoading && hwPermitData) {
      const {
        work_performed_by,
        name_of_hotwork_supervisor,
        names_of_other_workers_involved_in_hotwork,
        contact_number,
        type_of_hotwork_equipment_used,
        name_of_hotwork_equipment_operator,
        work_location,
        work_description,
        start_date_of_work,
        start_time_of_work,
        end_date_of_work,
        end_time_of_work,
      } = hwPermitData;

      // Set form values with fallback defaults
      formik.setValues({
        work_performed_by: work_performed_by || "",
        project: selectedProject,
        name_of_hotwork_supervisor: name_of_hotwork_supervisor || [],
        names_of_other_workers_involved_in_hotwork:
          names_of_other_workers_involved_in_hotwork || "",
        contact_number: contact_number || "",
        type_of_hotwork_equipment_used: type_of_hotwork_equipment_used || "",
        name_of_hotwork_equipment_operator:
          name_of_hotwork_equipment_operator || "",
        start_date_of_work: start_date_of_work || getCurrentDate(),
        start_time_of_work: start_time_of_work || "",
        end_date_of_work: end_date_of_work || "",
        end_time_of_work: end_time_of_work || "",
        work_location: work_location || "",
        work_description: work_description || "",
      });
    }
  }, [isPermitLoading, hwPermitData, selectedProject]);


  const handleSupervisorChange = (selectedOptions: SupervisorOption[]) => {
    const selectedIds = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    formik.setFieldValue("name_of_hotwork_supervisor", selectedIds);
  };

  const handleSupervisorOthersChange = (tags: string[]) => {
    formik.setFieldValue("names_of_other_workers_involved_in_hotwork", tags.join(','));
  };

  const handleSupervisorContactNumberChange = (tags: string[]) => {
    formik.setFieldValue("contact_number", tags.join(','));
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <LoadingOverlay active={isPermitLoading} spinner text="Fetching...">
        <Row>
          <Col md={12}>
            <Label className="card-title"> Site Information</Label>
            <hr />
          </Col>

          {/* <Col md={6}>
            <FormGroup>
              <Label for="work_performed_by">
                Work Performed By <span className="text-danger">*</span>
              </Label>
              <Input
                id="work_performed_by"
                name="work_performed_by"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.work_performed_by}
                invalid={
                  formik.touched.work_performed_by &&
                  !!formik.errors.work_performed_by
                }
              />
              {formik.touched.work_performed_by &&
                formik.errors.work_performed_by ? (
                <div className="text-danger">
                  {formik.errors.work_performed_by as string}
                </div>
              ) : null}
            </FormGroup>
          </Col> */}

          <Col md={6}>
            <FormGroup>
              <Label for="name_of_hotwork_supervisor">
                Work Supervised By <span className="text-danger">*</span>
              </Label>
              <Select
                id="name_of_hotwork_supervisor"
                isMulti
                options={supervisorOptions}
                value={supervisorOptions.filter((option) =>
                  formik.values.name_of_hotwork_supervisor.includes(option.value)
                )}
                onChange={handleSupervisorChange}
                isLoading={supervisorsDataLoading}
                isDisabled={true}
              />
              {formik.touched.name_of_hotwork_supervisor &&
                formik.errors.name_of_hotwork_supervisor ? (
                <div className="text-danger">
                  {formik.errors.name_of_hotwork_supervisor as string}
                </div>
              ) : null}
            </FormGroup>
          </Col>

          {/* Project Name (read-only) */}
          <Col md={6}>
            <FormGroup>
              <Label for="project_name">Project Name</Label>
              <Input
                id="project_name"
                name="project_name"
                type="text"
                value={projectsData?.project_name || ""}
                readOnly
                disabled
              />
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="names_of_other_workers_involved_in_hotwork">Other Workers Involved </Label>
              <TagsInput
                addKeys={[9, 13, 188]}
                value={formik.values.names_of_other_workers_involved_in_hotwork ? formik.values.names_of_other_workers_involved_in_hotwork.split(",") : []}
                // value={formik.values.work_supervisor_others}
                onChange={handleSupervisorOthersChange}
                inputProps={{
                  placeholder: "Add a Workers",
                  style: {
                    padding: "6px",
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    minWidth: "150px",
                    border: "none",
                    outline: "none",
                    flex: 1,
                  },
                }}
              />
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="supervisor_contact_number_others">
                Contact Number
              </Label>
              <TagsInput
                addKeys={[9, 13, 188]}
                value={formik.values.contact_number ? formik.values.contact_number.split(",") : []}
                // value={formik.values.supervisor_contact_number_others}
                onChange={handleSupervisorContactNumberChange}
                inputProps={{
                  placeholder: "Add contact number",
                  style: {
                    padding: "6px",
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    minWidth: "150px",
                    border: "none",
                    outline: "none",
                    flex: 1,
                  },
                }}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="type_of_hotwork_equipment_used">Equipment Used</Label>
              <TagsInput
                addKeys={[9, 13, 188]}
                value={formik.values.type_of_hotwork_equipment_used ? formik.values.type_of_hotwork_equipment_used.split(",") : []}
                onChange={(tags) => formik.setFieldValue("type_of_hotwork_equipment_used", tags.join(","))}
                inputProps={{
                  placeholder: "Add equipment",
                  style: {
                    padding: "6px",
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    minWidth: "150px",
                    border: "none",
                    outline: "none",
                    flex: 1,
                  },
                }}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="name_of_hotwork_equipment_operator">
                Equipment Operator <span className="text-danger">*</span>
              </Label>
              <Input
                id="name_of_hotwork_equipment_operator"
                name="name_of_hotwork_equipment_operator"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.name_of_hotwork_equipment_operator}
                invalid={
                  formik.touched.name_of_hotwork_equipment_operator &&
                  !!formik.errors.name_of_hotwork_equipment_operator
                }
              />
              {formik.touched.name_of_hotwork_equipment_operator &&
                formik.errors.name_of_hotwork_equipment_operator ? (
                <div className="text-danger">
                  {formik.errors.name_of_hotwork_equipment_operator}
                </div>
              ) : null}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="start_date_of_work">
                Start Date <span className="text-danger">*</span>
              </Label>
              <Input
                id="start_date_of_work"
                name="start_date_of_work"
                type="date"
                onChange={formik.handleChange}
                value={formik.values.start_date_of_work}
                invalid={
                  formik.touched.start_date_of_work &&
                  !!formik.errors.start_date_of_work
                }
              />
              {formik.touched.start_date_of_work &&
                formik.errors.start_date_of_work ? (
                <div className="text-danger">
                  {formik.errors.start_date_of_work as string}
                </div>
              ) : null}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="start_time_of_work">
                Start Time <span className="text-danger">*</span>
              </Label>
              <Input
                id="start_time_of_work"
                name="start_time_of_work"
                type="time"
                onChange={formik.handleChange}
                value={formik.values.start_time_of_work}
                invalid={
                  formik.touched.start_time_of_work &&
                  !!formik.errors.start_time_of_work
                }
              />
              {formik.touched.start_time_of_work &&
                formik.errors.start_time_of_work ? (
                <div className="text-danger">
                  {formik.errors.start_time_of_work as string}
                </div>
              ) : null}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="end_date_of_work">
                End Date <span className="text-danger">*</span>
              </Label>
              <Input
                id="end_date_of_work"
                name="end_date_of_work"
                type="date"
                onChange={formik.handleChange}
                value={formik.values.end_date_of_work}
                invalid={
                  formik.touched.end_date_of_work &&
                  !!formik.errors.end_date_of_work
                }
              />
              {formik.touched.end_date_of_work &&
                formik.errors.end_date_of_work ? (
                <div className="text-danger">
                  {formik.errors.end_date_of_work as string}
                </div>
              ) : null}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="end_time_of_work">
                End Time <span className="text-danger">*</span>
              </Label>
              <Input
                id="end_time_of_work"
                name="end_time_of_work"
                type="time"
                onChange={formik.handleChange}
                value={formik.values.end_time_of_work}
                invalid={
                  formik.touched.end_time_of_work &&
                  !!formik.errors.end_time_of_work
                }
              />
              {formik.touched.end_time_of_work &&
                formik.errors.end_time_of_work ? (
                <div className="text-danger">
                  {formik.errors.end_time_of_work as string}
                </div>
              ) : null}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="location">Location</Label>
              <TagsInput
                addKeys={[9, 13, 188]}
                value={formik.values.work_location ? formik.values.work_location.split(",") : []}
                onChange={(tags) => formik.setFieldValue("work_location", tags.join(","))}
                inputProps={{
                  placeholder: "Add locations",
                  style: {
                    padding: "6px",
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    minWidth: "150px",
                    border: "none",
                    outline: "none",
                    flex: 1,
                  },
                }}
              />
            </FormGroup>
          </Col>



          <Col md={12}>
            <FormGroup>
              <Label for="work_description">
                Work Description
              </Label>
              <Input
                id="work_description"
                name="work_description"
                type="textarea"
                onChange={(e) => formik.setFieldValue("work_description", e.target.value)}
                value={formik.values.work_description}
                invalid={
                  formik.touched.work_description &&
                  !!formik.errors.work_description
                }
              />
              {formik.touched.work_description && formik.errors.work_description ? (
                <div className="text-danger">
                  {formik.errors.work_description as string}
                </div>
              ) : null}
            </FormGroup>
          </Col>
          <Col md={12}>
            <Button type="submit" color="primary">
              {hotpermitisLoading || patchisLoading ? (
                <Spinner size="sm" />
              ) : (
                "Next"
              )}
            </Button>
          </Col>
        </Row>
      </LoadingOverlay>
    </Form>
  );
};

export default Step1HotWorkPermit;
