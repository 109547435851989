import React, { useState } from "react";
import {
  Col,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Input,
  FormGroup,
  Label,
  CardBody,
  Card,
  CardHeader,
} from "reactstrap";
import classnames from "classnames";
import LoadingOverlay from "react-loading-overlay-ts";
import {
  useGetAppSettingsQuery,
  useUpdateAppSettingByIdMutation,
  useGetFeatureFlagsQuery,
  useUpdateFeatureFlagByIdMutation,
} from "slices/pageSettings/pageSettingsSlice";
import { Link } from "react-router-dom";
import BreadCrumb from "Components/Common/BreadCrumb";

interface FormState {
  [key: number]: string | boolean; // or any other type that your form values expect
}

const PageSettings = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [isUpdating, setIsUpdating] = useState(false); // To show overlay during update
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(
    null
  ); // State for debounce timeout

  const {
    data: appSettings,
    isLoading: appSettingsLoading,
    refetch,
  } = useGetAppSettingsQuery(undefined);
  const [updateAppSetting] = useUpdateAppSettingByIdMutation();
  const {
    data: featureFlags,
    isLoading: featureFlagsLoading,
    refetch: refetchFeatureFlags,
  } = useGetFeatureFlagsQuery(undefined);
  const [updateFeatureFlag] = useUpdateFeatureFlagByIdMutation();
  const [formState, setFormState] = useState<FormState>({});

  const tabChange = (tab: any) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  // Debounced function to handle app setting updates for text input
  const debouncedHandleTextChange = (id: number, value: string) => {
    setFormState((prev) => ({
      ...prev,
      [id]: value,
    }));

    if (debounceTimeout) {
      clearTimeout(debounceTimeout); // Clear the previous debounce timeout
    }

    // Set a new debounce timeout
    const timeout = setTimeout(async () => {
      setIsUpdating(true);
      const updateData = { value };
      await updateAppSetting({ id, updateData })
        .unwrap()
        .then(() => refetch())
        .finally(() => setIsUpdating(false));
    }, 500); // Delay of 500ms for debounce

    setDebounceTimeout(timeout); // Store the debounce timeout in state
  };

  const handleAppSettingChange = (id: number, value: any) => {
    if (typeof value === "boolean") {
      setFormState((prev) => ({
        ...prev,
        [id]: value,
      }));
      // Call the update mutation for boolean fields directly
      setIsUpdating(true);
      const updateData = { value };
      updateAppSetting({ id, updateData })
        .unwrap()
        .then(() => refetch())
        .finally(() => setIsUpdating(false));
    } else {
      debouncedHandleTextChange(id, value);
    }
  };

  const handleTextSubmit = async (id: number, value: string) => {
    setIsUpdating(true); // Show loading overlay

    const updateData = { value }; // Prepare the data for API

    await updateAppSetting({ id, updateData })
      .unwrap()
      .then(() => {
        refetch(); // Refresh the data after successful update
        // alert('Setting updated successfully!');
      })
      .catch((error) => {
        console.error("Error updating setting:", error);
      })
      .finally(() => {
        setIsUpdating(false); // Hide loading overlay
      });
  };

  const handleFeatureFlagChange = async (id: number, flag: boolean) => {
    setFormState((prev) => ({
      ...prev,
      [id]: flag,
    }));
    setIsUpdating(true);
    const updateData = { flag };
    await updateFeatureFlag({ id, updateData })
      .unwrap()
      .then(() => refetchFeatureFlags())
      .finally(() => setIsUpdating(false));
  };

  if (appSettingsLoading || featureFlagsLoading) return <div>Loading...</div>;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Profile" pageTitle="Settings" />
          <Row>
            <Col xxl={12}>
              <CardHeader>
                <Nav
                  className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                  role="tablist"
                >
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => tabChange("1")}
                    >
                      <i className="fas fa-home"></i>
                      App settings
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => tabChange("2")}
                    >
                      <i className="far fa-user"></i>
                      Feature-flags
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardHeader>

              <LoadingOverlay active={isUpdating} spinner text="Updating...">
                <TabContent activeTab={activeTab}>
                  {/* App Settings Tab */}
                  <TabPane tabId="1">
                    <Row>
                      {appSettings?.map((setting: any) => (
                        <Col key={setting.id} lg={12}>
                          <div className="team-list list-view-filter">
                            <Card className="team-box">
                              <CardBody className="px-4">
                                <Row className="align-items-center team-row">
                                  <Col lg={4}>
                                    <div className="team-profile-img">
                                      <div className="avatar-lg img-thumbnail rounded-circle">
                                        <div className="avatar-title rounded-circle">
                                          <i className="ri-settings-5-line fs-2"></i>
                                        </div>
                                      </div>
                                      <div className="team-content">
                                        <Link to="#" className="d-block">
                                          <h5 className="fs-16 mb-1">
                                            {setting?.name}
                                          </h5>
                                        </Link>
                                        <p className="text-muted mb-0">
                                          {setting?.description}
                                        </p>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col lg={4}>
                                    <Row className="text-muted text-center"></Row>
                                  </Col>
                                  <Col lg={2}>
                                    <FormGroup>
                                      <Label>{setting?.name}</Label>
                                      {setting.type === "text" && (
                                        <div className="d-flex">
                                          <Input
                                            type="text"
                                            value={
                                              formState[setting.id] ||
                                              setting.value ||
                                              ""
                                            }
                                            onChange={(e) =>
                                              setFormState((prev) => ({
                                                ...prev,
                                                [setting.id]: e.target.value,
                                              }))
                                            }
                                          />
                                          &nbsp;
                                          <span
                                            className="btn btn-soft-success btn-md shadow-none"
                                            onClick={() =>
                                              handleTextSubmit(
                                                setting.id,
                                                formState[setting.id] ||
                                                  setting.value
                                              )
                                            }
                                          >
                                            <i className="ri-check-line"></i>
                                          </span>
                                        </div>
                                      )}

                                      {setting.type === "color_dropdown" && (
                                        <Input
                                          type="select"
                                          value={
                                            formState[setting.id] ||
                                            setting.value ||
                                            ""
                                          }
                                          onChange={(e) =>
                                            handleAppSettingChange(
                                              setting.id,
                                              e.target.value
                                            )
                                          }
                                          style={{ padding: "0.5rem" }}
                                        >
                                          <option value="#FF0000">
                                            <span
                                              style={{
                                                backgroundColor: "#FF0000",
                                                width: "20px",
                                                height: "20px",
                                                display: "inline-block",
                                                marginRight: "10px",
                                                verticalAlign: "middle",
                                              }}
                                            ></span>
                                            Red
                                          </option>
                                          <option value="#00FF00">
                                            <span
                                              style={{
                                                backgroundColor: "#00FF00",
                                                width: "20px",
                                                height: "20px",
                                                display: "inline-block",
                                                marginRight: "10px",
                                                verticalAlign: "middle",
                                              }}
                                            ></span>
                                            Green
                                          </option>
                                          <option value="#0000FF">
                                            <span
                                              style={{
                                                backgroundColor: "#0000FF",
                                                width: "20px",
                                                height: "20px",
                                                display: "inline-block",
                                                marginRight: "10px",
                                                verticalAlign: "middle",
                                              }}
                                            ></span>
                                            Blue
                                          </option>
                                          <option value="#FFFF00">
                                            <span
                                              style={{
                                                backgroundColor: "#FFFF00",
                                                width: "20px",
                                                height: "20px",
                                                display: "inline-block",
                                                marginRight: "10px",
                                                verticalAlign: "middle",
                                              }}
                                            ></span>
                                            Yellow
                                          </option>
                                        </Input>
                                      )}

                                      {setting.type === "switch" && (
                                        <div className="form-check form-switch">
                                          <Input
                                            type="switch"
                                            checked={
                                              formState[setting.id] === "on"
                                            }
                                            onChange={() =>
                                              handleAppSettingChange(
                                                setting.id,
                                                formState[setting.id] === "on"
                                                  ? "off"
                                                  : "on"
                                              )
                                            }
                                          />
                                          <Label className="form-check-label">
                                            {formState[setting.id] === "on"
                                              ? "On"
                                              : "Off"}
                                          </Label>
                                        </div>
                                      )}
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </TabPane>

                  {/* Feature-flags Tab */}
                  <TabPane tabId="2">
                    <Row>
                      {featureFlags?.map((flag: any) => (
                        <Col key={flag.id} lg={12}>
                          <div className="team-list list-view-filter">
                            <Card className="team-box">
                              <CardBody className="px-4">
                                <Row className="align-items-center team-row">
                                  <Col lg={4}>
                                    <div className="team-profile-img">
                                      <div className="avatar-lg img-thumbnail rounded-circle">
                                        <div className="avatar-title rounded-circle">
                                          {/* Conditional icon rendering based on the flag name */}
                                          {flag?.name === "Members" && (
                                            <i className="ri-team-fill fs-2"></i>
                                          )}
                                          {flag?.name ===
                                            "Inspection Report" && (
                                            <i className="mdi mdi-file fs-2"></i>
                                          )}
                                          {flag?.name === "Workflow" && (
                                            <i className="bx bx-line-chart-down fs-2"></i>
                                          )}
                                          {flag?.name === "Access Control" && (
                                            <i className="bx bxs-wrench fs-2"></i>
                                          )}
                                          {flag?.name ===
                                            "Resource Inventory" && (
                                            <i className="bx bxs-store fs-2"></i>
                                          )}
                                          {flag?.name === "Project" && (
                                            <i className="bx bxs-folder-open fs-2"></i>
                                          )}
                                          {flag?.name === "Task" && (
                                            <i className="bx bxs-server fs-2"></i>
                                          )}
                                          {/* Fallback default icon */}
                                          {![
                                            "Inspection Report",
                                            "Access Control",
                                            "Resource Inventory",
                                            "Project",
                                            "Task",
                                            "Members",
                                            "Workflow",
                                          ].includes(flag?.name) && (
                                            <i className="ri-settings-5-line fs-2"></i>
                                          )}
                                        </div>
                                      </div>

                                      <div className="team-content">
                                        <Link to="#" className="d-block">
                                          <h5 className="fs-16 mb-1">
                                            {flag?.name}
                                          </h5>
                                        </Link>
                                        <p className="text-muted mb-0">
                                          {flag?.description}
                                        </p>
                                      </div>
                                    </div>
                                  </Col>

                                  <Col lg={2}>
                                    <FormGroup>
                                      <Label>{flag.name}</Label>
                                      <div className="form-check form-switch">
                                        <Input
                                          type="switch"
                                          checked={
                                            !!(formState[flag.id] ?? flag.flag)
                                          }
                                          onChange={() =>
                                            handleFeatureFlagChange(
                                              flag.id,
                                              !(formState[flag.id] ?? flag.flag) // Toggle the boolean value
                                            )
                                          }
                                        />
                                        <Label className="form-check-label">
                                          {formState[flag.id] ?? flag.flag
                                            ? "Enabled"
                                            : "Disabled"}
                                        </Label>
                                      </div>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </TabPane>
                </TabContent>
              </LoadingOverlay>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PageSettings;
