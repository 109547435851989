import React, { useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  UncontrolledAlert
} from 'reactstrap';
import {
  workflowSlice,
  useGetWorkflowsQuery,
  useUpdateSafetyAppWorkflowProjectMutation,
  useUpdateSafetyAppAssignedWorkflowMutation, 
  useDeleteSafetyAppAssignedWorkflowMutation,
} from 'slices/workflow/WorkFlowSlice';
import { useDispatch } from "react-redux";

interface WorkflowProps {
  workflowData: any;
  projectId: string | undefined;
}

const WorkflowSettingTab: React.FC<WorkflowProps> = ({ workflowData, projectId }) => {

  const dispatch = useDispatch();

  const [workflowEditModal, setWorkflowEditModal] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [selectedWorkflow, setSelectedWorkflow] = useState<string>("");
  const [selectedReport, setSelectedReport] = useState<string>("");
  const [selectedWorkflowName, setSelectedWorkflowName] = useState<string>("");
  const [isDirty, setIsDirty] = useState(false);
  const [editedWorkflowData, setEditedWorkflowData] = useState<any>({});
  const [editingWorkflow, setEditingWorkflow] = useState(false);


  console.log('editedWorkflowData', editedWorkflowData);

  const handleWorkflowSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedWorkflow(event.target.value);
  };

  const [updateSafteyAppProjectWorkflow, { isLoading: projectWorkflowLoading }] = useUpdateSafetyAppWorkflowProjectMutation();
  const [updateSafteyAppAssignedWorkflow, { isLoading: assignedWorkflowLoading }] = useUpdateSafetyAppAssignedWorkflowMutation();
  const [deleteSafteyAppAssignedWorkflow, { isLoading: deleteWorkflowLoading }] = useDeleteSafetyAppAssignedWorkflowMutation();

  const { data: workFlow, isLoading: isLoadingWorkFlow } = useGetWorkflowsQuery("SAFETY_APP");

  const toggleWorkflowEditModal = () => setWorkflowEditModal(!workflowEditModal);
  const toggleWorkflowDeleteModal = () => setDeleteModalOpen(!deleteModalOpen);

  const handleEditClick = () => {
    setEditingWorkflow(true);
    setIsDirty(true);
  };

  const handleSwitchChange = (workflowId: number, field: string, value: boolean) => {
    setEditedWorkflowData((prevData: any) => ({
      ...prevData,
      [workflowId]: {
        ...prevData[workflowId],
        [field]: value,
      },
    }));
    setIsDirty(true);
  };

  const removeWorkflowChanges = async () => {
    await deleteSafteyAppAssignedWorkflow({selectedReport, projectId});
    toggleWorkflowDeleteModal();
  }

  const saveAllWorkflowChanges = async () => {
    try {
      for (const workflowId in editedWorkflowData) {
        await updateSafteyAppAssignedWorkflow({
          id: workflowId,
          ...editedWorkflowData[workflowId],
        });
      }

      dispatch(workflowSlice.util.invalidateTags([
        // { type: "GetProjectsByWorkFlow" },
        // { type: "GetWorkflowsByCategoryName" },
        { type: "SafetyAppApproval" },
      ]));

      setIsDirty(false);
      setEditingWorkflow(false);
      setEditedWorkflowData({});
    } catch (error) {
      console.error("Failed to update workflows:", error);
    }
  };

  const assignWorkflowOnSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await updateSafteyAppProjectWorkflow({
      id: selectedWorkflow,
      projects: [projectId],
      report_id: selectedReport,
    });
    toggleWorkflowEditModal();
  };

  console.log('workflowData', workflowData)

  return (
    <React.Fragment>
      <UncontrolledAlert color="warning" className="alert-additional material-shadow mb-3 text-dark">
        <div className="alert-body">
          <div className="d-flex">
            <div className="flex-shrink-0 me-3">
              <i className="ri-alert-line fs-16 align-middle"></i>
            </div>
            <div className="flex-grow-1">
              <h5 className="alert-heading">Workflow Configuration Update!</h5>
              <p className="mb-0">Changes made to the workflow configuration will reflect to all Active Aprrovals belongs to this Project.</p>
            </div>
          </div>
        </div>
      </UncontrolledAlert>

      {workflowData?.map((report_data: any) => (
        <Card key={report_data.id}>
          <CardBody>
            <div className="text-muted">
              <div className="d-flex justify-content-between">
                <div>
                  <h6 className="mb-3 fw-semibold text-uppercase">
                    <span className="text-muted">Report Name:</span> {" "}
                    <span className="fw-semibold">{report_data?.report_name}</span>
                  </h6>
                  <h6 className="mb-3 fw-semibold text-uppercase">
                    <span className="text-muted">Workflow Name:</span> {" "}
                    <span className="fw-semibold">{report_data?.workflow_name}</span>
                  </h6>
                </div>
                <div>
                  {report_data?.assigned_workflow?.length === 0 && (
                    <button onClick={() => { setSelectedReport(report_data?.id); toggleWorkflowEditModal(); }} type="button" className="btn btn-sm btn-primary">Assign WorkFlow</button>
                  )}

                  {(report_data?.assigned_workflow?.length > 0 && !editingWorkflow) ? (
                    <>
                    <Button
                      color="secondary"
                      size="sm"
                      className='me-2'
                      onClick={() => { setSelectedReport(report_data?.id); handleEditClick(); }}>
                      Edit Workflow
                    </Button>
                    <Button 
                      onClick={() => { setSelectedReport(report_data?.id); setSelectedWorkflowName(report_data?.workflow_name); toggleWorkflowDeleteModal(); }} 
                      color="danger" 
                      size="sm"
                     >Remove
                    </Button>
                    </>
                  ) : (
                    (selectedReport === report_data.id && isDirty) && (
                      <Button
                        color="primary"
                        size="sm"
                        onClick={saveAllWorkflowChanges}>
                        {assignedWorkflowLoading ? <Spinner size="sm" /> : "Save Workflow"}
                      </Button>
                    )
                  )}
                </div>
              </div>
              {report_data?.assigned_workflow?.map((workflow: any) => (
                <div className="pt-3 border-top border-top-dashed mt-4" key={workflow.id}>
                  <Row>
                    <Col lg={3} sm={6} className="mb-2">
                      <p className="mb-2 text-uppercase fw-medium">Step Name</p>
                      <h5 className="fs-15 mb-0">{workflow?.level}) {workflow?.step_name}</h5>
                    </Col>
                    <Col lg={2} sm={6} className="mb-2">
                      <p className="mb-2 text-uppercase fw-medium">Allow Resubmit</p>
                      <Input
                        type="switch"
                        checked={
                          editedWorkflowData[workflow.id]?.is_allow_resubmit ??
                          workflow.is_allow_resubmit
                        }
                        onChange={(e) =>
                          handleSwitchChange(workflow.id, "is_allow_resubmit", e.target.checked)
                        }
                        disabled={!editingWorkflow}
                      />
                    </Col>
                    <Col lg={2} sm={6} className="mb-2">
                      <p className="mb-2 text-uppercase fw-medium">Allow Revoke</p>
                      <Input
                        type="switch"
                        checked={
                          editedWorkflowData[workflow.id]?.is_allow_revoke ??
                          workflow.is_allow_revoke
                        }
                        onChange={(e) =>
                          handleSwitchChange(workflow.id, "is_allow_revoke", e.target.checked)
                        }
                        disabled={!editingWorkflow}
                      />
                    </Col>
                    <Col lg={2} sm={6} className="mb-2">
                      <p className="mb-2 text-uppercase fw-medium">Allow Close</p>
                      <Input
                        type="switch"
                        checked={
                          editedWorkflowData[workflow.id]?.is_allow_close ??
                          workflow.is_allow_close
                        }
                        onChange={(e) =>
                          handleSwitchChange(workflow.id, "is_allow_close", e.target.checked)
                        }
                        disabled={!editingWorkflow}
                      />
                    </Col>
                  </Row>
                </div>
              ))}
            </div>
          </CardBody>
        </Card>
      ))}
      <Modal isOpen={workflowEditModal} toggle={toggleWorkflowEditModal}>
        <Form onSubmit={assignWorkflowOnSubmit}>
          <ModalHeader toggle={toggleWorkflowEditModal}>Assign Workflow To Report</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="reason">Workflow Type</Label>
              <select
                required
                className="form-control"
                value={selectedWorkflow}
                onChange={handleWorkflowSelect}
              >
                <option value="">Select Workflow</option>
                {workFlow?.map((workflow: any, index: number) => (
                  <option key={index} value={workflow.id}>
                    {workflow.workflow_name}
                  </option>
                ))}
              </select>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" type="submit">
              {projectWorkflowLoading ? <Spinner size="sm" /> : <> Submit</>}
            </Button>
            <Button color="secondary" disabled={projectWorkflowLoading} onClick={toggleWorkflowEditModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        isOpen={deleteModalOpen}
        toggle={() => setDeleteModalOpen(false)}
        // centered
      >
        <ModalHeader toggle={() => setDeleteModalOpen(false)}>
          Confirm Remove
        </ModalHeader>
        <ModalBody>
          Are you sure you want to remove workflow <b>{selectedWorkflowName}</b>? This action cannot be
          undone.
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            disabled={deleteWorkflowLoading}
            onClick={() => setDeleteModalOpen(false)}
          >
            Cancel
          </Button>
          <Button
            color="danger"
            onClick={removeWorkflowChanges}
            disabled={deleteWorkflowLoading}
          >
            {deleteWorkflowLoading ? <Spinner size="sm" /> : "Delete"}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default WorkflowSettingTab;
