import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
} from "reactstrap";
import Select from "react-select";


interface AddEmployeeModalProps {
  isOpen: boolean;
  toggle: () => void;
  employees: Array<{ id: number; employee_fullname: string }>;
  employeesDataLoading: boolean;
  selectedEmployee: number | null;
  setSelectedEmployee: (value: number | null) => void;
  isSubmitting: boolean;
  onSubmit: () => void;
  isPermitFetching: boolean;
}

const AddEmployeeModal: React.FC<AddEmployeeModalProps> = ({
  isOpen,
  toggle,
  employees,
  employeesDataLoading,
  selectedEmployee,
  setSelectedEmployee,
  isSubmitting,
  onSubmit,
  isPermitFetching
}) => {

  const employeeOptions = employees.map((employee) => ({
    value: employee.id,
    label: employee.employee_fullname,
  }));

  const onFormSubmit = (e: any) => {
    e.preventDefault();
    onSubmit();
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Form onSubmit={(e)=>onFormSubmit(e)}>
      <ModalHeader toggle={toggle}>Add Employee</ModalHeader>
      <ModalBody>
          <FormGroup>
            <Label for="employeeSelect">Select Employee</Label>
            <Select
              id="employeeSelect"
              options={employeeOptions}
              value={employeeOptions.find((option) => option.value === selectedEmployee) || null}
              onChange={(selectedOption: any) => setSelectedEmployee(selectedOption ? selectedOption.value : null)}
              placeholder="Select an employee"
              isLoading={employeesDataLoading}
              isClearable
              required
            />
          </FormGroup>
        
      </ModalBody>
      <ModalFooter>
        <Button color="primary" type="submit" disabled={isSubmitting || isPermitFetching}>
          {isSubmitting || isPermitFetching ? <Spinner size="sm" /> : "Add"}
        </Button>
        <Button disabled={isSubmitting || isPermitFetching} color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddEmployeeModal;
