import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Spinner,
} from "reactstrap";
import { useCreateSectionQuotedMutation } from "slices/resourceInventory/inventorySlice";
import { Formik, Form as FormikForm, ErrorMessage } from "formik";
import * as Yup from "yup";

interface QuotedModalProps {
  isOpen: boolean;
  toggle: () => void;
  productId: number | string;
  refetchInventory: () => void;
  initialQuantity?: number;
}

const QuotedModal: React.FC<QuotedModalProps> = ({
  isOpen,
  toggle,
  productId,
  refetchInventory,
  initialQuantity = 1,
}) => {
  const [createSectionQuoted, { isLoading: isCreating }] =
    useCreateSectionQuotedMutation();

  const validationSchema = Yup.object({
    quantity: Yup.number()
      .required("Quantity is required")
      .min(1, "Quantity must be at least 1")
      .typeError("Quantity must be a number"),
  });

  useEffect(() => {}, [initialQuantity]);

  const handleSubmit = async (
    values: { quantity: number },
    { resetForm }: { resetForm: () => void }
  ) => {
    try {
      await createSectionQuoted({
        ...values,
        quantity: values.quantity.toString(),
        product: productId,
      });
      toggle();
      refetchInventory();
      resetForm();
    } catch (error) {
      console.error("Error adding quoted section:", error);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Add Quoted Section</ModalHeader>
      <ModalBody>
        <Formik
          initialValues={{ quantity: initialQuantity }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit, values, setFieldValue }) => (
            <FormikForm onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="quantity">Quantity</Label>
                <div className="input-group">
                  <Button
                    type="button"
                    onClick={() =>
                      setFieldValue(
                        "quantity",
                        Math.max(values.quantity - 1, 1)
                      )
                    }
                  >
                    -
                  </Button>
                  <Input
                    type="number"
                    id="quantity"
                    name="quantity"
                    value={values.quantity}
                    onChange={(e) =>
                      setFieldValue("quantity", Number(e.target.value))
                    }
                    min={1}
                  />
                  <Button
                    type="button"
                    onClick={() =>
                      setFieldValue("quantity", values.quantity + 1)
                    }
                  >
                    +
                  </Button>
                </div>
                <ErrorMessage
                  name="quantity"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              <ModalFooter>
                <Button
                  color="primary"
                  type="submit"
                  disabled={
                    isCreating ||
                    values.quantity === 0 ||
                    values.quantity === initialQuantity
                  }
                >
                  {isCreating ? (
                    <>
                      <Spinner size="sm" />
                      &nbsp;Saving...
                    </>
                  ) : (
                    "Save Changes"
                  )}
                </Button>
                <Button
                  color="secondary"
                  onClick={toggle}
                  disabled={isCreating}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </FormikForm>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default QuotedModal;
