import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import BreadCrumb from "Components/Common/BreadCrumb";
import Step1HomeWorkPermit from "./WizardSteps/Step1HomeWorkPermit";
import Step2Questionnaire from "./WizardSteps/Step2Questionnaire";
import Step3ToolboxTalk from "./WizardSteps/Step3ToolboxTalk";
import Step4FileUpload from "./WizardSteps/Step4FileUpload";
import StepToolBoxMeeting from "./WizardSteps/StepToolBoxMeeting";
import Step5PreviewSubmit from "./WizardSteps/Step5PreviewSubmit";
import { safetyAppSlice } from "slices/safetyApp/safetyappSlice";
import ValidationAlertStatic from "../../ValidationAlertStatic";
import { getErrorText } from "../../../../helpers/error_message";
import { useGetWorkflowsQuery, useCheckSafetyAppAssignedWorkflowMutation } from "slices/workflow/WorkFlowSlice";
import { useGetprojectByIdQuery } from "slices/projects/projectSlice";


const HomeworkPermitWizard: React.FC = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState<number>(1);
  const [geoPermission, setGeoPermission] = React.useState<boolean>(false);
  const [modalBackDrop, setModalBackDrop] = useState<boolean>(false);
  const [validationMessages, setValidationMessages] = useState<string[]>([]);
  const [location, setLocation] = useState<{ latitude: number | null; longitude: number | null }>({ latitude: null, longitude: null });
  const { selectedProject, hwPermitNo: hwPermitNoFromURL } = useParams<{ selectedProject: string; hwPermitNo?: string }>();
  const { data: projectsData } = useGetprojectByIdQuery(selectedProject);
  const [checkSafteyAppAssignedWorkflow] = useCheckSafetyAppAssignedWorkflowMutation();

  const toggleTab = (tab: number) => {
    if (activeTab !== tab) {
      if (hwPermitNoFromURL) {
        dispatch(safetyAppSlice.util.invalidateTags([{ type: "GetHomeworkPermitByNumber", id: hwPermitNoFromURL }]));
      }
      setActiveTab(tab);
    }
  };

  const getLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          setGeoPermission(true);
        },
        (err) => {
          setValidationMessages((prev) => {
            const errorMessage = getErrorText('location-disabled');
            return prev.includes(errorMessage)
              ? prev
              : [...prev, errorMessage]
          });
          setModalBackDrop(true);
          setGeoPermission(false);
        },
        { enableHighAccuracy: true }
      );
    } else {
      setValidationMessages((prev) => {
        const errorMessage = getErrorText('location-not-supported');
        return prev.includes(errorMessage)
          ? prev
          : [...prev, errorMessage]
      });
      setModalBackDrop(true);
      setGeoPermission(false);
    }
  };

  const checkWorkflowProject = async () => {
    let assignedWorkflow: any = await checkSafteyAppAssignedWorkflow({reportKey: 'HOME_WORK_PERMIT', projectId: selectedProject});
    if (assignedWorkflow.data.length === 0 ) {
      setValidationMessages((prev: any) => {
        const errorMessage = (<span>{getErrorText('workflow-not-assigned')} <b>{projectsData?.project_name}</b></span>);
        return prev.includes(errorMessage) ? prev : [...prev, errorMessage];
      });
      setModalBackDrop(true);
    }
  }

  useEffect(() => {
    setValidationMessages([]);
    getLocation();
    checkWorkflowProject();
  
  }, [selectedProject, projectsData]);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Home Work Permit" breadcrumbItem="Wizard" />
        <Row>
          <Col xl={12}>
            <Card>
              <CardBody className="form-steps">
                <div className="step-arrow-nav mb-4">
                  <Nav className="nav-pills custom-nav nav-justified" role="tablist">
                    <NavItem>
                      <NavLink className={classnames({ active: activeTab === 1 })}>
                        Permit Details
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink className={classnames({ active: activeTab === 2 })}>
                        ToolBox Meeting
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink className={classnames({ active: activeTab === 3 })}>
                        Check List
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink className={classnames({ active: activeTab === 4 })}>
                        Work Area Details
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink className={classnames({ active: activeTab === 5 })}>
                        File Upload
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink className={classnames({ active: activeTab === 6 })}>
                        Preview & Submit
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId={1}>
                    <Step1HomeWorkPermit geoPermission={geoPermission} selectedProject={selectedProject!} projectsData={projectsData} nextStep={() => toggleTab(2)} />
                  </TabPane>
                  <TabPane tabId={2}>
                    <StepToolBoxMeeting previousStep={() => toggleTab(1)} nextStep={() => toggleTab(3)} />
                  </TabPane>
                  <TabPane tabId={3}>
                    <Step2Questionnaire geoPermission={geoPermission} previousStep={() => toggleTab(2)} nextStep={() => toggleTab(4)} />
                  </TabPane>
                  <TabPane tabId={4}>
                    <Step3ToolboxTalk geoPermission={geoPermission} previousStep={() => toggleTab(3)} nextStep={() => toggleTab(5)} />
                  </TabPane>
                  <TabPane tabId={5}>
                    <Step4FileUpload geoPermission={geoPermission} previousStep={() => toggleTab(4)} nextStep={() => toggleTab(6)} />
                  </TabPane>
                  <TabPane tabId={6}>
                    <Step5PreviewSubmit
                      projectsData={projectsData!}
                      location={location}
                      previousStep={() => toggleTab(5)}
                    />
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <ValidationAlertStatic
        modalBackDrop={modalBackDrop}
        setModalBackDrop={setModalBackDrop}
        validationMessages={validationMessages}
        previousPageNavLink="/homeworkpermit-list"
      />
    </div>
  );
};

export default HomeworkPermitWizard;
