import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Col,
  Row,
  Input,
  Form,
  Label,
  FormGroup,
  Spinner,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Card,
  CardHeader,
  CardFooter,
} from "reactstrap";
import Select from "react-select";
import LoadingOverlay from "react-loading-overlay-ts";
import {
  useGetEmployeeDropDownQuery,
  useGetProjectsQuery,
} from "slices/employee/employeeSlice";
import {
  useCreateConfinedSpacePermitMutation,
  useUpdateConfinedSpacePermitMutation,
} from "slices/safetyApp/safetyappSlice";
import BreadCrumb from "Components/Common/BreadCrumb";

const ToolBoxMeetingAddEditReport: React.FC = () => {
  const { data: employeeData } = useGetEmployeeDropDownQuery();
  const { data: projectsDataList } = useGetProjectsQuery();
  const [createPermit, { isLoading: isCreating }] = useCreateConfinedSpacePermitMutation();
  const [updatePermit, { isLoading: isUpdating }] = useUpdateConfinedSpacePermitMutation();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState<any[]>([]);
  const [employeeSelectOptions, setEmployeeSelectOptions] = useState<any[]>([]);

  console.log('selectedEmployees',employeeSelectOptions)

  useEffect(() => {
    if (employeeData) {
      setEmployeeSelectOptions(
        employeeData.map((employee: any) => ({
          value: employee.employee_number,
          label: employee.employee_fullname,
          position_name: employee.position_name || "N/A",
        }))
      );
    }
  }, [employeeData]);

  const formik = useFormik({
    initialValues: {
      work_performed_by: "",
      project: "",
      main_sub_contractor: "",
      conducted_by: "",
      start_date_of_work: "",
      start_time_of_work: "",
      end_date_of_work: "",
      end_time_of_work: "",
      work_description: "",
    },
    validationSchema: Yup.object({
      project: Yup.string().required("Project is required"),
      main_sub_contractor: Yup.string().required("Main/Sub Contractor is required"),
      conducted_by: Yup.string().required("Conducted by is required"),
      start_date_of_work: Yup.date().required("Start date is required"),
      start_time_of_work: Yup.string().required("Start time is required"),
      end_date_of_work: Yup.date().required("End date is required"),
      end_time_of_work: Yup.string().required("End time is required"),
      work_description: Yup.string().optional(),
    }),
    onSubmit: async (values) => {
      const payload = {
        ...values,
        employees: selectedEmployees.map((emp) => ({
          employee_number: emp.employee_number,
          employee_name: emp.employee_fullname,
          position: emp.position_name,
        })),
      };
      try {
        await createPermit(payload).unwrap();
        alert("Permit created successfully!");
      } catch (error) {
        console.error("Error creating permit:", error);
        alert("Failed to create permit.");
      }
    },
  });
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleAddEmployee = (selected: any) => {
    if (!employeeData) return;
    const employeeDetails = employeeData.find(
      (emp: any) => emp.employee_number === selected.value
    );
    if (employeeDetails && !selectedEmployees.some(emp => emp.employee_number === employeeDetails.employee_number)) {
      setSelectedEmployees((prev) => [...prev, employeeDetails]);
      setEmployeeSelectOptions((prev) => prev.filter((opt) => opt.value !== selected.value));
    }
  };

  const removeEmployee = (employeeNumber: string) => {
    setSelectedEmployees((prev) =>
      prev.filter((emp) => emp.employee_number !== employeeNumber)
    );
    const removedEmployee = employeeData?.find(
      (emp) => emp.employee_number === employeeNumber
    );
    if (removedEmployee) {
      setEmployeeSelectOptions((prev) => [
        ...prev,
        {
          value: removedEmployee.employee_number,
          label: removedEmployee.employee_fullname,
          position_name: removedEmployee.position_name || "N/A",
        },
      ]);
    }
  };

  return (
    <React.Fragment>
        <div className="page-content">
        <Container fluid>
        <BreadCrumb title="Toolbox Meeting" pageTitle="SafetyApp" />
        <Card id="customerList">
        <CardHeader >
        <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">ToolBox Meeting report</h5>
                      </div>
                    </div>
        </CardHeader>
        <div className="card-body pt-1">
    <Form onSubmit={formik.handleSubmit}>
      <LoadingOverlay active={isCreating || isUpdating} spinner text="Processing...">
        <Row>
        <Col md={6}>
            <FormGroup>
              <Label for="main_sub_contractor">Main/Sub Contractor<span className="text-danger">*</span></Label>
              <Input
                id="main_sub_contractor"
                name="main_sub_contractor"
                value={formik.values.main_sub_contractor}
                onChange={formik.handleChange}
              />
               {formik.touched.main_sub_contractor && formik.errors.main_sub_contractor && (
    <div className="text-danger">{formik.errors.main_sub_contractor}</div>
  )}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="project">Project<span className="text-danger">*</span></Label>
              <Select
                options={projectsDataList?.map((project: any) => ({
                  value: project.id,
                  label: project.project_name,
                }))}
                onChange={(selected: any) => formik.setFieldValue("project", selected.value)}
              />
               {formik.touched.project && formik.errors.project && (
    <div className="text-danger">{formik.errors.project}</div>
  )}
            </FormGroup>
          </Col>

          
          <Col md={6}>
            <FormGroup>
              <Label for="start_date_of_work">Start Date<span className="text-danger">*</span></Label>
              <Input
                type="date"
                id="start_date_of_work"
                name="start_date_of_work"
                value={formik.values.start_date_of_work}
                onChange={formik.handleChange}
              />
               {formik.touched.start_date_of_work && formik.errors.start_date_of_work && (
    <div className="text-danger">{formik.errors.start_date_of_work}</div>
  )}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="start_time_of_work">Start Time<span className="text-danger">*</span></Label>
              <Input
                type="time"
                id="start_time_of_work"
                name="start_time_of_work"
                value={formik.values.start_time_of_work}
                onChange={formik.handleChange}
              />
               {formik.touched.start_time_of_work && formik.errors.start_time_of_work && (
    <div className="text-danger">{formik.errors.start_time_of_work}</div>
  )}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="end_date_of_work">End Date<span className="text-danger">*</span></Label>
              <Input
                type="date"
                id="end_date_of_work"
                name="end_date_of_work"
                value={formik.values.end_date_of_work}
                onChange={formik.handleChange}
              />
               {formik.touched.end_date_of_work && formik.errors.end_date_of_work && (
    <div className="text-danger">{formik.errors.end_date_of_work}</div>
  )}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="end_time_of_work">End Time<span className="text-danger">*</span></Label>
              <Input
                type="time"
                id="end_time_of_work"
                name="end_time_of_work"
                value={formik.values.end_time_of_work}
                onChange={formik.handleChange}
              />
              {formik.touched.end_time_of_work && formik.errors.end_time_of_work && (
    <div className="text-danger">{formik.errors.end_time_of_work}</div>
  )}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="conducted_by">Conducted By</Label>
              <Input
                id="conducted_by"
                name="conducted_by"
                value={formik.values.conducted_by}
                onChange={formik.handleChange}
              />
            </FormGroup>
          </Col>

          <Col md={12}>
            <FormGroup>
              <Label for="work_description">Work Description</Label>
              <Input
                id="work_description"
                name="work_description"
                value={formik.values.work_description}
                onChange={formik.handleChange}
              />
               {formik.touched.work_description && formik.errors.work_description && (
    <div className="text-danger">{formik.errors.work_description}</div>
  )}
            </FormGroup>
          </Col>
          <hr className="mt-3"/>

          <Col md={12} className="text-center">
            <Button color="primary" onClick={toggleModal}>
            <i className="ri-add-line align-bottom me-1"></i> Employee
            </Button>
          </Col>

          <Col md={12} className="mt-4">
            <Table className="table-borderless align-middle mb-0">
              <thead className="table-light">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Employee Name</th>
                  <th scope="col">Position</th>
                  <th scope="col">Employee Number</th>
                  <th scope="col">Permit Number</th>
                  <th scope="col">Signature</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {selectedEmployees?.length > 0 ? (
                selectedEmployees.map((employee, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                     <td>{employee.employee_fullname}</td>
                    <td>{employee.position_name || "N/A"}</td>
                    <td>{employee.employee_number}</td>
                    <td></td>
                    <td></td>
                    <td>
                      <Button size="sm" color="danger" onClick={() => removeEmployee(employee.employee_number)}>
                      <i className="ri-delete-bin-5-line align-middle"></i>
                      </Button>
                    </td>
                  </tr>
                ))
            ):(
                <tr>
                <td colSpan={6} className="text-center">
                  No Employees added yet.
                </td>
              </tr>
            )}
              </tbody>
            </Table>
          </Col>

          <Modal isOpen={modalOpen} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>Select Employee</ModalHeader>
            <ModalBody>
              <Select
                options={employeeSelectOptions}
                onChange={handleAddEmployee}
              />
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleModal}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </Row>
      </LoadingOverlay>
      <CardFooter>
     <Button
    color="success"
    onClick={(e) => {
      e.preventDefault(); 
      formik.handleSubmit();
    }}
  >
    Submit
  </Button>
  </CardFooter>
    </Form>
    </div>
   

    </Card>
    </Container>
    </div>
    </React.Fragment>
  );
};

export default ToolBoxMeetingAddEditReport;
