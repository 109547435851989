import React, { useEffect, useState } from "react";
import { Col, Row, Button, FormGroup, Label, Input, Form, Spinner, Table } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";  // Import useParams
import {
  useGetHomeworkPermitQuestionnaireKeysQuery,
  useUpdateHomeworkPermitQuestionnaireKeyByHwPermitNoMutation,
  useGetHomeworkPermitByNumberQuery,
} from "slices/safetyApp/safetyappSlice";

// Define types for the form values
interface Question {
  questionnaire_key: number;
  question: string;
  answer: string; // Yes or No
  sort_id: number;
}

interface FormValues {
  questions: Question[];
}

interface Step2QuestionnaireProps {
  geoPermission: boolean;
  previousStep: () => void;
  nextStep: () => void;
}

const Step2Questionnaire: React.FC<Step2QuestionnaireProps> = ({
  geoPermission,
  previousStep,
  nextStep,
}) => {
  // Get hwPermitNo from the URL parameters
  const { hwPermitNo } = useParams<{ hwPermitNo: string }>(); // Get hwPermitNo from URL
  
  // Fetch questionnaire data
  const { data: questionnaireData, isLoading: questionLoading } =
    useGetHomeworkPermitQuestionnaireKeysQuery(undefined);
  const [updateHomeworkPermitQuestionnaireKey, { isLoading: patchisLoading }] =
    useUpdateHomeworkPermitQuestionnaireKeyByHwPermitNoMutation();

  const {
    data: hwPermitData,
    isLoading: isPermitLoading,
    refetch: refetchHomeWorkPermit,
  } = useGetHomeworkPermitByNumberQuery(hwPermitNo);

  const [initialQuestions, setInitialQuestions] = useState<Question[]>([]);

  // Populate initialQuestions state once questionnaireData is available
  useEffect(() => {
    if (questionnaireData) {
      const sortedQuestions = questionnaireData
        .map((q: any) => ({
          questionnaire_key: q.id,
          question: q.question,
          answer: "",
          sort_id: q.sort_id, // Include sort_id in the mapped questions
        }))
        .sort((a: { sort_id: number }, b: { sort_id: number }) => a.sort_id - b.sort_id);

      setInitialQuestions(sortedQuestions);
    }
  }, [questionnaireData]);

  useEffect(() => {
    if (hwPermitNo) {
      refetchHomeWorkPermit(); 
    }
  }, [hwPermitNo, refetchHomeWorkPermit]);

  // Set formik values based on hwPermitData
  useEffect(() => {
    if (hwPermitData?.questionnaires?.length) {
      // Map the hwPermitData questionnaires to formik values
      const updatedQuestions = hwPermitData.questionnaires.map((q: any) => ({
        questionnaire_key: q.questionnaire_key,
        question: q.question,
        answer: q.answer || "", // If there's an answer already, use it; otherwise, default to ""
        sort_id: q.sort_id,
      }));
      setInitialQuestions(updatedQuestions);
    }
  }, [hwPermitData]);

  // Initialize formik with the correct type for form values
  const formik = useFormik<FormValues>({
    initialValues: {
      questions: initialQuestions,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      questions: Yup.array().of(
        Yup.object({
          answer: Yup.string().required("Answer is required"),
        })
      ),
    }),
    validateOnChange: false, // Disable validation on change
    validateOnBlur: false, // Disable validation on blur
    onSubmit: async (values) => {
      try {
        const payload = {
          questionnaire_updates: values.questions,
        };
  
        await updateHomeworkPermitQuestionnaireKey({
          hw_permit_no: hwPermitNo,  
          updatedQuestionnaireKeyData: payload,
        });
  
        nextStep();
      } catch (error) {
        console.error("Error updating questionnaire", error);
      }
    },
  });
  

  // Update the previousStep function to refetch data
  const handlePreviousStep = () => {
    refetchHomeWorkPermit();
    previousStep(); // Call the original previousStep function
  };

  // Handle loading state
  if (questionLoading || isPermitLoading) {
    return <Spinner color="primary" />;
  }

  // If no data is returned, display an error message
  if (!questionnaireData || questionnaireData.length === 0) {
    return <div>No questions available</div>;
  }

  return (
    <Form onSubmit={formik.handleSubmit}>
  <Table className="table table-striped">
    <thead>
      <tr>
        <th>Question</th>
        <th>Answer</th>
      </tr>
    </thead>
    <tbody>
      {formik.values.questions.map((q, index) => (
        <tr key={q.questionnaire_key}>
          <td style={{width: "75%"}}>{q.question}</td>
          <td>
            <FormGroup check inline>
              <Label check className="mr-2">
                <Input
                  type="radio"
                  name={`questions[${index}].answer`}
                  value="Yes"
                  checked={formik.values.questions[index].answer === "Yes"}
                  onChange={formik.handleChange}
                />
                {" "} Yes
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Label check>
                <Input
                  type="radio"
                  name={`questions[${index}].answer`}
                  value="No"
                  checked={formik.values.questions[index].answer === "No"}
                  onChange={formik.handleChange}
                />
                {" "} No
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Label check>
                <Input
                  type="radio"
                  name={`questions[${index}].answer`}
                  value="NA"
                  checked={formik.values.questions[index].answer === "NA"}
                  onChange={formik.handleChange}
                />
               {" "} N/A
              </Label>
            </FormGroup>
            {formik.touched.questions &&
              formik.errors.questions &&
              formik.errors.questions[index] &&
              typeof formik.errors.questions[index] === "object" &&
              formik.errors.questions[index].answer && (
                <div className="text-danger">
                  {formik.errors.questions[index].answer}
                </div>
              )}
          </td>
        </tr>
      ))}
    </tbody>
  </Table>

  <div className="d-flex justify-content-between">
    <Button color="secondary" onClick={handlePreviousStep}>
      Previous
    </Button>
    <Button
      color="primary"
      disabled={patchisLoading || questionLoading}
      type="submit"
    >
      {patchisLoading ? "Loading..." : "Submit & Next"}
    </Button>
  </div>
</Form>

  );
};

export default Step2Questionnaire;
