import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from "reactstrap";
import Dropzone from "react-dropzone";

interface FileUploadModalProps {
  isOpen: boolean;
  toggle: () => void;
  onFileUpload: () => void;
  onFileDrop: (files: File[]) => void;
  selectedFiles: File[];
  isUploading: boolean;
}

const FileUploadModal: React.FC<FileUploadModalProps> = ({
  isOpen,
  toggle,
  onFileUpload,
  onFileDrop,
  selectedFiles,
  isUploading,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Upload Files</ModalHeader>
      <ModalBody>
        <Dropzone onDrop={onFileDrop} multiple>
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps()}
              style={{
                border: "2px dashed #ccc",
                padding: "20px",
                textAlign: "center",
                cursor: "pointer",
              }}
            >
              <input {...getInputProps()} />
              <p>Drag and drop files here, or click to select files</p>
            </div>
          )}
        </Dropzone>
        {selectedFiles.length > 0 && (
          <div className="mt-4">
            <h5>Selected Files:</h5>
            <ul>
              {selectedFiles.map((file, index) => (
                <li key={index}>{file.name}</li>
              ))}
            </ul>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={onFileUpload}
          disabled={selectedFiles.length === 0 || isUploading}
        >
          {isUploading ? <Spinner size="sm" /> : "Upload"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default FileUploadModal;
