import React from "react";
import { Card, CardBody, Col, Row, Spinner } from "reactstrap";
import { Link } from "react-router-dom";

// Define props for ActivitiesTab component
interface ActivitiesTabProps {
  activities: any;
  isLoading: boolean;
}

const ActivitiesTab: React.FC<ActivitiesTabProps> = ({
  activities,
  isLoading,
}) => {
  // Function to get badge color based on the action
  const getBadgeClass = (action: string) => {
    switch (action) {
      case "create":
        return "bg-success-subtle text-success";
      case "delete":
        return "bg-danger-subtle text-danger";
      default:
        return "bg-primary-subtle text-primary";
    }
  };

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <h5 className="card-title">Activities</h5>

          {/* Show loading overlay when data is being fetched */}
          {isLoading ? (
            <div className="text-center">
              <Spinner />
              <p className="text-muted mt-2">Loading activities...</p>
            </div>
          ) : activities?.length === 0 ? (
            <div className="text-center">
              <p className="text-muted">No activities found yet.</p>
            </div>
          ) : (
            <div className="acitivity-timeline py-3">
              {activities?.map((activity: any, index: any) => (
                <div className="acitivity-item py-3 d-flex" key={index}>
                  <div className="flex-shrink-0">
                    {activity?.request_user_image_url ? (
                      <img
                        src={activity?.request_user_image_url}
                        alt=""
                        className="avatar-xs rounded-circle acitivity-avatar"
                      />
                    ) : (
                      <div className="flex-shrink-0 avatar-xs acitivity-avatar">
                        <div className="avatar-title bg-light text-primary rounded-circle">
                          {activity?.request_user?.charAt(0)}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h6 className="mb-1">
                      {activity?.request_user || ""}
                      {activity?.action && (
                        <span
                          className={`badge ${getBadgeClass(
                            activity?.action
                          )} align-middle ms-2`}
                        >
                          {" "}
                          {activity?.action}
                        </span>
                      )}
                    </h6>
                    <p className="text-muted mb-2">{activity?.description}</p>
                    {(activity?.assigned_user_image_url ||
                      activity?.assigned_user) && (
                      <div className="avatar-group mb-2">
                        <Link
                          to="#"
                          className="avatar-group-item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title=""
                          data-bs-original-title="Christi"
                        >
                          {activity?.assigned_user_image_url ? (
                            <img
                              src={activity?.assigned_user_image_url}
                              alt=""
                              className="avatar-xs rounded-circle activity-avatar"
                              title={`Assigned:${activity?.assigned_user}`}
                            />
                          ) : (
                            <div className="avatar-xs activity-avatar">
                              <div
                                className="avatar-title bg-light text-primary rounded-circle"
                                title={`${activity?.assigned_user}`}
                              >
                                {activity?.assigned_user?.charAt(0)}
                              </div>
                            </div>
                          )}
                        </Link>
                        <h6 className="mt-2">
                          {activity?.assigned_user || ""}
                        </h6>
                      </div>
                    )}

                    <small className="mb-0 text-muted">
                      {activity.created_at_text || "Unknown time"}
                    </small>
                  </div>
                </div>
              ))}
            </div>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default ActivitiesTab;
