import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Button,
} from "reactstrap";
import { PersonalInfo } from "../EmployeeProfile";
import { IsValidPermissions } from "../../../Routes/AuthProtected";

interface PersonalInfoProps {
  personalInfoData: PersonalInfo | null;
  setCardView: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
}

export const PersonalInfoView: React.FC<PersonalInfoProps> = ({
  personalInfoData,
  setCardView,
}) => {
  return (
    <React.Fragment>
      <Card>
        <CardHeader className="d-flex align-items-center">
          <i className="ri-user-location-fill fs-4"></i>
          <h4 className="card-title flex-grow-1 mb-0">Personal Information</h4>
          <div className="flex-shrink-0">
            {IsValidPermissions(['EDIT_MEMBERS']) &&
              <Button
                onClick={() => {
                  setCardView({ personalInfoEdit: true });
                }}
                className="btn btn-soft-info btn-md shadow-none"
              >
                Edit
              </Button>
            }
          </div>
        </CardHeader>
        <CardBody>
          <div className="table-card">
            <table className="table mb-0">
              <tbody>
                <tr>
                  <td className="fw-medium" style={{ width: "300px" }}>
                    Personal Email
                  </td>
                  <td>{personalInfoData?.personal_email}</td>
                </tr>
                <tr>
                  <td className="fw-medium">Gender</td>
                  <td>{personalInfoData?.gender_name}</td>
                </tr>
                <tr>
                  <td className="fw-medium">Date of Birth</td>
                  <td>{personalInfoData?.date_of_birth}</td>
                </tr>
                <tr>
                  <td className="fw-medium">Nationality</td>
                  <td>{personalInfoData?.nationality}</td>
                </tr>
                <tr>
                  <td className="fw-medium">Marital Status</td>
                  <td>{personalInfoData?.marital_status_name}</td>
                </tr>
                <tr>
                  <td className="fw-medium">Address 1</td>
                  <td>{personalInfoData?.address1}</td>
                </tr>
                <tr>
                  <td className="fw-medium">Address 2</td>
                  <td>{personalInfoData?.address2}</td>
                </tr>
                <tr>
                  <td className="fw-medium">City</td>
                  <td>{personalInfoData?.city}</td>
                </tr>
                <tr>
                  <td className="fw-medium">State</td>
                  <td>{personalInfoData?.state}</td>
                </tr>
                <tr>
                  <td className="fw-medium">Country</td>
                  <td>{personalInfoData?.country_name}</td>
                </tr>
                <tr>
                  <td className="fw-medium">Zip Code</td>
                  <td>{personalInfoData?.zip_code}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
