import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { customBaseQuery } from "helpers/jwt-token-access/customBaseQuery";
import { WorkflowType, ApprovalType } from "./type";


const convertToFormData = (
  obj: Record<string, any> | undefined | null,
  parentKey: string = ""
): FormData => {
  const formData = new FormData();

  const appendData = (key: string, value: any) => {
    if (value instanceof File) {
      formData.append(key, value);
    } else if (Array.isArray(value)) {
      value.forEach((item, index) => {
        if (item instanceof File) {
          formData.append(`${key}[${index}]`, item);
        } else {
          formData.append(`${key}[${index}]`, item);
        }
      });
    } else if (value && typeof value === "object") {
      if (value instanceof Date) {
        formData.append(key, value.toISOString());
      } else {
        Object.keys(value).forEach((subKey) => {
          appendData(`${key}[${subKey}]`, value[subKey]);
        });
      }
    } else {
      formData.append(key, value);
    }
  };

  if (obj) {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      appendData(parentKey ? `${parentKey}[${key}]` : key, value);
    });
  }

  return formData;
};

export const workflowSlice = createApi({
  reducerPath: "workflowApi",
  baseQuery: customBaseQuery,
  tagTypes: [
    "GetWorkflowsByCategoryName",
    "GetProjectsByWorkFlow",
    "SafetyAppApproval",
  ],
  endpoints: (builder) => ({
    getWorkflows: builder.query({
      query: ($categoryName) =>
        `/workflow/api/v1/workflow/?category_name=${$categoryName}`,
      providesTags: ["GetWorkflowsByCategoryName"],
    }),
    getWorkflowById: builder.query({
      query: (workflowId) => `/workflow/api/v1/workflow/${workflowId}/`,
    }),
    createWorkflow: builder.mutation({
      query: (workflowData) => ({
        url: `/workflow/api/v1/create_update_workflow/`,
        method: "POST",
        body: workflowData,
      }),
      invalidatesTags: [
        { type: "GetProjectsByWorkFlow" },
        { type: "GetWorkflowsByCategoryName" },
      ],
    }),
    updateWorkflow: builder.mutation({
      query: ({ id, ...workflowData }) => ({
        url: `/workflow/api/v1/create_update_workflow/${id}/`,
        method: "PATCH",
        body: workflowData,
      }),
      invalidatesTags: [
        { type: "GetProjectsByWorkFlow" },
        { type: "GetWorkflowsByCategoryName" },
      ],
    }),

    getRoles: builder.query({
      query: () => "/organization/api/v1/roles/",
    }),
    getWorkFlowApprovals: builder.query({
      query: () => "/workflow/api/v1/workflow_approvals/",
    }),
    getWorkFlowApprovalsByProject: builder.query({
      query: (projectId) =>
        `/workflow/api/v1/workflow_approvals/?project=${projectId}`,
    }),
    createApproveWorkflow: builder.mutation({
      query: (approvalData) => ({
        url: `/workflow/api/v1/approve_workflow/`,
        method: "POST",
        body: approvalData,
      }),
    }),
    createSafetyAppApproveWorkflow: builder.mutation({
      query: (approvalData) => ({
        url: `/workflow/api/v1/safety_app_approve_workflow/`,
        method: "POST",
        body: approvalData,
      }),
      invalidatesTags: [
        { type: "GetProjectsByWorkFlow" },
        { type: "GetWorkflowsByCategoryName" },
      ],
    }),
    createSafetyAppClosedRevokedWorkflow: builder.mutation({
      query: (approvalData) => ({
        url: `/workflow/api/v1/safety_app_approve_close_revoke_workflow/`,
        method: "POST",
        body: approvalData,
      }),
      invalidatesTags: [
        { type: "GetProjectsByWorkFlow" },
        { type: "GetWorkflowsByCategoryName" },
      ],
    }),
    updateWorkflowProject: builder.mutation({
      query: ({ id, ...updateData }) => ({
        url: `/workflow/api/v1/workflow_project_update/${id}/`,
        method: "PATCH",
        body: updateData,
      }),
      invalidatesTags: [
        { type: "GetProjectsByWorkFlow" },
        { type: "GetWorkflowsByCategoryName" },
      ],
    }),
    updateSafetyAppWorkflowProject: builder.mutation({
      query: ({ id, ...updateData }) => ({
        url: `/workflow/api/v1/safety_app_workflow_project_update/${id}/`,
        method: "PATCH",
        body: updateData,
      }),
      invalidatesTags: [
        { type: "GetProjectsByWorkFlow" },
        { type: "GetWorkflowsByCategoryName" },
        { type: "SafetyAppApproval"}
      ],
    }),
    updateSafetyAppAssignedWorkflow: builder.mutation({
      query: ({ id, ...updateData }) => ({
        url: `/workflow/api/v1/safety_app_assigned_workflow/${id}/`,
        method: "PATCH",
        body: updateData,
      }),
    }),
    getProjectsByWorkFlow: builder.query({
      query: (workflow_category) =>
        `/organization/api/v1/projects/?workflow_category=${workflow_category}`,
      providesTags: ["GetProjectsByWorkFlow"],
    }),

    getSafetyAppApproval: builder.query({
      query: () => `/workflow/api/v1/safety_app_approval_view/`,
      providesTags: ["SafetyAppApproval"],
    }),
    getSafetyAppAssignedWorkflow: builder.query({
      query: (projectId) =>
        `/workflow/api/v1/get_safety_app_assigned_workflow/${projectId}/`,
      providesTags: ['SafetyAppApproval'],
    }),
    getSafetyAppApprovalByPermitId: builder.query({
      query: (permitId) =>
        `/workflow/api/v1/safety_app_approval_view/${permitId}/`,
      providesTags: ['SafetyAppApproval'],
    }),
    checkSafetyAppAssignedWorkflow: builder.mutation({
      query: ({reportKey, projectId}) => ({
        url: `/workflow/api/v1/get_safety_app_workflow_report_verify/?report_key=${reportKey}&project_id=${projectId}`,
        method: "GET",
        responseHandler: async (response: Response) => {
          const data = await response.json();
          return data;
        },
      }),
    }),
    createSafetyAppApproval: builder.mutation({
      query: (approvalData) => ({
        url: `/workflow/api/v1/safety_app_approval_view/`,
        method: "POST",
        body: approvalData,
      }),
      invalidatesTags: ["SafetyAppApproval"],
    }),
    postApprovalComments: builder.mutation({
      query: (comments) => ({
        url: `/workflow/api/v1/safety_app_approval_comments/`,
        method: "POST",
        body: comments,
      }),
      invalidatesTags: ["SafetyAppApproval"],
    }),
    postApprovalCommentsAttachment: builder.mutation({
      query: (attachment) => {
        const formData = convertToFormData(attachment);
        return {
          url: `/workflow/api/v1/safety_app_approval_comments_attachment/`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["SafetyAppApproval"],
    }),
    deleteSafetyAppAssignedWorkflow: builder.mutation({
      query: ({selectedReport, projectId}) => ({
        url: `/workflow/api/v1/delete_safety_app_assigned_workflow/${projectId}/${selectedReport}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SafetyAppApproval"],
    }),
  }),
});

export const {
  useGetWorkflowsQuery,
  useGetWorkflowByIdQuery,
  useCreateWorkflowMutation,
  useUpdateWorkflowMutation,
  useGetRolesQuery,
  useGetWorkFlowApprovalsQuery,
  useGetWorkFlowApprovalsByProjectQuery,
  useCreateApproveWorkflowMutation,
  useCreateSafetyAppClosedRevokedWorkflowMutation,
  useCreateSafetyAppApproveWorkflowMutation,
  useUpdateWorkflowProjectMutation,
  useUpdateSafetyAppWorkflowProjectMutation,
  useUpdateSafetyAppAssignedWorkflowMutation,
  useGetProjectsByWorkFlowQuery,
  useGetSafetyAppApprovalQuery,
  useGetSafetyAppApprovalByPermitIdQuery,
  useCreateSafetyAppApprovalMutation,
  usePostApprovalCommentsMutation,
  usePostApprovalCommentsAttachmentMutation,
  useGetSafetyAppAssignedWorkflowQuery,
  useDeleteSafetyAppAssignedWorkflowMutation,
  useCheckSafetyAppAssignedWorkflowMutation
} = workflowSlice;
