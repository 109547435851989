import React from "react";
import {
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import {
  useGetDeliveredAuditQuery,
  useGetImplementedAuditQuery,
  useGetProcuredAuditQuery,
} from "slices/resourceInventory/inventorySlice";
import avatar1 from "../../../assets/images/users/avatar-1.jpg";
import LoadingOverlay from "react-loading-overlay-ts";

// Define props for AuditModal component
interface AuditModalProps {
  id: any;
  type: string;
  isOpen: boolean;
  toggle: () => void;
}

const AuditModal: React.FC<AuditModalProps> = ({
  id,
  type,
  isOpen,
  toggle,
}) => {
  // Hooks are always called unconditionally
  const procuredAudit = useGetProcuredAuditQuery(id, {
    skip: type !== "procured",
  });
  const implementedAudit = useGetImplementedAuditQuery(id, {
    skip: type !== "implemented",
  });
  const deliveredAudit = useGetDeliveredAuditQuery(id, {
    skip: type !== "delivered",
  });

  // Set auditQueryResult conditionally based on `type`
  const auditQueryResult =
    type === "procured"
      ? procuredAudit
      : type === "implemented"
      ? implementedAudit
      : deliveredAudit;

  // Extract data and isLoading safely with default fallbacks
  const { data: activities = [], isLoading = false } = auditQueryResult || {};

  // Function to get badge color based on the action
  const getBadgeClass = (action: string) => {
    switch (action) {
      case "create":
        return "bg-success-subtle text-success";
      case "delete":
        return "bg-danger-subtle text-danger";
      default:
        return "bg-primary-subtle text-primary";
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      {/* Modal wrapper */}
      <ModalHeader toggle={toggle}>Audit Logs</ModalHeader>
      {/* Header with close button */}
      <ModalBody>
        <LoadingOverlay active={isLoading} spinner text="Loading..." >
          {/* Show loading overlay when data is being fetched */}
          {isLoading ? (
            <div className="text-center">
              <Spinner />
              <p className="text-muted mt-2">Loading activities...</p>
            </div>
          ) : activities.length === 0 ? (
            <div className="text-center">
              <p className="text-muted">No activities found yet.</p>
            </div>
          ) : (
            <div className="acitivity-timeline py-3">
              {activities?.map((activity: any, index: any) => (
                <div className="acitivity-item py-3 d-flex" key={activity.id}>
                  <div className="flex-shrink-0">
                    {activity?.request_user_image_url ? (
                      <img
                        src={activity?.request_user_image_url}
                        alt=""
                        className="avatar-xs rounded-circle acitivity-avatar"
                      />
                    ) : (
                      <div className="flex-shrink-0 avatar-xs acitivity-avatar">
                        <div className="avatar-title bg-light text-primary rounded-circle">
                          <i
                            style={{
                              cursor: "pointer",
                              fontSize: "15px",
                            }}
                            title="Audit Log"
                            className="ri-send-plane-fill"
                          ></i>
                          {/* {activity?.request_user?.charAt(0)} */}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="flex-grow-1 ms-1">
                    <h6 className="mb-1">
                      {activity?.activity_log}
                      <span
                        className={`badge ${getBadgeClass(
                          activity?.tag
                        )} align-middle ms-2`}
                      >
                        {activity?.tag}
                      </span>
                    </h6>
                    <p className="text-muted mb-2">{activity?.comments}</p>
                    <small className="mb-0 text-muted">
                      {activity?.created_at_text}
                    </small>
                  </div>
                </div>
              ))}
            </div>
          )}
        </LoadingOverlay>
      </ModalBody>
    </Modal>
  );
};

export default AuditModal;
