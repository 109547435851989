import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { customBaseQuery } from "helpers/jwt-token-access/customBaseQuery";


export const pageAccessSlice = createApi({
  reducerPath: "pageAccess",
  baseQuery: customBaseQuery,
  endpoints: (builder) => ({
    // Page Access Endpoints
    getPageAccesses: builder.query({
      query: () => "/organization/api/v1/page_access/",
    }),
    getPageAccessById: builder.query({
      query: (id) => `/organization/api/v1/page_access/${id}/`,
    }),
    getRoleById: builder.query({
      query: (id) => `/organization/api/v1/roles/${id}/`,
    }),
    createRole: builder.mutation({
      query: (newRole) => ({
        url: "/organization/api/v1/roles/",
        method: "POST",
        body: newRole,
      }),
    }),
    updateRoleById: builder.mutation({
      query: ({ id, updateData }) => ({
        url: `/organization/api/v1/roles/${id}/`,
        method: "PATCH",
        body: updateData,
      }),
    }),
    deleteRoleById: builder.mutation({
      query: (id) => ({
        url: `/organization/api/v1/roles/${id}/`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetPageAccessesQuery,
  useGetPageAccessByIdQuery,
  useGetRoleByIdQuery,
  useCreateRoleMutation,
  useUpdateRoleByIdMutation,
  useDeleteRoleByIdMutation,
} = pageAccessSlice;
