import React from "react";
import { Card, CardBody, CardHeader, Row, Table } from "reactstrap";
import { Tree, TreeNode } from "react-organizational-chart";
import styled from "@emotion/styled";
import { useGetWorkflowByIdQuery } from "slices/workflow/WorkFlowSlice";

interface WorkflowProjectModalProps {
  workflowId: number | null;
}

const WorkflowChartTab: React.FC<WorkflowProjectModalProps> = ({
  workflowId,
}) => {
  // Fetch workflow data using the query
  const { data, error, isLoading } = useGetWorkflowByIdQuery(workflowId);

  // Function to convert a flat list to a nested structure
  function convertToNestedStructure(approvals: any[]) {
    if (approvals.length === 0) return [];

    if (approvals.length === 1) return [{ ...approvals[0], children: [] }];

    const root = { ...approvals[0], children: [] };
    let currentLevel = [root];

    for (let i = 1; i < approvals.length; i++) {
      const item = { ...approvals[i], children: [] };
      currentLevel[currentLevel.length - 1].children.push(item);
      currentLevel.push(item);

      if (currentLevel.length > 1) {
        currentLevel = [currentLevel[currentLevel.length - 1]];
      }
    }

    return [root];
  }

  type StyledNodeProps = {
    isRequired?: boolean;
  };

  const StyledNode = styled.div<StyledNodeProps>`
    padding: 5px;
    border-radius: 8px;
    display: inline-block;
    border: 1px solid #b7b7b7;
    text-align: center;
    width: 200px;
    background-color: "white";
    color: "white";
  `;

  const StyledRootNode = styled.div<StyledNodeProps>`
    padding: 5px;
    border-radius: 0;
    display: inline-block;
    border: 1px solid #b7b7b7;
    text-align: center;
    width: 200px;
    background: #d8d8ff;
    color: "white";
  `;

  type StyledTreeExampleProps = {
    approvals: any[];
    workflowName: string;
  };

  const StyledTreeExample: React.FC<StyledTreeExampleProps> = ({
    approvals,
    workflowName,
  }) => {
    const renderTreeNodes = (steps: any) => {
      return steps.map((step: any, i: number) => (
        <TreeNode
          key={step.id}
          label={
            <StyledNode isRequired={step.is_required}>
              <span>
                <span className="fs-6" style={{ color: "#503bba" }}>
                  <b>Level {step.level} Approval</b>
                </span>
                <hr />
                <span className="fs-6">
                  <b>{step.step_name}</b>
                </span>
                <br />
                {step.is_required ? (
                  <span className="badge border border-danger text-danger fs-6 mt-1">
                    Approval Required
                  </span>
                ) : (
                  ""
                )}
                <hr />
                {step.role_name?.map((n: any) => (
                  <span
                    className="badge border border-primary text-primary fs-6"
                    key={n}
                  >
                    {n}
                  </span>
                ))}
              </span>
            </StyledNode>
          }
        >
          {step.children && renderTreeNodes(step.children)}
        </TreeNode>
      ));
    };

    return (
      <Tree
        lineWidth={"2px"}
        lineColor={"green"}
        lineBorderRadius={"10px"}
        label={
          <StyledRootNode isRequired={true}>
            <b>{workflowName}</b>
          </StyledRootNode>
        }
      >
        {renderTreeNodes(convertToNestedStructure(approvals))}
      </Tree>
    );
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading workflow data</div>;

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="d-flex align-items-center mb-4">
          <h5 className="card-title flex-grow-1">Workflow Chart</h5>
        </CardHeader>
        <CardBody>
          <Row>
            {data && (
              <StyledTreeExample
                approvals={data.approvals}
                workflowName={data.workflow_name}
              />
            )}
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default WorkflowChartTab;
