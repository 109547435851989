// customBaseQuery.js
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken, getUserRole, setAuthUser, isTokenNearExpiry, refreshAuthToken } from "./auth-token-header";

export const customBaseQuery = fetchBaseQuery({
    baseUrl: window.__RUNTIME_CONFIG__.REACT_APP_API_URL,
    prepareHeaders: async (headers) => {
        let token = getToken();
        let userRole = getUserRole();
        const refreshToken = JSON.parse(localStorage.getItem("authUser") || "{}")?.refresh;

        // Check token expiry and refresh if necessary
        if (isTokenNearExpiry(token) && refreshToken) {
            try {
                const newTokenData = await refreshAuthToken(refreshToken);
                setAuthUser(newTokenData);
                token = newTokenData.access;
            } catch (error) {
                console.error("Token refresh failed:", error);
            }
        }

        if (token) {
            headers.set("Authorization", `Bearer ${token}`);
        }

        if (userRole) {
            headers.set("AccessRole", userRole);
        }

        return headers;
    },
});
