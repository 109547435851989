import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Progress,
  Row,
  TabContent,
  TabPane,
  Spinner,
  Table,
  CardHeader,
  Button,
} from "reactstrap";
import classnames from "classnames";
import { useParams } from "react-router-dom";
import {
  useGetEmployeeByUuidQuery,
  useGetEmergencyContactInfoQuery,
  useGetEmployeePersonalInfoQuery,
  usePatchEmployeeProfileImageMutation,
  useGetEmployeeProfileImageQuery,
} from "slices/employee/employeeSlice";

import { useDropzone } from "react-dropzone";

import { WorkProfile } from "./EmployeeProfileView/WorkProfileView";
import { WorkProfileEdit } from "./EmployeeEditView/WorkProfileEdit";
import { PersonalInfoView } from "./EmployeeProfileView/PersonalInfoView";
import { PersonalInfoEdit } from "./EmployeeEditView/PersonalInfoEdit";
import { EmergencyContactInfoView } from "./EmployeeProfileView/EmergencyInfoView";
import { EmergencyContactEdit } from "./EmployeeEditView/EmergencyContactInfoEdit";
import EmployeeDocumentList from "./Documents/EmployeeDocuments";
import EmployeeProjectsList from "./Projects/EmployeeProjects";
import SignatureForm from "./Signature/SignatureForm";
import { ToastContainer } from "react-toastify";
import {
  useGetEmployeeUserNamePassWordMutation,
  useGetAuditLogsQuery,
} from "slices/employee/employeeSlice";
import ActivitiesTab from "Components/Common/ActivitesTab";
import { Tooltip } from 'react-tooltip';

export interface Project {
  project_name: string;
  assigned_date: string;
  current_active: boolean;
  project_actual_cost: string | null;
  project_estimation_cost: string | null;
  project_allocated_budget: string | null;
  project_url: string | undefined;
  start_date: string;
  end_date: string;
  status: number;
}

export interface EmployeeData {
  employee_fullname: string;
  work_email: string;
  employee_uuid: string;
  role_name: string[];
  work_phone: string;
  employee_start_date: string;
  position_name: string;
  skills: string;
  first_name: string;
  last_name: string;
  onboarding_mentor_name: string;
  direct_manager_name: string;
  project_name: string;
  employee_number: string;
  nric: string;
  employee_profile_image_url: {
    profile_image: string;
    profile_image_url: string;
  };
  overall_status: {
    is_employee_work_info_completed: boolean;
    is_employee_personal_info_completed: boolean;
    is_employee_emergency_contact_completed: boolean;
    is_employee_profile_image_completed: boolean;
    average_percentage: number;
    is_profile_completed: boolean;
  };
  project_list: Project[];
}

export interface PersonalInfo {
  personal_email: string;
  address2: string;
  gender: string | null;
  home_phone: string;
  state: string;
  address1: string;
  city: string;
  marital_status: string | null;
  nationality: string;
  zip_code: string;
  mobile_phone: string;
  date_of_birth: string | null;
  country: string | null;
  marital_status_name: string;
  gender_name: string;
  country_name: string;
}

export interface EmergencyContactInfo {
  full_name: string;
  email: string;
  relationship: string;
  address2: string;
  mobile_phone: string;
  state: string;
  zip_code: string;
  work_phone: string;
  address1: string;
  home_phone: string;
  city: string;
  country: number | null;
  country_name: string;
}

type CardViewState = Record<string, boolean>;

const EmployeeProfile: React.FC = () => {
  //   SwiperCore.use([Autoplay]);

  const [employeeProfileData, setEmployeeProfileData] =
    useState<EmployeeData | null>(null);

  const [personalInfo, setPersonalInfo] = useState<PersonalInfo | null>(null);

  const [emergencyContactInfo, setEmergencyContactInfo] =
    useState<EmergencyContactInfo | null>(null);

  const [cardView, setCardView] = useState<CardViewState>({
    summary: true,
    profile: false,
    personalInfo: false,
    emergencyContact: false,
  });

  const [hovered, setHovered] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [credentials, setCredentials] = useState<{
    username: string;
    password: string;
  } | null>(null);

  const { employee_uuid } = useParams<{ employee_uuid: string }>();

  const [getEmployeeUserNamePassWord, { isLoading: isloadingEmployeeUserNamePassWord }] = useGetEmployeeUserNamePassWordMutation()

  // Toggle the visibility of the password and fetch data
  const handleShowPassword = async () => {
    if (!showPassword) {
      const response: any = await getEmployeeUserNamePassWord(employee_uuid);
      console.log(response)
      if (response.data) {
        setShowPassword(true);
        setCredentials(
          {
            "username": response.data.username,
            "password": response.data.password ? atob(response.data.password) : "******"
          }
        )
      }
      else {
        setShowPassword(false);
      }
    } else {
      setShowPassword(false);
    }
  };

  // Copy function with tooltip update
  const handleCopy = (text: string, label: "Username" | "Password") => {
    navigator.clipboard.writeText(text);
  };

  const { data, error, isLoading, refetch } = useGetEmployeeByUuidQuery(employee_uuid);

  const { data: auditLogs, isLoading: auditLogsLoading } = useGetAuditLogsQuery(
    { model_name: "employee", object_id: data?.id },
    { skip: !data?.id }
  );

  const {
    data: personalInfoData,
    isLoading: personalInfoLoading,
    refetch: personalInfoFetch,
  } = useGetEmployeePersonalInfoQuery(employee_uuid);

  const {
    data: emergencyContactInfoData,
    isLoading: emergencyContactInfoLoading,
    refetch: emergencyContactInfoFetch,
  } = useGetEmergencyContactInfoQuery(employee_uuid);

  const {
    data: profileImageData,
    isLoading: isFetchingImage,
    refetch: profileImageFetch,
  } = useGetEmployeeProfileImageQuery(employee_uuid);

  const [patchEmployeeProfileImage, { isLoading: patchProfileImageLoading }] =
    usePatchEmployeeProfileImageMutation();

  useEffect(() => {
    if (employee_uuid) {
      refetch();
    }
  }, [employee_uuid, refetch]);

  useEffect(() => {
    if (employee_uuid) {
      personalInfoFetch();
    }
  }, [employee_uuid, personalInfoFetch]);

  useEffect(() => {
    if (employee_uuid) {
      emergencyContactInfoFetch();
    }
  }, [employee_uuid, emergencyContactInfoFetch]);

  useEffect(() => {
    if (data) {
      setEmployeeProfileData(data);
    }
  }, [data]);

  useEffect(() => {
    if (personalInfoData) {
      setPersonalInfo(personalInfoData);
      personalInfoFetch();
    }
  }, [personalInfoData, personalInfoFetch]);

  useEffect(() => {
    if (emergencyContactInfoData) {
      setEmergencyContactInfo(emergencyContactInfoData);
      emergencyContactInfoFetch();
    }
  }, [emergencyContactInfoData, emergencyContactInfoFetch]);

  const profileImageUrl = profileImageData?.profile_image_url;
  const initials = `${employeeProfileData?.first_name.charAt(
    0
  )}${employeeProfileData?.last_name.charAt(0)}`;

  const [activeTab, setActiveTab] = useState("1");
  const [activityTab, setActivityTab] = useState("1");

  const toggleTab = (tab: any) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const onDrop = async (acceptedFiles: File[]) => {
    if (acceptedFiles.length) {
      const imageFile = acceptedFiles[0];
      const updateData = { profile_image: imageFile };

      try {
        await patchEmployeeProfileImage({
          employee_uuid,
          updateData,
        });
        // Optionally refetch the profile data to update the UI
        refetch();
        profileImageFetch();
      } catch (error) {
        console.error("Failed to update profile image:", error);
      }
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { "image/*": [] },
    multiple: false,
  });

  document.title = "Profile | Velzon - React Admin & Dashboard Template";

  if (isLoading) {
    return <React.Fragment>
      <Container fluid>
        <div className="page-content" style={{ height: '80vh' }}> {/* Full viewport height */}
          <Container fluid className="h-100">
            <Row className="h-100 d-flex justify-content-center align-items-center">
              <Col lg={12} className="text-center">
                <Spinner color="primary"></Spinner> <br /> <br />
                <span className="text-muted">Fetching Member Information...</span>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </React.Fragment>
  }

  if (error) return <div>Error fetching data</div>;

  const skillsString = employeeProfileData?.skills || "";
  const skillsArray = skillsString ? skillsString.split(",") : [];

  const rolesArray = employeeProfileData?.role_name || [];

  return (
    <React.Fragment>
      <Tooltip style={{ zIndex: 10 }} anchorSelect=".emp-number-class" content="Employee Number" />
      <Tooltip style={{ zIndex: 10 }} anchorSelect=".emp-email-class" content="Email" />
      <Tooltip style={{ zIndex: 10 }} anchorSelect=".emp-phone-class" content="Phone" />
      <Tooltip style={{ zIndex: 10 }} anchorSelect=".copy-username-class" content="Copy Username" />
      <Tooltip style={{ zIndex: 10 }} anchorSelect=".copy-password-class" content="Copy Password" />
      <Tooltip style={{ zIndex: 10 }} anchorSelect=".copy-credentials-class" content="View Credentials" />

      <div className="page-content">
        <Container fluid>
          <div className="profile-foreground position-relative mx-n4 mt-n3">
            <div className="profile-wid-bg"></div>
          </div>
          <div className="pt-4 mb-4 mb-lg-3 pb-lg-4">
            <Row className="g-4">
              <div
                className="col-auto position-relative"
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                {...getRootProps()}
                style={{ cursor: "pointer" }}
              >
                <input {...getInputProps()} style={{ display: "none" }} />
                <div className="avatar-lg">
                  {profileImageUrl ? (
                    <img
                      src={profileImageUrl}
                      alt="user-img"
                      className="img-thumbnail rounded-circle"
                      style={{ width: "100px", height: "100px" }}
                    />
                  ) : (
                    <div
                      className="img-thumbnail rounded-circle bg-primary text-white d-flex align-items-center justify-content-center"
                      style={{ width: "100px", height: "100px" }}
                    >
                      <span className="fs-24">{initials}</span>
                    </div>
                  )}

                  {(patchProfileImageLoading || isFetchingImage) && (
                    <div
                      className="position-absolute top-50 start-50 translate-middle"
                      style={{
                        width: "100px",
                        height: "100px",
                        backgroundColor: "rgba(0, 0, 0, 0.6)",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "-8px"
                      }}
                    >
                      <Spinner size="sm" color="light" />
                    </div>
                  )}

                  {/* Edit icon that shows on hover */}
                  {(!patchProfileImageLoading || !isFetchingImage) && hovered && (
                    <div
                      className="position-absolute top-50 start-50 translate-middle"
                      style={{
                        width: "90px",
                        height: "90px",
                        backgroundColor: "rgba(0, 0, 0, 0.6)",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "-8px"
                      }}
                    >
                      <i className="ri ri-pencil-line text-white fs-5"></i>
                    </div>
                  )}
                </div>
              </div>

              <Col>
                <div className="p-2">
                  <h3 className="text-white mb-2">
                    {employeeProfileData?.employee_fullname}
                  </h3>
                  {employeeProfileData ?
                    <p className="text-white text-opacity-75 fs-5">
                      <span>Position</span> - {employeeProfileData?.position_name}
                    </p>
                    : <></>}
                  <div className="hstack text-white-50 gap-1">
                    {data?.employee_number ?
                      <div className="me-2 fs-5 emp-number-class">
                        <i className="ri-account-box-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>
                        {data?.employee_number}
                      </div>
                      : <></>}

                    {data?.work_email ?
                      <div className="me-2 fs-5 emp-email-class">
                        <i className="ri-mail-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>
                        {data?.work_email}
                      </div>
                      : <></>}
                    {data?.work_phone ?
                      <div className="me-2 fs-5 emp-phone-class">
                        <i className="ri-phone-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>
                        {data?.work_phone}
                      </div>
                      : <></>}
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <Row>
            <Col lg={12}>
              <div>
                <div className="d-flex">
                  <Nav
                    pills
                    className="animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        href="#overview-tab"
                        className={classnames(
                          { active: activeTab === "1" },
                          "fs-14"
                        )}
                        onClick={() => {
                          toggleTab("1");
                        }}
                      >
                        <i className="ri-airplay-fill d-inline-block d-md-none"></i>{" "}
                        <span className="d-none d-md-inline-block">
                          Overview
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#activities"
                        className={classnames(
                          { active: activeTab === "2" },
                          "fs-14"
                        )}
                        onClick={() => {
                          toggleTab("2");
                        }}
                      >
                        <i className="ri-list-unordered d-inline-block d-md-none"></i>{" "}
                        <span className="d-none d-md-inline-block">
                          Activities
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#projects"
                        className={classnames(
                          { active: activeTab === "3" },
                          "fs-14"
                        )}
                        onClick={() => {
                          toggleTab("3");
                        }}
                      >
                        <i className="ri-price-tag-line d-inline-block d-md-none"></i>{" "}
                        <span className="d-none d-md-inline-block">
                          Projects
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#documents"
                        className={classnames(
                          { active: activeTab === "4" },
                          "fs-14"
                        )}
                        onClick={() => {
                          toggleTab("4");
                        }}
                      >
                        <i className="ri-folder-4-line d-inline-block d-md-none"></i>{" "}
                        <span className="d-none d-md-inline-block">
                          Documents
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#documents"
                        className={classnames(
                          { active: activeTab === "5" },
                          "fs-14"
                        )}
                        onClick={() => {
                          toggleTab("5");
                        }}
                      >
                        <i className="ri-folder-4-line d-inline-block d-md-none"></i>{" "}
                        <span className="d-none d-md-inline-block">
                          Digital Signature
                        </span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>

                <TabContent activeTab={activeTab} className="pt-4">
                  <TabPane tabId="1">
                    <Row>
                      <Col xxl={3}>
                        <Card>
                          <CardBody>
                            <h5 className="card-title mb-5">
                              Complete Your Profile
                            </h5>
                            <Progress
                              value={
                                employeeProfileData?.overall_status
                                  ?.average_percentage
                              }
                              color="danger"
                              className="animated-progess custom-progress progress-label"
                            >
                              <div className="label">
                                {
                                  employeeProfileData?.overall_status
                                    ?.average_percentage
                                }
                                %
                              </div>{" "}
                            </Progress>
                          </CardBody>
                        </Card>

                        <Card>
                          <CardBody>
                            <div className="d-flex align-items-center mb-4">
                              <div className="flex-grow-1">
                                <h5 className="card-title mb-0">
                                  Assigned Projects
                                </h5>
                              </div>
                            </div>

                            {/* Check if project_list has data */}
                            {employeeProfileData?.project_list &&
                              employeeProfileData?.project_list.length > 0 ? (
                              employeeProfileData?.project_list.map(
                                (project, index) => (
                                  <div
                                    className="d-flex align-items-center py-3"
                                    key={index}
                                  >
                                    <div className="avatar-xs flex-shrink-0 me-3">

                                      {project.project_url ? (
                                        <img
                                          src={project?.project_url}
                                          alt=""
                                          className="rounded-circle avatar-sm"
                                          style={{
                                            width: "2rem",
                                            height: "2rem",
                                          }}
                                        />
                                      ) : (
                                        <>{project?.project_name.charAt(0)}</>
                                      )}
                                    </div>
                                    <div className="flex-grow-1">
                                      <div>
                                        <h5 className="fs-14 mb-1">
                                          {project?.project_name}
                                        </h5>
                                        <p className="fs-11 text-muted mb-0">
                                          Assigned on: {project?.assigned_date}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="flex-shrink-0 ms-2">
                                      <p className="btn btn-sm btn-outline-success shadow-none">
                                        {project.status === 1
                                          ? "Active"
                                          : "Inactive"}
                                      </p>
                                    </div>
                                  </div>
                                )
                              )
                            ) : (
                              // Fallback content if no projects are found
                              <p className="text-muted">No projects assigned</p>
                            )}
                          </CardBody>
                        </Card>

                        <Card>
                          <CardBody>
                            <h5 className="card-title mb-4">Skills</h5>
                            <div className="d-flex flex-wrap gap-2 fs-15">
                              {skillsArray?.length > 0 ? (
                                <>
                                  {skillsArray.map((skill, index) => (
                                    <div
                                      key={index}
                                      className="badge bg-primary-subtle text-primary"
                                    >
                                      {skill}
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <div className="text-muted">
                                  No skills added yet..
                                </div>
                              )}
                            </div>
                          </CardBody>
                        </Card>

                        <Card>
                          <CardHeader className="d-flex align-items-center">
                            <h4 className="card-title flex-grow-1 mb-0">
                              User Credentials
                            </h4>
                            <div className="flex-shrink-0">
                              <Button
                                onClick={handleShowPassword}
                                className="copy-credentials-class btn btn-soft-info btn-md shadow-none"
                              >
                                {isloadingEmployeeUserNamePassWord ? <Spinner size="sm" /> :
                                  <>
                                    {showPassword ? (
                                      <i className=" ri-eye-off-fill"></i>
                                    ) : (
                                      <i className="ri-eye-fill"></i>
                                    )}
                                  </>
                                }
                              </Button>
                            </div>
                          </CardHeader>
                          <CardBody>
                            <Row>
                              <Col md={10}>
                                <div>
                                  <strong>Username: </strong>
                                  {showPassword
                                    ? credentials?.username : "******"}
                                </div>
                              </Col>
                              <Col md={2}>
                                {showPassword && (
                                  <>
                                    <i
                                      className="copy-username-class ri-file-copy-fill text-primary cursor-pointer"
                                      id="copyUsername"
                                      onClick={() =>
                                        handleCopy(
                                          credentials?.username || "",
                                          "Username"
                                        )
                                      }
                                      style={{ cursor: "pointer" }}
                                    ></i>
                                  </>
                                )}
                              </Col>
                              <Col md={10}>
                                <div>
                                  <strong>Password: </strong>
                                  {showPassword
                                    ? credentials?.password : "******"}
                                </div>
                              </Col>
                              <Col md={2}>
                                {showPassword && (
                                  <div className="d-flex align-items-center">
                                    {/* Copy Password */}
                                    <i
                                      className="copy-password-class ri-file-copy-fill text-primary cursor-pointer"
                                      id="copyPassword"
                                      onClick={() =>
                                        handleCopy(credentials?.password || "", "Password")
                                      }
                                      style={{ cursor: "pointer" }}
                                    ></i>
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                        <Card>
                          <CardBody>
                            <h5 className="card-title mb-4">Role Permission</h5>
                            <div className="d-flex flex-wrap gap-2 fs-15">
                              {rolesArray?.length > 0 ? (
                                <>
                                  {rolesArray?.map((roles, index) => (
                                    <div
                                      key={index}
                                      className="badge bg-primary-subtle text-primary"
                                    >
                                      {roles}
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <div className="text-muted">
                                  No permission's assigned yet..
                                </div>
                              )}
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xxl={9}>
                        {(cardView?.summary || cardView?.profile) && (
                          <WorkProfile
                            setCardView={setCardView}
                            employeeProfileData={employeeProfileData}
                          />
                        )}
                        {cardView?.profileEdit && (
                          <WorkProfileEdit
                            setCardView={setCardView}
                            employee_uuid={employee_uuid}
                            employeeData={data}
                            isLoadingEmployee={isLoading}
                          />
                        )}

                        {(cardView?.summary || cardView?.personalInfo) && (
                          <PersonalInfoView
                            setCardView={setCardView}
                            personalInfoData={personalInfo}
                          />
                        )}

                        {cardView?.personalInfoEdit && (
                          <PersonalInfoEdit
                            setCardView={setCardView}
                            employee_uuid={employee_uuid}
                            personalInfoData={personalInfoData}
                            isLoadingPersonalData={personalInfoLoading}
                          />
                        )}

                        {(cardView?.summary || cardView?.emergencyContact) && (
                          <EmergencyContactInfoView
                            setCardView={setCardView}
                            emergencyContactData={emergencyContactInfo}
                          />
                        )}

                        {cardView?.emergencyContactEdit && (
                          <EmergencyContactEdit
                            setCardView={setCardView}
                            employee_uuid={employee_uuid}
                            emergencyContactInfoData={emergencyContactInfoData}
                            emergencyContactInfoLoading={emergencyContactInfoLoading}
                          />
                        )}
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <ActivitiesTab
                      activities={auditLogs}
                      isLoading={auditLogsLoading}
                    />
                  </TabPane>

                  <TabPane tabId="3">
                    <EmployeeProjectsList employee_uuid={employee_uuid} emoloyeeData={data} />
                  </TabPane>

                  <TabPane tabId="4">
                    <EmployeeDocumentList employee_uuid={employee_uuid} />
                  </TabPane>

                  <TabPane tabId="5">
                    <SignatureForm employee_uuid={employee_uuid} />
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default EmployeeProfile;
