import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import CountUp from "react-countup";
import { taskWidgets } from '../../../common/data';
import {
    useGetTaskSummaryQuery
  } from "slices/tasks/taskSlice";

const Widgets = () => {

    const {
        data: taskSummary,
        isLoading: isSummaryLoading,
        refetch: refetchTaskSummary,
        error: taskError,
      } = useGetTaskSummaryQuery(undefined);

      console.log('taskSummary', taskSummary)

    return (
        <React.Fragment>
           
                <Col xxl={3} sm={6}>
                    <Card className="card-animate">
                        <CardBody>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <p className="fw-medium text-muted mb-0">{"Active Tasks"}</p>
                                    <h2 className="mt-4 ff-secondary fw-semibold">
                                        <span className="counter-value">
                                            <CountUp
                                                start={0}
                                                end={taskSummary ? taskSummary["Active Task"] : 0}
                                                decimal={"1"}
                                                suffix={""}
                                                duration={2}
                                            />
                                        </span>
                                    </h2>
                                    <p className="mb-0 text-muted"><span className={"badge bg-light mb-0 text-" +  "danger"}>
                                        {/* <i className={"align-middle " + "danger"}></i> 15 % */}
                                        </span> Number of Active Tasks</p>
                                </div>
                                <div>
                                    <div className="avatar-sm flex-shrink-0">
                                        <span className={"avatar-title rounded-circle fs-4 bg-" + "info"}>
                                            <i className={"ri-ticket-2-line"}></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>

                <Col xxl={3} sm={6}>
                    <Card className="card-animate">
                        <CardBody>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <p className="fw-medium text-muted mb-0">{"In Progress"}</p>
                                    <h2 className="mt-4 ff-secondary fw-semibold">
                                        <span className="counter-value">
                                            <CountUp
                                                start={0}
                                                end={taskSummary ? taskSummary["In Progress"]: 0}
                                                decimal={"1"}
                                                suffix={""}
                                                duration={2}
                                            />
                                        </span>
                                    </h2>
                                    <p className="mb-0 text-muted"><span className={"badge bg-light mb-0 text-" +  "warning"}>
                                        {/* <i className={"align-middle " + "warning"}></i> 15 % */}
                                    </span> Number of In Progress Tasks</p>
                                </div>
                                <div>
                                    <div className="avatar-sm flex-shrink-0">
                                        <span className={"avatar-title rounded-circle fs-4 bg-" + "warning"}>
                                            <i className={"ri-ticket-2-line"}></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>

                <Col xxl={3} sm={6}>
                    <Card className="card-animate">
                        <CardBody>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <p className="fw-medium text-muted mb-0">{"Completed"}</p>
                                    <h2 className="mt-4 ff-secondary fw-semibold">
                                        <span className="counter-value">
                                            <CountUp
                                                start={0}
                                                end={taskSummary ? taskSummary["Completed"]: 0}
                                                decimal={"1"}
                                                suffix={""}
                                                duration={2}
                                            />
                                        </span>
                                    </h2>
                                    <p className="mb-0 text-muted"><span className={"badge bg-light mb-0 text-" +  "success"}>
                                        {/* <i className={"align-middle " + "success"}></i> 15 % */}
                                    </span> Number of Completed Tasks</p>
                                </div>
                                <div>
                                    <div className="avatar-sm flex-shrink-0">
                                        <span className={"avatar-title rounded-circle fs-4 bg-" + "success"}>
                                            <i className={"ri-ticket-2-line"}></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>

                <Col xxl={3} sm={6}>
                    <Card className="card-animate">
                        <CardBody>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <p className="fw-medium text-muted mb-0">{"Cancelled"}</p>
                                    <h2 className="mt-4 ff-secondary fw-semibold">
                                        <span className="counter-value">
                                            <CountUp
                                                start={0}
                                                end={taskSummary ? taskSummary["Cancelled"]: 0}
                                                decimal={"1"}
                                                suffix={""}
                                                duration={2}
                                            />
                                        </span>
                                    </h2>
                                    <p className="mb-0 text-muted"><span className={"badge bg-light mb-0 text-" +  "danger"}>
                                        {/* <i className={"align-middle " + "danger"}></i> 15 % */}
                                    </span> Number of Cancelled Tasks</p>
                                </div>
                                <div>
                                    <div className="avatar-sm flex-shrink-0">
                                        <span className={"avatar-title rounded-circle fs-4 bg-" + "danger"}>
                                            <i className={"ri-ticket-2-line"}></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            
        </React.Fragment>
    );
};

export default Widgets;