import {
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    Modal,
    Form,
    ModalBody,
    ModalHeader,
    Label,
    Input,
    FormFeedback,
    Button,
    Spinner
} from "reactstrap";
import * as Yup from "yup";
import { Link } from 'react-router-dom';
import { useFormik } from "formik";
import { toast } from 'react-toastify';

import Dropzone from "react-dropzone";

// Import Scroll Bar - SimpleBar
import SimpleBar from 'simplebar-react';

//Import Flatepicker
import Flatpickr from "react-flatpickr";
import moment from "moment";

import {
useGetProjectsQuery,
useGetEmployeeDropDownQuery
} from "slices/employee/employeeSlice";

import {
    useGetAllPriorityQuery,
    useGetAllTaskStatusQuery
  } from "slices/tasks/taskSlice";

import { TASK_STATUS } from '../../../../Constance';


interface TaskFormModalProps {
    modal: any;
    toggle: any;
    isEdit: boolean;
    selectedFiles: any; 
    setselectedFiles: any;
    isLoadingCreateTask: boolean; 
    setModal: any;
    task: any;
    createTask: any;
    updateTask: any;
    refetchTasks: any;
}

const TaskFormModal: React.FC<TaskFormModalProps> = ({ modal, toggle, isEdit, isLoadingCreateTask, selectedFiles, setselectedFiles, setModal, task, createTask, updateTask, refetchTasks }) => {

    const { data: projectsData } = useGetProjectsQuery(undefined);

    const { data: employeedropdownData } = useGetEmployeeDropDownQuery(undefined);
  
    const { data: priorityData } = useGetAllPriorityQuery(undefined);

    const { data: taskStatus } = useGetAllTaskStatusQuery(undefined);

    const handleAcceptedFiles = (files: any) => {
        const newFiles = files.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
          })
        );
        setselectedFiles((prevFiles: any) => [...prevFiles, ...newFiles]);
      };
    
    const removeFile = (index: any) => {
    setselectedFiles((prevFiles: any) => prevFiles.filter((_: any, i: any) => i !== index));
    };

    /**
  * Formats the size
  */
  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const handleCheckboxChange = (e: any) => {
    const { value, checked } = e.target;
    const currentAssignees = validation.values.assignee;
    const intValue = parseInt(value);
  
    if (checked) {
      // If checked, add the value to the array
      validation.setFieldValue("assignee", [...currentAssignees, intValue]);
    } else {
      // If unchecked, remove the value from the array
      validation.setFieldValue("assignee", currentAssignees.filter((id: number) => id !== intValue));
    }
  };

  const validation: any = useFormik({
    enableReinitialize: true,

    initialValues: {
      start_date: task?.start_date || null,
      due_date: task?.due_date || null,
      title: task?.title || null,
      description: task?.description || null,
      task_status: task?.task_status || null,
      reporter: task?.reporter || null,
      assignee: task?.assignee || [],
      priority: task?.priority || null,
      projects: task?.projects || null,
    },
    validationSchema: Yup.object({
      start_date: Yup.string().required("Please Enter Start Date"),
      due_date: Yup.string().required("Please Enter Due Date"),
      title: Yup.string().required("Please Enter Title"),
      description: Yup.string().required("Please Enter Description"),
      task_status: Yup.number().required("Please Enter Task Status").integer(),
      reporter: Yup.number().required("Please Enter Reporter").integer(),
      assignee: Yup.array().min(1, "Please select at least one assignee"),
      priority: Yup.string().required("Please Enter Priority"),
      projects: Yup.number().required("Please Select Projects").integer(),
    }),
    onSubmit: async (values) => {
      // Handle form submission
      let data = values;
      if (selectedFiles.length > 0) {
        data = { ...values, ...{ files: selectedFiles } }
      }
      if (!isEdit) {
        try {
          await createTask(data).unwrap();
          toast.success("Task created successfully!");
          refetchTasks();
          validation.resetForm();
          setselectedFiles([]);
          toggle();
        } catch (error) {
          toast.error("Error creating Task");
        }
    } else {
      try {
        await updateTask({ id: task?.id, newTask: data }).unwrap();
        toast.success("Task updated successfully!");
        refetchTasks();
        validation.resetForm();
        setselectedFiles([]);
        toggle();
      } catch (error) {
        toast.error("Error updating Task");
      }
    }
    },
  });
    
    return <Modal
    isOpen={modal}
    toggle={toggle}
    centered
    size="lg"
    className="border-0"
    modalClassName='modal fade zoomIn'
  >
    <ModalHeader className="p-3 bg-info-subtle" toggle={toggle}>
      {!!isEdit ? "Edit Task" : "Create Task"}
    </ModalHeader>
    <Form className="tablelist-form" onSubmit={(e: any) => {
      e.preventDefault();
      validation.handleSubmit();
      return false;
    }}>
      <ModalBody className="modal-body">
        <Row className="g-3">
          <Col lg={12}>
            <Label for="taskName-field" className="form-label">Task Name</Label>
            <Input
              name="title"
              id="taskName-field"
              className="form-control"
              placeholder="Task Name"
              type="text"
              validate={{
                required: { value: true },
              }}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.title || ""}
              invalid={
                validation.touched.title && validation.errors.title ? true : false
              }
            />
            {validation.touched.title && validation.errors.title ? (
              <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
            ) : null}
          </Col>
          <Col lg={12}>
            <Label for="projectName-field" className="form-label">Project Name</Label>
            <select
              className={`form-select ${validation.touched.projects && validation.errors.projects ? 'is-invalid' : ''}`}
              name="projects"
              id="projectName-field"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.projects || ""}
            >
              <option value="">Select</option>
              {projectsData?.map((options: any) => (
                <option key={options.id} value={options.id}>
                  {options.project_name}
                </option>
              ))}
            </select>
            {validation.touched.projects && validation.errors.projects ? (
              <FormFeedback type="invalid">{validation.errors.projects}</FormFeedback>
            ) : null}

          </Col>
          <Col lg={12}>
            <div>
              <Label for="taskDescription-field" className="form-label">Task Information</Label>
              <Input
                name="description"
                id="taskDescription-field"
                className="form-control"
                placeholder="Add Task Information here..."
                type="textarea"
                validate={{
                  required: { value: true },
                }}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.description || ""}
                invalid={
                  validation.touched.description && validation.errors.description ? true : false
                }
              />
              {validation.touched.description && validation.errors.description ? (
                <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
              ) : null}
            </div>
          </Col>

          <Col lg={6}>
            <Label for="reporter-field" className="form-label">Reporter</Label>
            <select
              className={`form-select ${validation.touched.reporter && validation.errors.reporter ? 'is-invalid' : ''}`}
              name="reporter"
              id="reporter-field"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.reporter || ""}
            >
              <option value="">Select</option>
              {employeedropdownData?.map((options: any) => (
                <option key={options.id} value={options.id}>
                  <img src={options.employee_profile_image_url} alt="test" className="avatar-xxs rounded-circle" />
                  {options.employee_fullname}
                </option>
              ))}
            </select>
            {validation.touched.reporter && validation.errors.reporter ? (
              <FormFeedback type="invalid">{validation.errors.reporter}</FormFeedback>
            ) : null}
          </Col>

          <Col lg={6}>
            <Label className="form-label">Assigned To</Label>
            <SimpleBar style={{ maxHeight: "95px", paddingLeft: "5px" }}>
              <ul className="list-unstyled vstack gap-2 mb-0">
                {employeedropdownData?.map((item, key) => (<li key={key}>
                  <div className="form-check d-flex align-items-center">
                    <Input name="assignee" className="form-check-input me-3" type="checkbox"
                    //   onChange={validation.handleChange}
                      onChange={handleCheckboxChange}
                      onBlur={validation.handleBlur}
                      value={item.id}
                      invalid={validation.touched.assignee && validation.errors.assignee ? true : false}
                      id={`id-field-${item.id}`}
                      checked={validation.values.assignee.includes(item.id)}
                       />

                    <Label className="form-check-label d-flex align-items-center" htmlFor={`id-field-${item.id}`}>
                      <span className="flex-shrink-0">
                        {item.employee_profile_url ?
                          <img src={item.employee_profile_url} alt="" className="avatar-xxs rounded-circle" />
                          :
                          <div className="flex-shrink-0 avatar-xxs me-2">
                            <div className="avatar-title bg-success-subtle text-success rounded-circle fs-13">
                              {item.employee_fullname.charAt(0)}
                            </div></div>}
                      </span>
                      <span className="flex-grow-1 ms-2">
                        {item.employee_fullname}
                      </span>
                    </Label>
                    {validation.touched.assignee && validation.errors.assignee ? (
                      <FormFeedback type="invalid">{validation.errors.assignee}</FormFeedback>
                    ) : null}
                  </div>
                </li>))}
              </ul>
            </SimpleBar>
          </Col>

          <Col lg={6}>
            <Label for="startdate-field" className="form-label">Start Date</Label>

            <Flatpickr
              name="startDate"
              id="startdate-field"
              className="form-control"
              placeholder="Select a date"
              options={{
                altInput: true,
                altFormat: "d M, Y",
                dateFormat: "d M, Y",
              }}
              onChange={(start_date: any) => validation.setFieldValue("start_date", moment(start_date[0]).format("YYYY-MM-DD"))}
              value={validation.values.start_date || ''}
            />
            {validation.errors.start_date && validation.touched.start_date ? (
              <FormFeedback type="invalid" className='d-block'>{validation.errors.start_date}</FormFeedback>
            ) : null}
          </Col>

          <Col lg={6}>
            <Label for="duedate-field" className="form-label">Due Date</Label>

            <Flatpickr
              name="due_date"
              id="duedate-field"
              className="form-control"
              placeholder="Select a date"
              options={{
                altInput: true,
                altFormat: "d M, Y",
                dateFormat: "d M, Y",
              }}
              onChange={(due_date: any) => validation.setFieldValue("due_date", moment(due_date[0]).format("YYYY-MM-DD"))}
              value={validation.values.due_date || ''}
            />
            {validation.errors.due_date && validation.touched.due_date ? (
              <FormFeedback type="invalid" className='d-block'>{validation.errors.due_date}</FormFeedback>
            ) : null}
          </Col>

          {!isEdit ?
          <Col lg={6}>
            <Label for="ticket-status" className="form-label">Status</Label>
            <Input
              name="task_status"
              type="select"
              className="form-select"
              id="ticket-status"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.task_status || ""}
              invalid={
                validation.touched.task_status && validation.errors.task_status ? true : false
              }
            >
              <option value="">Status</option>
              {taskStatus?.map((options: any) => (
                <option key={options.id} value={options.id}>
                  {options.status_name}
                </option>
              ))}
            </Input>
            {validation.touched.task_status && validation.errors.task_status ? (
              <FormFeedback type="invalid">{validation.errors.task_status}</FormFeedback>
            ) : null}
          </Col> : ''}

          <Col lg={6}>
            <Label for="priority-field" className="form-label">Priority</Label>
            <Input
              name="priority"
              type="select"
              className="form-select"
              id="priority-field"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.priority || ""}
              invalid={
                validation.touched.priority && validation.errors.priority ? true : false
              }
            >
              <option value="">Priority</option>
              {priorityData?.map((options: any) => (
                <option key={options.id} value={options.id}>
                  {options.priority_name}
                </option>
              ))}
            </Input>
            {validation.touched.priority && validation.errors.priority ? (
              <FormFeedback type="invalid">{validation.errors.priority}</FormFeedback>
            ) : null}
          </Col>

          {!isEdit ?
          <Card>
            <CardHeader >
              <h5 className="card-title mb-0">Attached files</h5>
            </CardHeader>
            <CardBody>
              <div>
                <p className="text-muted">Add Attached files here.</p>

                <Dropzone
                  onDrop={acceptedFiles => {
                    handleAcceptedFiles(acceptedFiles);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone dz-clickable">
                      <div
                        className="dz-message needsclick"
                        {...getRootProps()}
                      >
                        <div className="mb-3">
                          <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                        </div>
                        <h4>Drop files here or click to upload.</h4>
                      </div>
                    </div>
                  )}
                </Dropzone>

                <ul className="list-unstyled mb-0" id="dropzone-preview">

                  {selectedFiles.map((f: any, i: any) => {
                    return (
                      <Card
                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                        key={i + "-file"}
                      >
                        <div className="p-2">
                          <Row className="align-items-center">
                            <Col className="col-auto">
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={f.name}
                                src={f.preview}
                              />
                            </Col>
                            <Col>
                              <div style={{ "display": "flex", "justifyContent": "space-between" }}>
                                <div>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </div>
                                <div style={{ "float": "left" }} className="flex-shrink-0 ms-3">
                                  <button onClick={() => removeFile(i)} className="btn btn-sm btn-danger">Delete</button>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    );
                  })}
                </ul>

              </div>
            </CardBody>
          </Card>
          : ''}

        </Row>
      </ModalBody>
      <div className="modal-footer">
        <div className="hstack gap-2 justify-content-end">
          <Button
            type="button"
            disabled={isLoadingCreateTask}
            onClick={() => {
              setModal(false);
              validation.resetForm();
              setselectedFiles([]);
            }}
            className="btn-light"
          >Close</Button>
          <Button color="success" className="btn-load" disabled={isLoadingCreateTask} type='submit'>
            {isLoadingCreateTask ? <span className="d-flex align-items-center">
              <Spinner size="sm" className="flex-shrink-0" />
              <span className="flex-grow-1 ms-2">
                Loading...
              </span>
            </span> :
              <>{!!isEdit ? "Update Task" : "Add Task"}</>
            }
          </Button>
        </div>
      </div>
    </Form>
  </Modal>
}

export { TaskFormModal }