import React from "react";
import {
  Badge,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  Col,
  Row,
  Table,
} from "reactstrap";
import { useGetEmployeeDropDownQuery } from "slices/employee/employeeSlice";
import { useGetProjectsQuery } from "slices/employee/employeeSlice";
import { useGetCompaniesQuery } from "slices/InspectionReport/InspectionReportSlice";
import { BadgeStatus } from "helpers/badgeStatus";
import { STATUS_COLORS } from "helpers/common_helper";

//SimpleBar

const imageExtensions = ["jpeg", "jpg", "png"];

const fileIconMap: { [key: string]: string } = {
  pdf: "ri-file-pdf-line", // Use an icon for PDFs
  doc: "ri-file-word-line", // Use an icon for Word docs
  docx: "ri-file-word-line",
  zip: "ri-folder-zip-line", // Use an icon for ZIP files
  rar: "ri-folder-zip-line",
  default: "ri-file-line", // Default icon for unknown file types
};

interface ProjectProps {
  toolboxMeetingByIdData: any;
}

const OverviewTab: React.FC<ProjectProps> = ({ toolboxMeetingByIdData }) => {
  const { data: employeeData } = useGetEmployeeDropDownQuery();
  const { data: projectData } = useGetProjectsQuery();
  const { data: companyData } = useGetCompaniesQuery();

  // Find Supervisor Names from IDs
  // const getSupervisorNames = () => {
  //   return getHomeworkPermitById?.work_supervisor.map((id: number) => {
  //     const supervisor = employeeData?.find((emp: any) => emp.id === id);
  //     return supervisor ? supervisor.employee_fullname : `Unknown ID (${id})`;
  //   });
  // };

  // Get Company Name
  // const getCompanyName = () => {
  //   const company = companyData?.find(
  //     (comp: any) => comp.id === getHomeworkPermitById?.company
  //   );
  //   return company
  //     ? company.company_name
  //     : `Unknown Company ID (${getHomeworkPermitById?.company})`;
  // };

  // Get Project Name
  // const getProjectName = () => {
  //   const project = projectData?.find(
  //     (proj: any) => proj.id === getHomeworkPermitById?.project
  //   );
  //   return project
  //     ? project.project_name
  //     : `Unknown Project ID (${getHomeworkPermitById?.project})`;
  // };

  // Extract file extension and return corresponding icon or image
  const getFileTypeIconOrImage = (documentUrl: string) => {
    const fileName = documentUrl?.split("/").pop()?.split("?")[0] || "";
    const fileExtension = fileName?.split(".").pop()?.toLowerCase() || "default";

    if (imageExtensions.includes(fileExtension)) {
      // Show the actual image if it's an image file
      return (
        <CardImg
          top
          width="150px"
          height="150px"
          src={documentUrl}
          alt={fileName}
          style={{ position: "relative" }}
        />
      );
    } else {
      // Return appropriate icon for non-image files
      const iconClass = fileIconMap[fileExtension] || fileIconMap["default"];
      return (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "150px" }}
        >
          <i className={`${iconClass} ri-4x`} />
        </div>
      );
    }
  };

  const truncateFileName = (fileName: string, maxLength = 20) => {
    if (fileName.length > maxLength) {
      const truncatedName = fileName.substring(0, maxLength) + "...";
      return truncatedName;
    }
    return fileName;
  };

  const extractDocumentName = (url: string) => {
    const parts = url?.split("/");
    return parts[parts?.length - 1];
  };

  return (
    <React.Fragment>
      <Row>
        <Col xl={12} lg={12}>
          <Card>
            <CardBody>
              <div className="text-muted">
                <h5 className="mt-2">Tool Box Meeting Details</h5>
                <div className="pt-3 border-top border-top-dashed mt-4">
                  <Row>
                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Main/Sub Contractor :
                        </p>
                        <h5 className="fs-15 mb-0">{toolboxMeetingByIdData?.contractor}</h5>
                      </div>
                    </Col>
                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Project :
                        </p>
                        <h5 className="fs-15 mb-0">{toolboxMeetingByIdData?.project_name}</h5>
                      </div>
                    </Col>
                    <Col lg={6} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Conducted By :
                        </p>
                        <h5 className="fs-15 mb-0">{toolboxMeetingByIdData?.submited_by}</h5>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="pt-3 border-top border-top-dashed mt-4">
                  <Row>
                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Start Date of Work :
                        </p>
                        <h5 className="fs-15 mb-0">
                          {toolboxMeetingByIdData?.start_date_of_work}
                        </h5>
                      </div>
                    </Col>
                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Start Time of Work :
                        </p>
                        <h5 className="fs-15 mb-0">
                          {toolboxMeetingByIdData?.start_time_of_work}
                        </h5>
                      </div>
                    </Col>

                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          End Date of Work :
                        </p>
                        <h5 className="fs-15 mb-0">
                          {toolboxMeetingByIdData?.end_date_of_work}
                        </h5>
                      </div>
                    </Col>

                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          End Time of Work :
                        </p>
                        <h5 className="fs-15 mb-0">
                          {toolboxMeetingByIdData?.end_time_of_work}
                        </h5>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="pt-3 border-top border-top-dashed mt-4">
                  <Row>
                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Submitted Date Time :
                        </p>
                        <h5 className="fs-15 mb-0">
                          {toolboxMeetingByIdData?.submit_date_text}
                        </h5>
                      </div>
                    </Col>
                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Submission Status :
                        </p>
                        <h5 className="fs-15 mb-0">
                          {toolboxMeetingByIdData?.report_status_name ?
                            <BadgeStatus value={toolboxMeetingByIdData?.report_status_name} color={STATUS_COLORS[toolboxMeetingByIdData?.report_status_name]} />
                            : null}
                        </h5>
                      </div>
                    </Col>

                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Approval Status :
                        </p>
                        <h5 className="fs-15 mb-0">
                          {toolboxMeetingByIdData?.approval_status_name ?
                            <BadgeStatus value={toolboxMeetingByIdData?.approval_status_name} color={STATUS_COLORS[toolboxMeetingByIdData?.approval_status_name]} />
                            : null}
                        </h5>
                      </div>
                    </Col>

                    {/* <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Submitted Location :
                        </p>
                        <div className="badge fs-15">
                          {getHomeworkPermitById?.location}
                        </div>
                      </div>
                    </Col> */}
                  </Row>
                </div>

              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={12} lg={12}>
          <Card>
            {/* <CardHeader>
              <h5 className="card-title">Task Title: &nbsp;&nbsp;{projectData?.title}</h5>
            </CardHeader> */}
            <CardBody>
              <h5 className="mt-2">Tool Box Attendance Details</h5>
              <hr />
              <Row className="mt-4">
                <Col md={12}>
                  <Table className="table-borderless align-middle mb-0">
                    <thead className="table-light">
                      <tr>
                        <th scope="col">#No</th>
                        <th scope="col">Employee No</th>
                        <th scope="col">NRIC/FIN</th>
                        <th scope="col">Name</th>
                        <th scope="col">Designation</th>
                      </tr>
                    </thead>
                    <tbody>
                      {toolboxMeetingByIdData?.toolbox_attendance?.length > 0 ? (
                        toolboxMeetingByIdData.toolbox_attendance.map(
                          (attendance: any, index: number) => {
                            return <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{attendance?.employee?.employee_number}</td>
                              <td>{attendance?.employee?.nric}</td>
                              <td>
                                <div className="d-flex align-items-center">
                                  {attendance?.employee.employee_profile_url ?
                                    <div className="flex-shrink-0">
                                      <img src={attendance?.employee.employee_profile_url} alt="" className="avatar-xs rounded-circle" />
                                    </div> :
                                    <div className="flex-shrink-0 avatar-xs">
                                      <div className="avatar-title bg-success-subtle text-success rounded-circle fs-13">
                                        {attendance?.employee.employee_fullname.charAt(0)}
                                      </div>
                                    </div>}

                                  <div className="flex-grow-1 ms-2">
                                    <h6 className="mb-1">{attendance?.employee.employee_fullname}</h6>

                                  </div>

                                </div>
                              </td>
                              <td>{attendance?.employee.position_name}</td>
                            </tr>
                          }
                        )
                      ) : (
                        <tr>
                          <td colSpan={4} className="text-center">
                            No employee attendance added yet
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>

      </Row>
    </React.Fragment>
  );
};

export default OverviewTab;
