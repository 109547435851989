import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import { useCreateSectionProcuredMutation } from "slices/resourceInventory/inventorySlice";
import { Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";
import { useGetProductByIdQuery } from "slices/resourceInventory/inventorySlice";

interface ProcuredModalProps {
  isOpen: boolean;
  toggle: () => void;
  productId: number | string;
  resourceProduct: number | string;
  refetchInventory: () => void;
  plannedAmount?: number;
  quotedAmount?: number;
  selectedBalanceQuantity?: number;
  selectedProductInventory?: number;
  selectedProcuredAmount?: number;
}

const ProcuredModal: React.FC<ProcuredModalProps> = ({
  isOpen,
  toggle,
  productId,
  resourceProduct,
  refetchInventory,
  plannedAmount = 0,
  quotedAmount = 0,
  selectedBalanceQuantity = 0,
  selectedProductInventory = 0,
  selectedProcuredAmount = 0,
}) => {
  const { data: product, isLoading } = useGetProductByIdQuery(productId);

  const [createSectionProcured, { isLoading: isCreating }] =
    useCreateSectionProcuredMutation();

  const [procuredAmount, setProcuredAmount] = useState(0);
  const [maxProcuredAmount, setMaxProcuredAmount] = useState(0);
  const [inventoryQuantity, setInventoryQuantity] = useState(0);

  // Calculate procured and max procured values based on conditions
  useEffect(() => {
    if (product?.total_stock > plannedAmount) {
      setProcuredAmount(0); // Disable input when stock exceeds planned amount
      setMaxProcuredAmount(0);
    } else if (selectedBalanceQuantity === 0) {
      const procuredValue = plannedAmount - selectedProcuredAmount;
      setProcuredAmount(procuredValue);
      setMaxProcuredAmount(procuredValue);
    } else if (selectedBalanceQuantity > 0) {
      const procuredValue =
        plannedAmount - selectedProcuredAmount + selectedProductInventory;
      setProcuredAmount(procuredValue);
      setMaxProcuredAmount(procuredValue);
    }
  }, [
    product?.total_stock,
    plannedAmount,
    selectedBalanceQuantity,
    selectedProductInventory,
    selectedProcuredAmount,
  ]);

  // Separate calculation for inventory quantity
  const calculateInventoryQuantity = () => {
    let calculatedInventoryQuantity = 0;

    if (product?.total_stock > plannedAmount && selectedBalanceQuantity === 0) {
      calculatedInventoryQuantity = plannedAmount;
    } else if (
      product?.total_stock > plannedAmount &&
      selectedBalanceQuantity > 0
    ) {
      calculatedInventoryQuantity = plannedAmount - selectedProcuredAmount;
    } else if (
      product?.total_stock === plannedAmount &&
      selectedBalanceQuantity > 0
    ) {
      calculatedInventoryQuantity = plannedAmount;
    } else {
      calculatedInventoryQuantity = 0;
    }

    setInventoryQuantity(calculatedInventoryQuantity);
  };

  // Recalculate inventory quantity when relevant dependencies change
  useEffect(() => {
    calculateInventoryQuantity();
  }, [
    product?.total_stock,
    plannedAmount,
    selectedBalanceQuantity,
    selectedProcuredAmount,
  ]);

  const validationSchema = Yup.object({
    quantity: Yup.number()
      .required("Required")
      .min(0, "Cannot be less than 0")
      .max(maxProcuredAmount, `Cannot be greater than ${maxProcuredAmount}`),
  });

  const handleSubmit = async (
    values: { quantity: number },
    { resetForm }: { resetForm: () => void }
  ) => {
    try {
      await createSectionProcured({
        product: resourceProduct,
        quantity: values.quantity,
        inventory_quantity: inventoryQuantity, // Use pre-calculated value
        next_action: "Procured.To_Initiate",
      });

      toggle();
      refetchInventory();
      resetForm();
    } catch (error) {
      console.error("Error adding procured section:", error);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Add Procured Section</ModalHeader>
      <hr />
      <ModalBody>
        <Row>
          <Col className="col-md-12">
            <div className="table-card">
              {!isLoading ? (
                <Formik
                  initialValues={{
                    quantity: procuredAmount,
                  }}
                  validationSchema={validationSchema}
                  validateOnChange={true}
                  validateOnBlur={true}
                  enableReinitialize
                  onSubmit={handleSubmit}
                >
                  {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    errors,
                    touched,
                    isSubmitting,
                  }) => (
                    <FormikForm onSubmit={handleSubmit}>
                      <table className="table mb-0">
                        <tbody>
                          <tr>
                            <td
                              className="fw-medium"
                              style={{ width: "300px" }}
                            >
                              Name
                            </td>
                            <td>{product?.name}</td>
                          </tr>
                          <tr>
                            <td
                              className="fw-medium"
                              style={{ width: "300px" }}
                            >
                              Quoted
                            </td>
                            <td>{quotedAmount}</td>
                          </tr>
                          <tr>
                            <td
                              className="fw-medium"
                              style={{ width: "300px" }}
                            >
                              Planned
                            </td>
                            <td>{plannedAmount}</td>
                          </tr>
                          <tr>
                            <td className="fw-medium">Total Inventory Stock</td>
                            <td>{product?.total_stock}</td>
                          </tr>
                          <tr>
                            <td
                              className="fw-medium"
                              style={{ width: "300px" }}
                            >
                              Procured Amount:
                            </td>
                            <td>
                              <input
                                type="number"
                                id="procured_quantity"
                                name="quantity"
                                value={values.quantity}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-control"
                                disabled={maxProcuredAmount === 0}
                              />
                              {touched.quantity && errors.quantity && (
                                <div className="text-danger">
                                  {errors.quantity}
                                </div>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td
                              className="fw-medium"
                              style={{ width: "300px" }}
                            >
                              Inventory Stock:
                            </td>
                            <td>{inventoryQuantity}</td>
                          </tr>
                        </tbody>
                      </table>

                      <ModalFooter>
                        <Button
                          color="primary"
                          type="submit"
                          disabled={isCreating || isSubmitting}
                        >
                          {isCreating || isSubmitting ? (
                            <>
                              <Spinner size="sm" />
                              &nbsp;Saving...
                            </>
                          ) : (
                            "Request for Approval"
                          )}
                        </Button>
                        &nbsp;&nbsp;
                        <Button
                          color="secondary"
                          onClick={toggle}
                          disabled={isCreating || isSubmitting}
                        >
                          Cancel
                        </Button>
                      </ModalFooter>
                    </FormikForm>
                  )}
                </Formik>
              ) : (
                <div className="text-center mt-5 mb-5">
                  Loading Product Information...
                </div>
              )}
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default ProcuredModal;
