import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Table,
  Row,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import {
  useDeleteTaskAttachmentByIdMutation,
  useCreateTaskAttachmentMutation,
  useGetAllTaskAttachmentsQuery,
} from "slices/tasks/taskSlice";
import LoadingOverlay from "react-loading-overlay-ts";
import * as Yup from "yup";
import { useFormik } from "formik";

interface TaskDocumentProps {
  task_id: string | undefined;
}

interface FormValues {
  file: File | null;
}

const TaskDocumentList: React.FC<TaskDocumentProps> = ({ task_id }) => {
  const {
    data: documents,
    isLoading,
    refetch,
  } = useGetAllTaskAttachmentsQuery(task_id, {
    skip: !task_id,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState<number | null>(
    null
  );
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const validationSchema = Yup.object().shape({
    file: Yup.mixed().required("Please upload a file"),
  });

  const [createTaskAttachment, { isLoading: postLoading }] =
    useCreateTaskAttachmentMutation();
  const [deleteTaskAttachment, { isLoading: deleteLoading }] =
    useDeleteTaskAttachmentByIdMutation();

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleAddDocument = () => {
    setSelectedDocumentId(null);
    setIsEditing(false);
    handleModalToggle();
  };

  const handleDeleteConfirmation = (id: number) => {
    setSelectedDocumentId(id);
    setDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (selectedDocumentId) {
      try {
        await deleteTaskAttachment(selectedDocumentId.toString()).unwrap();
        refetch();
        setDeleteModalOpen(false);
      } catch (error) {
        console.error("Error deleting document:", error);
      }
    }
  };

  const handleFileDownload = async (url: string) => {
    try {
      const response = await fetch(url);
      if (!response.ok)
        throw new Error(`Failed to download file: ${response.statusText}`);

      const blob = await response.blob();
      const fileURL = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = url.split("/").pop() || "file";
      link.click();
      window.URL.revokeObjectURL(fileURL);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  // Inside your component
  const formik = useFormik<FormValues>({
    initialValues: {
      file: null, // Default value is null
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const payload = {
          file: values.file,
          task: task_id,
        };
        await createTaskAttachment(payload).unwrap();
        refetch();
        formik.resetForm(); 
        handleModalToggle();
      } catch (error) {
        console.error("Error submitting document:", error);
      }
    },
    enableReinitialize: true,
  });

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      formik.setFieldValue("file", acceptedFiles[0]);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg"],
      "application/pdf": [".pdf"],
    },
    multiple: false,
  });

  if (isLoading) return <div>Loading...</div>;

  return (
    <>
      <Card>
        <CardBody>
          <div className="d-flex align-items-center mb-4">
            <h5 className="card-title flex-grow-1 mb-0">Task Documents</h5>
            <Button color="danger" onClick={handleAddDocument}>
              <i className="ri-upload-2-fill me-1 align-bottom"></i> Add
              Document
            </Button>
          </div>
          <Row>
            <Col lg={12}>
              {documents && documents.length > 0 ? (
                <div className="table-responsive">
                  <Table className="table-borderless align-middle mb-0">
                    <thead className="table-light">
                      <tr>
                        <th scope="col">File</th>
                        <th scope="col">Task Name</th>
                        <th scope="col">Created At</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {documents.map((item: any) => {
                        const fileName = item.file
                          .split("/")
                          .pop()
                          .split("?")[0];
                        const fileExtension = fileName
                          .split(".")
                          .pop()
                          .toLowerCase();
                        const imageExtensions = ["png", "jpg", "jpeg"];
                        const iconClass = imageExtensions.includes(
                          fileExtension
                        )
                          ? "ri-image-line"
                          : "ri-folder-zip-line";
                        return (
                          <tr key={item.id}>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="avatar-sm">
                                  <div
                                    className={`avatar-title bg-${
                                      iconClass.includes("image")
                                        ? "primary"
                                        : "secondary"
                                    }-subtle text-${
                                      iconClass.includes("image")
                                        ? "primary"
                                        : "secondary"
                                    } rounded fs-20`}
                                  >
                                    <i className={iconClass}></i>
                                  </div>
                                </div>
                                <div className="ms-3 flex-grow-1">
                                  <h6 className="fs-15 mb-0">
                                    <Link to="#">{fileName}</Link>
                                  </h6>
                                </div>
                              </div>
                            </td>
                            <td>{item.task_name}</td>
                            <td>
                              {new Date(item.created_at).toLocaleString()}
                            </td>
                            <td>
                              <UncontrolledDropdown direction="start">
                                <DropdownToggle
                                  tag="a"
                                  className="btn btn-light btn-icon"
                                >
                                  <i className="ri-equalizer-fill"></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem
                                    onClick={() =>
                                      handleFileDownload(item.file_url)
                                    }
                                  >
                                    <i className="ri-download-2-fill me-2 align-middle text-muted" />
                                    Download
                                  </DropdownItem>
                                  <DropdownItem divider />
                                  <DropdownItem
                                    onClick={() =>
                                      handleDeleteConfirmation(item.id)
                                    }
                                  >
                                    <i className="ri-delete-bin-5-line me-2 align-middle text-muted" />
                                    Delete
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <div className="d-flex justify-content-center align-items-center">
                  No documents available.
                </div>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>

      {/* Add/Edit Document Modal with Dropzone */}
      <Modal isOpen={isModalOpen} toggle={handleModalToggle} centered>
        <ModalHeader toggle={handleModalToggle}>
          {isEditing ? "Edit Document" : "Add Document"}
        </ModalHeader>
        <ModalBody>
          <LoadingOverlay active={isLoading} spinner text="Loading...">
            <form onSubmit={formik.handleSubmit}>
              <div
                {...getRootProps()}
                className={`dropzone dz-clickable mb-2 ${
                  isDragActive ? "border-primary" : "border-secondary"
                }`}
              >
                <div className="dz-message needsclick">
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <div className="mb-3">
                      <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                    </div>
                  ) : formik.values.file ? (
                    <p>{formik.values.file.name}</p>
                  ) : (
                    <h4>Drop files here or click to upload.</h4>
                  )}
                </div>
              </div>
              {formik.errors.file && (
                <div className="text-danger mt-2">{formik.errors.file}</div>
              )}
              <Button color="primary" type="submit" disabled={postLoading}>
                {postLoading ? <Spinner size="sm" /> : "Submit"}
              </Button>
              &nbsp;&nbsp;
              <Button
                color="secondary"
                disabled={postLoading}
                onClick={() => {
                  formik.resetForm(); // Reset the form when user clicks cancel
                  handleModalToggle();
                }}
              >
                Cancel
              </Button>
            </form>
          </LoadingOverlay>
        </ModalBody>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        isOpen={deleteModalOpen}
        toggle={() => setDeleteModalOpen(false)}
        centered
      >
        <ModalHeader toggle={() => setDeleteModalOpen(false)}>
          Confirm Delete
        </ModalHeader>
        <ModalBody>
          Are you sure you want to delete this document? This action cannot be
          undone.
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            disabled={deleteLoading}
            onClick={() => setDeleteModalOpen(false)}
          >
            Cancel
          </Button>
          <Button
            color="danger"
            onClick={handleConfirmDelete}
            disabled={deleteLoading}
          >
            {deleteLoading ? <Spinner size="sm" /> : "Delete"}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default TaskDocumentList;
