import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { customBaseQuery } from "helpers/jwt-token-access/customBaseQuery";
import { InspectionReport } from "./type";

const convertToFormData = (obj: any) => {
    const formData = new FormData();
    Object.keys(obj).forEach(key => {
      if (Array.isArray(obj[key])) {
        obj[key].forEach(item => formData.append(key, item));
      } else {
        formData.append(key, obj[key]);
      }
    });
    return formData;
  };

// Define the type for the report creation result
type CreateReportResult = {
    id: number;
    // Add other properties if needed
  };

// InspectionReportSlice creation
export const inspectionReportSlice = createApi({
  reducerPath: "inspectionReportApi",
  baseQuery: customBaseQuery,
  endpoints: (builder) => ({
    // GET all inspection reports
    getInspectionReports: builder.query<InspectionReport[], void>({
      query: () => "/reports/api/v1/inspection_report/",
    }),

    // POST create an inspection report
    createInspectionReport: builder.mutation<CreateReportResult, Partial<InspectionReport>>({
      query: (reportData) => ({
        url: "/reports/api/v1/create_update_inspection/",
        method: "POST",
        body: reportData,
      }),
    }),

    // PATCH update an inspection report by ID
    updateInspectionReport: builder.mutation<void, { id: number | null; reportData: Partial<InspectionReport> }>({
      query: ({ id, reportData }) => ({
        url: `/reports/api/v1/create_update_inspection/${id}/`,
        method: "PATCH",
        body: reportData,
      }),
    }),

    // GET an inspection report by ID
    getInspectionReportById: builder.query<InspectionReport, number>({
      query: (id) => `/reports/api/v1/inspection_report/${id}/`,
    }),

    updateInspectionReportById: builder.mutation({
        query: ({ id, reportData }: { id: number; reportData: any }) => {
          const formData = convertToFormData(reportData);
          return {
            url: `/reports/api/v1/inspection_report_update/${id}`,
            method: "PATCH",
            body: formData,
          };
        },
      }),


    getCompanies: builder.query<any[], void>({
        query: () => "/organization/api/v1/company/",
      }),
  }),
});

export const {
  useGetInspectionReportsQuery,
  useCreateInspectionReportMutation,
  useUpdateInspectionReportMutation,
  useGetInspectionReportByIdQuery,
  useUpdateInspectionReportByIdMutation,
  useGetCompaniesQuery,
} = inspectionReportSlice;
