import React from "react";
import {
  Button,
  FormGroup,
  Label,
  Form,
  Spinner,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
} from "reactstrap";
import Select from "react-select";

interface Step4ToolBoxMeetingProps {
  previousStep: () => void;
  nextStep: () => void;
  formik: any;
  handleSelectChange: any;
  handleDelete: any;
  permitData: any;
  toolboxMeetings: any;
  refetchPermit: any;
  setIsModalOpen: any;
  isModalOpen: boolean;
  selectedToolbox: any;
  patchisLoading: boolean;
  toolboxLoading: boolean;
  isPermitLoading: boolean;
  isPermitFetching: boolean;
}

const Step4ToolBoxMeeting: React.FC<Step4ToolBoxMeetingProps> = (
  { 
    previousStep, 
    nextStep,
    formik,
    handleSelectChange,
    handleDelete,
    permitData,
    toolboxMeetings,
    refetchPermit,
    setIsModalOpen,
    isModalOpen,
    selectedToolbox,
    patchisLoading,
    toolboxLoading,
    isPermitLoading,
    isPermitFetching
  }) => {

  const toolboxOptions = toolboxMeetings?.results.map((meeting: any) => ({
    value: meeting.toolbox_id,
    label: `${meeting.toolbox_id} (Start: ${meeting.start_date_of_work}, End: ${meeting.end_date_of_work})`,
  }));

  const handlePreviousStep = () => {
    refetchPermit();
    previousStep();
  };

  if (toolboxLoading || isPermitLoading) {
    return <Spinner color="primary" />;
  }

  return (
    <div>
      <Button color="primary" onClick={() => setIsModalOpen(true)}>
        Add Toolbox Meeting
      </Button>

      <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)}>
        <ModalHeader toggle={() => setIsModalOpen(!isModalOpen)}>Select Toolbox Meeting</ModalHeader>
        <ModalBody>
          <Form onSubmit={formik.handleSubmit}>
            <FormGroup>
              <Label for="toolboxMeeting">Toolbox Meeting</Label>
              <Select
                id="toolboxMeeting"
                name="selectedToolbox"
                value={selectedToolbox}
                onChange={handleSelectChange}
                options={toolboxOptions}
              />
              {formik.touched.selectedToolbox && formik.errors.selectedToolbox && (
                <div className="text-danger">{formik.errors.selectedToolbox}</div>
              )}
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={formik.submitForm}
            disabled={(patchisLoading || isPermitFetching)}
          >
            {(patchisLoading || isPermitFetching) ? <Spinner size="sm" /> : "Submit"}
          </Button>
          <Button disabled={(patchisLoading || isPermitFetching)} color="secondary" onClick={() => setIsModalOpen(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Col md={12} className="mt-4">
        <h5>Toolbox Meeting Details</h5>
        <hr />
        {permitData?.toolbox_meeting?.length > 0 ? (
          <Table className="table-borderless align-middle mb-0">
            <thead className="table-light">
              <tr>
                <th scope="col">Toolbox ID</th>
                <th scope="col">Contractor</th>
                <th scope="col">Project</th>
                <th scope="col">Start Date</th>
                <th scope="col">End Date</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {permitData?.toolbox_meeting?.map((toolbox: any) => (
                <tr key={toolbox.toolbox_id}>
                  <td>{toolbox.toolbox_id}</td>
                  <td>{toolbox.contractor}</td>
                  <td>{toolbox.project_name}</td>
                  <td>{toolbox.start_date_of_work}</td>
                  <td>{toolbox.end_date_of_work}</td>
                  <td>
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() => handleDelete(toolbox?.toolbox_id)}
                      disabled={patchisLoading}
                    >
                      {patchisLoading ? <Spinner size="sm" /> : <><i className="ri-delete-bin-5-line align-middle"></i></>}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <div>No toolbox meetings linked yet.</div>
        )}
      </Col>

      <div className="d-flex justify-content-between mt-3">
        <Button color="secondary" onClick={handlePreviousStep}>
          Previous
        </Button>
        <Button
          color="primary"
          onClick={nextStep}
          disabled={!permitData?.toolbox_meeting?.length}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default Step4ToolBoxMeeting;
