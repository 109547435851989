import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Col,
  Row,
  Input,
  Form,
  Label,
  FormGroup,
  Spinner,
} from "reactstrap";
import Select from "react-select"; // Import Select from react-select
import {
  useCreateHomeworkPermitMutation,
  useUpdateHomeworkPermitMutation,
  useGetHomeworkPermitByNumberQuery,
} from "slices/safetyApp/safetyappSlice";
import { useGetEmployeeDropDownQuery, useGetEmployeeDropDownByProjectQuery } from "slices/employee/employeeSlice";
import { useGetCompaniesQuery } from "slices/InspectionReport/InspectionReportSlice";

import LoadingOverlay from "react-loading-overlay-ts";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { useParams, useNavigate } from "react-router-dom";

interface Step1HomeWorkPermitProps {
  geoPermission: boolean;
  selectedProject: string;
  projectsData: any;
  nextStep: () => void;
  // setHwPermitNo: (no: string) => void; 
  // hwPermitNo: string | null; 
}

interface SupervisorOption {
  value: number;
  label: string;
}


interface FormValues {
  work_performed_by: string;
  project: string;
  company: number | "";
  company_others: string;
  work_supervisor: number[];
  work_supervisor_others: string;
  supervisor_contact_number_others: string;
  equipment_used: string;
  start_date_of_work: string;
  start_time_of_work: string;
  end_date_of_work: string;
  end_time_of_work: string;
  work_location: string;
  work_description: string;
}

const Step1HomeWorkPermit: React.FC<Step1HomeWorkPermitProps> = ({
  geoPermission,
  selectedProject,
  projectsData,
  nextStep,
}) => {
  const { selectedProject: urlProjectId, hwPermitNo: hwPermitNoFromURL } = useParams<{ selectedProject: string; hwPermitNo: string }>();
  const navigate = useNavigate();

  const { data: companiesData } = useGetCompaniesQuery();
  const { data: supervisorsData } = useGetEmployeeDropDownByProjectQuery(urlProjectId, {
    skip: !urlProjectId,
  });
  const [createHomeworkPermit, { isLoading: homepermitisLoading }] =
    useCreateHomeworkPermitMutation();
  const [updateHomeworkPermit, { isLoading: patchisLoading }] =
    useUpdateHomeworkPermitMutation();

  const {
    data: hwPermitData,
    isLoading: isPermitLoading,
    refetch: refetchHomeWorkPermit,
  } = useGetHomeworkPermitByNumberQuery(hwPermitNoFromURL);

  const supervisorOptions: SupervisorOption[] =
    supervisorsData?.map((supervisor) => ({
      value: supervisor.id,
      label: supervisor.employee_fullname,
    })) || [];

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Add 1 to month since it's zero-indexed
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      work_performed_by: hwPermitData?.work_performed_by || "",
      project: selectedProject,
      company: hwPermitData?.company || "",
      company_others: hwPermitData?.company_others || "",
      work_supervisor: hwPermitData?.work_supervisor || [],
      work_supervisor_others:
        hwPermitData?.work_supervisor_others || "",
      supervisor_contact_number_others:
        hwPermitData?.supervisor_contact_number_others || "",
      equipment_used: hwPermitData?.equipment_used || "",
      start_date_of_work: hwPermitData?.start_date_of_work || getCurrentDate(),
      start_time_of_work: hwPermitData?.start_time_of_work || "",
      end_date_of_work: hwPermitData?.end_date_of_work || "",
      end_time_of_work: hwPermitData?.end_time_of_work || "",
      work_location: hwPermitData?.work_location || "",
      work_description: hwPermitData?.work_description || "",
    },
    validationSchema: Yup.object({
      work_supervisor: Yup.array().min(
        1,
        "At least one supervisor is required"
      ),
      work_performed_by: Yup.string()
        .max(250, "Max 250 characters")
        .required("Work performed by is required"),
      start_date_of_work: Yup.date().required("Start date is required"),
      start_time_of_work: Yup.string().required("Start time is required"),
      end_date_of_work: Yup.date().required("End date is required"),
      end_time_of_work: Yup.string().required("End time is required"),
      work_location: Yup.string().optional(),
      work_description: Yup.string().optional(),
      supervisor_contact_number_others: Yup.string().optional(),
      company_others: Yup.string().optional(),
      work_supervisor_others: Yup.string().optional(),
    }),
    onSubmit: async (values) => {
      console.log('values', values);
      try {
        const payload = {
          ...values,
          supervisor_contact_number_others:
            values.supervisor_contact_number_others,
          company_others: values.company_others,
          work_supervisor_others: values.work_supervisor_others,
          equipment_used: values.equipment_used,
          work_location: values.work_location,
          work_description: values.work_description,
        };

        let response;
        if (hwPermitNoFromURL) {
          response = await updateHomeworkPermit({
            hw_permit_no: hwPermitNoFromURL,
            updatedPermitData: payload,
          }).unwrap();
        } else {
          response = await createHomeworkPermit(payload).unwrap();
          localStorage.setItem("hwPermitNo", response.hw_permit_no);
          navigate(
            `/homework-permit-wizard/${selectedProject}/${response.hw_permit_no}`
          );
        }

        formik.resetForm();
        nextStep();
      } catch (error) {
        console.error("Failed to submit the form", error);
      }
    },
  });

  useEffect(() => {
    if (hwPermitNoFromURL) {
      refetchHomeWorkPermit();
    }
  }, [hwPermitNoFromURL, refetchHomeWorkPermit]);

  useEffect(() => {
    if (!isPermitLoading && hwPermitData) {
      const {
        work_performed_by,
        company,
        company_others,
        work_supervisor,
        work_supervisor_others,
        supervisor_contact_number_others,
        equipment_used,
        start_date_of_work,
        start_time_of_work,
        end_date_of_work,
        end_time_of_work,
        work_location,
        work_description,
      } = hwPermitData;

      formik.setValues({
        work_performed_by: work_performed_by || "",
        project: selectedProject,
        company: company || "",
        company_others: company_others || "",
        work_supervisor: work_supervisor || [],
        work_supervisor_others: work_supervisor_others || "",
        supervisor_contact_number_others: supervisor_contact_number_others || "",
        equipment_used: equipment_used || "",
        start_date_of_work: start_date_of_work || getCurrentDate(),
        start_time_of_work: start_time_of_work || "",
        end_date_of_work: end_date_of_work || "",
        end_time_of_work: end_time_of_work || "",
        work_location: work_location || "",
        work_description: work_description || " ",
      });
    }
  }, [isPermitLoading, hwPermitData, selectedProject]);

  const handleSupervisorChange = (selectedOptions: SupervisorOption[]) => {
    const selectedIds = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    formik.setFieldValue("work_supervisor", selectedIds);
  };

  const handleCompanyOthersChange = (tags: string[]) => {
    formik.setFieldValue("company_others", tags.join(','));
  };

  const handleSupervisorOthersChange = (tags: string[]) => {
    formik.setFieldValue("work_supervisor_others", tags.join(','));
  };

  const handleSupervisorContactNumberChange = (tags: string[]) => {
    formik.setFieldValue("supervisor_contact_number_others", tags.join(','));
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <LoadingOverlay active={isPermitLoading} spinner text="Fetching...">
        <Row>
          <Col md={12}>
            <Label className="card-title"> Site Information</Label>
            <hr />
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="work_performed_by">
                Work Performed By <span className="text-danger">*</span>
              </Label>
              <Input
                id="work_performed_by"
                name="work_performed_by"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.work_performed_by}
                invalid={
                  formik.touched.work_performed_by &&
                  !!formik.errors.work_performed_by
                }
              />
              {formik.touched.work_performed_by &&
                formik.errors.work_performed_by ? (
                <div className="text-danger">
                  {formik.errors.work_performed_by as string}
                </div>
              ) : null}
            </FormGroup>
          </Col>

          {/* Project Name (read-only) */}
          <Col md={6}>
            <FormGroup>
              <Label for="project_name">Project Name</Label>
              <Input
                id="project_name"
                name="project_name"
                type="text"
                value={projectsData?.project_name || ""} // Accessing project name here
                readOnly
                disabled
              />
            </FormGroup>
          </Col>

          {/* Company Dropdown */}
          <Col md={6}>
            <FormGroup>
              <Label for="company">
                Company <span className="text-danger">*</span>
              </Label>
              <Input
                id="company"
                name="company"
                type="select"
                onChange={formik.handleChange}
                value={formik.values.company}
                invalid={formik.touched.company && !!formik.errors.company}
              >
                <option value="">Select Company</option>
                {companiesData?.map((company) => (
                  <option key={company.id} value={company.id}>
                    {company.company_name}
                  </option>
                ))}
              </Input>
              {formik.touched.company && formik.errors.company ? (
                <div className="text-danger">
                  {formik.errors.company as string}
                </div>
              ) : null}
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="company_others">Other Companies</Label>
              <TagsInput
                addKeys={[9, 13, 188]}
                value={formik.values.company_others ? formik.values.company_others.split(",") : []}
                onChange={handleCompanyOthersChange}
                inputProps={{
                  placeholder: "Add a company",
                  style: {
                    padding: "6px",
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    minWidth: "150px",
                    border: "none",
                    outline: "none",
                    flex: 1,
                  },
                }}
              />
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="work_supervisor">
                Work Supervisor <span className="text-danger">*</span>
              </Label>
              <Select
                id="work_supervisor"
                isMulti
                options={supervisorOptions}
                value={supervisorOptions.filter((option) =>
                  formik.values.work_supervisor.includes(option.value)
                )}
                onChange={handleSupervisorChange}
              />
              {formik.touched.work_supervisor &&
                formik.errors.work_supervisor ? (
                <div className="text-danger">
                  {formik.errors.work_supervisor as string}
                </div>
              ) : null}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="work_supervisor_others">Other Supervisors</Label>
              <TagsInput
                addKeys={[9, 13, 188]}
                value={formik.values.work_supervisor_others ? formik.values.work_supervisor_others.split(",") : []}
                // value={formik.values.work_supervisor_others}
                onChange={handleSupervisorOthersChange}
                inputProps={{
                  placeholder: "Add a supervisor",
                  style: {
                    padding: "6px",
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    minWidth: "150px",
                    border: "none",
                    outline: "none",
                    flex: 1,
                  },
                }}
              />
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="supervisor_contact_number_others">
                Other Supervisor Contact Numbers
              </Label>
              <TagsInput
                addKeys={[9, 13, 188]}
                value={formik.values.supervisor_contact_number_others ? formik.values.supervisor_contact_number_others.split(",") : []}
                // value={formik.values.supervisor_contact_number_others}
                onChange={handleSupervisorContactNumberChange}
                inputProps={{
                  placeholder: "Add contact numbers",
                  style: {
                    padding: "6px",
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    minWidth: "150px",
                    border: "none",
                    outline: "none",
                    flex: 1,
                  },
                }}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="equipment_used">Equipment Used</Label>
              <TagsInput
                addKeys={[9, 13, 188]}
                value={formik.values.equipment_used ? formik.values.equipment_used.split(",") : []}
                onChange={(tags) => formik.setFieldValue("equipment_used", tags.join(","))}
                inputProps={{
                  placeholder: "Add equipment",
                  style: {
                    padding: "6px",
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    minWidth: "150px",
                    border: "none",
                    outline: "none",
                    flex: 1,
                  },
                }}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="start_date_of_work">
                Start Date <span className="text-danger">*</span>
              </Label>
              <Input
                id="start_date_of_work"
                name="start_date_of_work"
                type="date"
                onChange={formik.handleChange}
                value={formik.values.start_date_of_work}
                invalid={
                  formik.touched.start_date_of_work &&
                  !!formik.errors.start_date_of_work
                }
              />
              {formik.touched.start_date_of_work &&
                formik.errors.start_date_of_work ? (
                <div className="text-danger">
                  {formik.errors.start_date_of_work as string}
                </div>
              ) : null}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="start_time_of_work">
                Start Time <span className="text-danger">*</span>
              </Label>
              <Input
                id="start_time_of_work"
                name="start_time_of_work"
                type="time"
                onChange={formik.handleChange}
                value={formik.values.start_time_of_work}
                invalid={
                  formik.touched.start_time_of_work &&
                  !!formik.errors.start_time_of_work
                }
              />
              {formik.touched.start_time_of_work &&
                formik.errors.start_time_of_work ? (
                <div className="text-danger">
                  {formik.errors.start_time_of_work as string}
                </div>
              ) : null}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="end_date_of_work">
                End Date <span className="text-danger">*</span>
              </Label>
              <Input
                id="end_date_of_work"
                name="end_date_of_work"
                type="date"
                onChange={formik.handleChange}
                value={formik.values.end_date_of_work}
                invalid={
                  formik.touched.end_date_of_work &&
                  !!formik.errors.end_date_of_work
                }
              />
              {formik.touched.end_date_of_work &&
                formik.errors.end_date_of_work ? (
                <div className="text-danger">
                  {formik.errors.end_date_of_work as string}
                </div>
              ) : null}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="end_time_of_work">
                End Time <span className="text-danger">*</span>
              </Label>
              <Input
                id="end_time_of_work"
                name="end_time_of_work"
                type="time"
                onChange={formik.handleChange}
                value={formik.values.end_time_of_work}
                invalid={
                  formik.touched.end_time_of_work &&
                  !!formik.errors.end_time_of_work
                }
              />
              {formik.touched.end_time_of_work &&
                formik.errors.end_time_of_work ? (
                <div className="text-danger">
                  {formik.errors.end_time_of_work as string}
                </div>
              ) : null}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="location">Location</Label>
              <TagsInput
                addKeys={[9, 13, 188]}
                value={formik.values.work_location ? formik.values.work_location.split(",") : []}
                onChange={(tags) => formik.setFieldValue("work_location", tags.join(","))}
                inputProps={{
                  placeholder: "Add locations",
                  style: {
                    padding: "6px",
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    minWidth: "150px",
                    border: "none",
                    outline: "none",
                    flex: 1,
                  },
                }}
              />
            </FormGroup>
          </Col>

          <Col md={12}>
            <FormGroup>
              <Label for="work_description">
                Work Description
              </Label>
              <Input
                id="work_description"
                name="work_description"
                type="textarea"
                onChange={(e) => formik.setFieldValue("work_description", e.target.value)}
                value={formik.values.work_description}
                invalid={
                  formik.touched.work_description &&
                  !!formik.errors.work_description
                }
              />
              {formik.touched.work_description && formik.errors.work_description ? (
                <div className="text-danger">
                  {formik.errors.work_description as string}
                </div>
              ) : null}
            </FormGroup>
          </Col>
          <Col md={12}>
            <Button type="submit" color="primary" disabled={!geoPermission}>
              {homepermitisLoading || patchisLoading ? (
                <Spinner size="sm" />
              ) : (
                "Next"
              )}
            </Button>
          </Col>
        </Row>
      </LoadingOverlay>
    </Form>
  );
};

export default Step1HomeWorkPermit;
