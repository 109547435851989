import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Modal,
  Row,
  UncontrolledDropdown,
  ModalBody,
  ModalHeader,
  Table,
} from "reactstrap";

//SimpleBar
import SimpleBar from "simplebar-react";

//import images
import avatar2 from "../../../assets/images/users/avatar-2.jpg";
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";
import avatar8 from "../../../assets/images/users/avatar-8.jpg";

import avatar7 from "../../../assets/images/users/avatar-7.jpg";
import avatar5 from "../../../assets/images/users/avatar-5.jpg";

interface ProjectProps {
  projectData: any;
}

const TeamTab: React.FC<ProjectProps> = ({ projectData }) => {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Row>
            {/* Check if project_list has data */}
            {projectData?.members_list &&
            projectData?.members_list.length > 0 ? (
              <div className="table-responsive">
                <Table className="table-borderless align-middle mb-0">
                  <thead className="table-light">
                    <tr>
                      <th scope="col">Employees</th>
                      {/* <th scope="col">Description</th> */}
                      <th scope="col">Employee Number</th>
                      <th scope="col">Assigned Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {projectData?.members_list.map(
                      (members: any, index: any) => (
                        <tr key={index}>
                          <td>
                            {/* <div className="avatar-xs flex-shrink-0 me-3">
                              {members?.project_url ? (
                                <img
                                  src={members?.project_url}
                                  alt=""
                                  className="rounded-circle avatar-sm"
                                  style={{
                                    width: "2rem",
                                    height: "2rem",
                                  }}
                                />
                              ) : (
                                <>{members?.employee_name.charAt(0)}</>
                              )}
                              {members.employee_name}
                            </div> */}
                            {members.employee_name}
                          </td>
                          {/* <td>{project.description}</td> */}
                          <td>{members?.employee_number}</td>
                          <td>{members?.assigned_date}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </Table>
              </div>
            ) : (
              // Fallback content if no projects are found
              <p className="text-muted">No projects assigned</p>
            )}
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default TeamTab;
