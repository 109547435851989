import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner } from "reactstrap";

interface WorkerDeleteConfirmationModalProps {
  isOpen: boolean;
  toggle: () => void;
  onDelete: () => void;
  isDeleting: boolean;
  isPermitFetching: boolean;
}

const WorkerDeleteConfirmationModal: React.FC<WorkerDeleteConfirmationModalProps> = ({
  isOpen,
  toggle,
  onDelete,
  isDeleting,
  isPermitFetching
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Delete Work Location</ModalHeader>
      <ModalBody>Are you sure you want to delete this work location?</ModalBody>
      <ModalFooter>
        <Button color="secondary" disabled={isDeleting || isPermitFetching} onClick={toggle}>
          Cancel
        </Button>
        <Button color="danger" disabled={isDeleting || isPermitFetching} onClick={onDelete}>
          {isDeleting ? <Spinner size="sm" /> : "Delete"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default WorkerDeleteConfirmationModal;
