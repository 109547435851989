import React, { useEffect, useState } from "react";
import {
  Button,
  FormGroup,
  Label,
  Form,
  Spinner,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import {
  useGetToolboxMeetingViewQuery,
  useLinkToolboxMeetingMutation,
  useGetHomeworkPermitByNumberQuery,
} from "slices/safetyApp/safetyappSlice";
import Select from "react-select";

interface Step2QuestionnaireProps {
  previousStep: () => void;
  nextStep: () => void;
}

const StepToolBoxMeeting: React.FC<Step2QuestionnaireProps> = ({ previousStep, nextStep }) => {
  const { hwPermitNo } = useParams<{ hwPermitNo: string }>();

  const { data: homeworkPermitData, isLoading: homeworkLoading, refetch: refetchHomeworkPermit } =
    useGetHomeworkPermitByNumberQuery(hwPermitNo);

  const { data: toolboxMeetings, isLoading: toolboxLoading } = useGetToolboxMeetingViewQuery(undefined);

  const [linkToolboxMeeting, { isLoading: patchisLoading }] = useLinkToolboxMeetingMutation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedToolbox, setSelectedToolbox] = useState<{ value: string; label: string } | null>(null);

  const formik = useFormik({
    initialValues: {
      selectedToolbox: "",
    },
    validationSchema: Yup.object({
      selectedToolbox: Yup.string().required("Please select a Toolbox Meeting"),
    }),
    onSubmit: async () => {
      if (!hwPermitNo || !selectedToolbox) return;

      const payload = {
        action_type: "PATCH",
        permit_type: "HOME_WORK_PERMIT",
        permit_no: hwPermitNo,
      };

      try {
        await linkToolboxMeeting({
          toolbox_id: selectedToolbox.value,
          linkData: payload,
        });

        refetchHomeworkPermit();
        setIsModalOpen(false);
        setSelectedToolbox(null);
      } catch (error) {
        console.error("Error linking toolbox meeting:", error);
      }
    },
  });

  const handleSelectChange = (selected: any) => {
    setSelectedToolbox(selected);
    formik.setFieldValue("selectedToolbox", selected?.value);
  };

  const handleDelete = async (toolboxId: string) => {
    if (!hwPermitNo) return;

    const payload = {
      action_type: "DELETE",
      permit_no: hwPermitNo,
      permit_type: "HOME_WORK_PERMIT",
    };

    try {
      await linkToolboxMeeting({
        toolbox_id: toolboxId,
        linkData: payload,
      });

      refetchHomeworkPermit();
    } catch (error) {
      console.error("Error deleting toolbox meeting:", error);
    }
  };

  if (toolboxLoading || homeworkLoading) {
    return <Spinner color="primary" />;
  }

  const toolboxOptions = toolboxMeetings?.results.map((meeting: any) => ({
    value: meeting.toolbox_id,
    label: `${meeting.toolbox_id} (Start: ${meeting.start_date_of_work}, End: ${meeting.end_date_of_work})`,
  }));

  const handlePreviousStep = () => {
    refetchHomeworkPermit();
    previousStep();
  };

  return (
    <div>
      <Button color="primary" onClick={() => setIsModalOpen(true)}>
        Add Toolbox Meeting
      </Button>

      <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)}>
        <ModalHeader toggle={() => setIsModalOpen(!isModalOpen)}>Select Toolbox Meeting</ModalHeader>
        <ModalBody>
          <Form onSubmit={formik.handleSubmit}>
            <FormGroup>
              <Label for="toolboxMeeting">Toolbox Meeting</Label>
              <Select
                id="toolboxMeeting"
                name="selectedToolbox"
                value={selectedToolbox}
                onChange={handleSelectChange}
                options={toolboxOptions}
              />
              {formik.touched.selectedToolbox && formik.errors.selectedToolbox && (
                <div className="text-danger">{formik.errors.selectedToolbox}</div>
              )}
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={formik.submitForm}
            disabled={patchisLoading}
          >
            {patchisLoading ? <Spinner size="sm" /> : "Submit"}
          </Button>
          <Button color="secondary" onClick={() => setIsModalOpen(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Col md={12} className="mt-4">
        <h5>Toolbox Meeting Details</h5>
        <hr />
        {homeworkPermitData?.toolbox_meeting?.length > 0 ? (
          <Table className="table-borderless align-middle mb-0">
            <thead className="table-light">
              <tr>
                <th scope="col">Toolbox ID</th>
                <th scope="col">Contractor</th>
                <th scope="col">Project</th>
                <th scope="col">Start Date</th>
                <th scope="col">End Date</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {homeworkPermitData?.toolbox_meeting?.map((toolbox: any) => (
                <tr key={toolbox.toolbox_id}>
                  <td>{toolbox.toolbox_id}</td>
                  <td>{toolbox.contractor}</td>
                  <td>{toolbox.project_name}</td>
                  <td>{toolbox.start_date_of_work}</td>
                  <td>{toolbox.end_date_of_work}</td>
                  <td>
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() => handleDelete(toolbox?.toolbox_id)}
                      disabled={patchisLoading}
                    >
                      {patchisLoading ? <Spinner size="sm" /> : <><i className="ri-delete-bin-5-line align-middle"></i></>}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <div>No toolbox meetings linked yet.</div>
        )}
      </Col>

      <div className="d-flex justify-content-between mt-3">
        <Button color="secondary" onClick={handlePreviousStep}>
          Previous
        </Button>
        <Button
          color="primary"
          onClick={nextStep}
          disabled={!homeworkPermitData?.toolbox_meeting?.length}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default StepToolBoxMeeting;
