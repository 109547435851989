import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import BreadCrumb from "Components/Common/BreadCrumb";
import Step1HotWorkPermit from "./WizardSteps/Step1HotWorkPermit";
import Step2QuestionnaireIndex from "./WizardSteps/Step2QuestionnaireIndex";
import Step3WorkAreaAllocationIndex from "./WizardSteps/Step3WorkAreaAllocationIndex";
import Step4ToolBoxMeetingIndex from "./WizardSteps/Step4ToolBoxMeetingIndex";
import Step5FileUploadIndex from "./WizardSteps/Step5FileUploadIndex";
import Step6PreviewSubmitIndex from "./WizardSteps/Step6PreviewSubmitIndex";
import { safetyAppSlice } from "slices/safetyApp/safetyappSlice";
import ValidationAlertStatic from "../../ValidationAlertStatic";
import { getErrorText } from "../../../../helpers/error_message";
import { useGetWorkflowsQuery } from "slices/workflow/WorkFlowSlice";
import { useGetprojectByIdQuery } from "slices/projects/projectSlice";


const HotworkPermitWizard: React.FC = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState<number>(1);
  

  const [modalBackDrop, setModalBackDrop] = useState<boolean>(false);
  const [validationMessages, setValidationMessages] = useState<string[]>([]);

  const [location, setLocation] = useState<{ latitude: number | null; longitude: number | null }>({ latitude: null, longitude: null });

  const { selectedProject, hwPermitNo: hwPermitNoFromURL } = useParams<{ selectedProject: string; hwPermitNo?: string }>();
  const { data: projectsData } = useGetprojectByIdQuery(selectedProject);

  const {
    data: workFlow,
    isLoading: isLoadingWorkFlow,
    // refetch: refetchWorkFlow,
  } = useGetWorkflowsQuery("SAFETY_APP");

  const toggleTab = (tab: number) => {
    if (activeTab !== tab) {
      if (hwPermitNoFromURL) {
        dispatch(safetyAppSlice.util.invalidateTags([{ type: "GetHotworkPermitByNumber", id: hwPermitNoFromURL }]));
      }
      setActiveTab(tab);
    }
  };


  const getLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (err) => {
          setValidationMessages((prev) => {
            const errorMessage = getErrorText('location-disabled');
            return prev.includes(errorMessage)
              ? prev
              : [...prev, errorMessage]
          });
          setModalBackDrop(true);
        },
        { enableHighAccuracy: true }
      );
    } else {
      setValidationMessages((prev) => {
        const errorMessage = getErrorText('location-not-supported');
        return prev.includes(errorMessage)
          ? prev
          : [...prev, errorMessage]
      });
      setModalBackDrop(true);

    }
  };

  const checkWorkflowProject = () => {
    const isProjectInWorkflow = workFlow.some((workflow: any) =>
      workflow.projects.includes(selectedProject ? parseInt(selectedProject) : -1)
    );

    if (!isProjectInWorkflow) {
      setValidationMessages((prev: any) => {
        const errorMessage = (<span>{getErrorText('workflow-not-assigned')} <b>{projectsData?.project_name}</b></span>);
        return prev.includes(errorMessage) ? prev : [...prev, errorMessage];
      });
      setModalBackDrop(true);
    }
  }

  useEffect(() => {
    setValidationMessages([]);
    getLocation();
  
    if (workFlow) {
      checkWorkflowProject();
    }
  }, [workFlow, selectedProject, projectsData]);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Hot Work Permit" breadcrumbItem="Wizard" />
        <Row>
          <Col xl={12}>
            <Card>
              <CardBody className="form-steps">
                <div className="step-arrow-nav mb-4">
                  <Nav className="nav-pills custom-nav nav-justified" role="tablist">
                    <NavItem>
                      <NavLink className={classnames({ active: activeTab === 1 })}>
                        Permit Details
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink className={classnames({ active: activeTab === 2 })}>
                        Check List
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink className={classnames({ active: activeTab === 3 })}>
                        Work Area Details
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink className={classnames({ active: activeTab === 4 })}>
                        ToolBox Meeting
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink className={classnames({ active: activeTab === 5 })}>
                        File Upload
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink className={classnames({ active: activeTab === 6 })}>
                        Preview & Submit
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId={1}>
                    <Step1HotWorkPermit selectedProject={selectedProject!} projectsData={projectsData} nextStep={() => toggleTab(2)} />
                  </TabPane>
                  <TabPane tabId={2}>
                    <Step2QuestionnaireIndex previousStep={() => toggleTab(1)} nextStep={() => toggleTab(3)} />
                  </TabPane>
                  <TabPane tabId={3}>
                    <Step3WorkAreaAllocationIndex previousStep={() => toggleTab(2)} nextStep={() => toggleTab(4)} />
                  </TabPane>
                  <TabPane tabId={4}>
                    <Step4ToolBoxMeetingIndex previousStep={() => toggleTab(3)} nextStep={() => toggleTab(5)} />
                  </TabPane>
                  <TabPane tabId={5}>
                    <Step5FileUploadIndex previousStep={() => toggleTab(4)} nextStep={() => toggleTab(6)} />
                  </TabPane>
                  <TabPane tabId={6}>
                    <Step6PreviewSubmitIndex
                      location={location}
                      previousStep={() => toggleTab(5)}
                    />
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <ValidationAlertStatic
        modalBackDrop={modalBackDrop}
        setModalBackDrop={setModalBackDrop}
        validationMessages={validationMessages}
        previousPageNavLink="/hotworkpermit-list"
      />
    </div>
  );
};

export default HotworkPermitWizard;
